import { iTicket } from "@sdk/services/tickets-models";
import { Button } from "components/common/button/button";
import { ChatIcon2 } from "components/common/icons/chat-icon2";
import { FolderIcon } from "components/common/icons/folder-icon";
import { IconContainer } from "components/common/icons/icon-container";
import { SearchIcon } from "components/common/icons/search";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { Spinner } from "components/common/spinner/spinner";
import dayjs from "dayjs";
import { useNavigator } from "helpers/hooks/use-navigator";
import { find, groupBy, last, orderBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { loadTicketTypes } from "store/modules/session/session.helpers";
import {
  selectAllTicketTypes,
  selectWidget,
  selectWidgetConfigurations,
} from "store/modules/session/session.selectors";
import { loadAllTickets } from "store/modules/tickets/tickets.helpers";
import { selectAllTickets } from "store/modules/tickets/tickets.selectors";
import { selectUserById } from "store/modules/users/users.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { usePrevious } from "utils/use-previous";
import { ConversationsWidget } from "views/home-page/sections/conversations-widget";
import { HomePageWidgetContainer } from "views/home-page/sections/home-page-widget-container";

export const TicketListPage = ({ disableBack }: { disableBack?: boolean }) => {
  const store = useStore();
  const widgetConfigurations = useSelector(selectWidgetConfigurations);

  const { state: tickets, isLoading } = useSimpleLoaderStore(
    selectAllTickets,
    loadAllTickets
  );

  const {
    state: ticketTypes,
    isLoading: isLoadingTicketTypes,
  } = useSimpleLoaderStore(
    selectAllTicketTypes,
    loadTicketTypes,
    [tickets.length],
    tickets.length === 0
  );

  const navigator = useNavigator();

  const { t } = useTranslation("common");

  const ticketsByTicketTypes = useMemo(() => groupBy(tickets, "ticketTypeId"), [
    tickets,
  ]);

  if (tickets?.length === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={t("RECENT_TICKETS")}
          disableBack={disableBack}
          icon={
            <div className="dark-bg rounded-lg p-2 flex justify-center items-center">
              <FolderIcon size={"1.3rem"} />
            </div>
          }
        />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className="h-full w-full flex-1 pt-4 bg-white relative"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            <Spinner isActive={isLoading || isLoadingTicketTypes}>
              <div className="ticket-list-container animate fade-in-up">
                {ticketTypes.map((ticketType) => {
                  if (
                    (ticketsByTicketTypes[ticketType.id!] || []).length === 0
                  ) {
                    return <></>;
                  }
                  return (
                    <HomePageWidgetContainer
                      key={ticketType.id}
                      title={ticketType.label}
                      icon={
                        <IconContainer size={"1.3rem"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M12.4142 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5ZM4 7V19H20V7H4Z"></path>
                          </svg>
                        </IconContainer>
                      }
                      // eslint-disable-next-line i18next/no-literal-string
                      animationClass="fade-in-up"
                    >
                      <div className="tickets-list animate fade-in-up mt-4">
                        {ticketsByTicketTypes?.[ticketType.id!].map(
                          (ticket) => (
                            <TicketListItem
                              ticket={ticket}
                              key={ticket.id}
                              emoji={ticketType.emoji}
                            />
                          )
                        )}
                      </div>
                    </HomePageWidgetContainer>
                  );
                })}
              </div>
            </Spinner>
            {/* <div className="border-t border-gray-200 w-full my-6"></div> */}

            {/* <ConversationsWidget /> */}
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};

const TicketListItem = ({
  ticket,
  emoji,
}: {
  ticket: iTicket;
  emoji: string;
}) => {
  const navigator = useNavigator();

  const { t } = useTranslation();
  return (
    <div
      className="hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer rounded-lg border-1 border-gray-200 flex flex-col p-2 hover-text-primary"
      onClick={() => {
        navigator.navigateTo(`/tickets/${ticket.id}`);
      }}
    >
      <div className="top-row flex flex-row justify-between items-center">
        <div className="">
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {emoji || "📥"} {ticket.label}
        </div>
        <i className="ri-arrow-right-line"></i>
      </div>
      <div className="second-row text-sm text-gray-600">
        {t("CREATED_AT")} {dayjs(ticket.createdAt).format("DD/MM/YYYY")}
      </div>
    </div>
  );
};
