import { useEffect, useState } from "react";

//* If the patch is not applied, when navigation happens, there will be a staggered animation to the page
export const useNavigationAnimationPatch = (timeout?: number) => {
  const [messagesVisibility, setMessagesVisibility] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setMessagesVisibility(true);
    }, timeout || 0);
  }, [timeout]);
  return messagesVisibility;
};
