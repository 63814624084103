import { SDK } from "@sdk";
import { iArticleCollection } from "@sdk/services/knowledge-base/knowledge-base-models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { articleCollectionDefaultQueryConfig } from "./article-collection-default-query";
import { ArticleCollectionsSlice } from "./article-collection.slice";

export const loadAllArticleCollections = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadArticleCollectionsQuery(articleCollectionDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const loadAllArticleCollectionsByPortalId = (
  selfServicePortalId: string
) => (store: Store<iStore>, forceReload?: boolean) =>
  loadArticleCollectionsQuery(
    {
      query: { selfServicePortalId },
      options: articleCollectionDefaultQueryConfig.options,
    },
    JSON.stringify({ selfServicePortalId })
  )(store, forceReload);

export const {
  loadEntityQueries: loadArticleCollectionsQuery,
  loadEntityById: loadArticleCollectionById,
  loadEntitiesByIds: bulkLoadArticleCollectionsByIds,
} = CreateHelpersForEntity<iStore, iArticleCollection>({
  sliceName: "articleCollections",
  slice: ArticleCollectionsSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: (query) => SDK.collections.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.collections.getById(entityId),
  fetchPageSize: 500,
  loadEntityByIdsProvider: (articleCollectionIds: string[]) =>
    (async () => {
      const articleCollections = await Promise.all(
        articleCollectionIds.map((articleCollectionId) =>
          SDK.collections.getById(articleCollectionId)
        )
      );
      return articleCollections;
    })(),
});
