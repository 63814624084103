import { SDK } from "@sdk";
import { showErrorMessage } from "@sdk/sdk.hooks";
import { Button } from "components/common/button/button";
import { MobileNumberInputLazy } from "components/common/mobile-number-input/mobile-number-input-lazy";
import { Spinner } from "components/common/spinner/spinner";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { validatePhoneNumberWithCountryCode } from "helpers/is-valid-phone-number";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "store/modules/session/session.actions";
import {
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";
import { isEmail, isWorkEmail } from "utils/is-email";

export const PreChatForm = ({ visible }: { visible: boolean }) => {
  const [countryCode, setCountryCode] = useState("GB");
  const sessionData = useSelector(selectSession);
  useEffect(() => {
    const countryCode = sessionData?.location?.data?.country;
    setCountryCode(countryCode || "GB");
  }, [sessionData]);

  const widget = useSelector(selectWidget);

  // const fieldToCollection: ("NAME" | "EMAIL" | "PHONE")[] = [
  //   "NAME",
  //   "EMAIL",
  //   "PHONE",
  // ];

  const fieldToCollection = useMemo(
    () => widget?.configurations.conversationFlow.preChatForm.fields || [],
    [widget?.configurations.conversationFlow.preChatForm.fields]
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checked, setChecked] = useState(false);
  const [PhoneError, setPhoneError] = useState("");

  const { t } = useTranslation("common");

  const isFormValid = useMemo(() => {
    setPhoneError("");
    for (let field of fieldToCollection) {
      if (field === "EMAIL") {
        if (
          widget?.configurations.conversationFlow.preChatForm
            .allowOnlyWorkEmails
        ) {
          if (!isWorkEmail(email)) {
            // console.log("EMAIL", PhoneError);
            return false;
          }
        } else {
          if (!isEmail(email)) {
            // console.log("EMAIL", PhoneError);
            return false;
          }
        }
      } else if (field === "NAME") {
        if (!name) {
          // console.log("name", PhoneError);
          return false;
        }
      }
      if (field === "PHONE") {
        if (!phone) {
          return false;
        } else {
          if (!validatePhoneNumberWithCountryCode(phone)) {
            setPhoneError(t("INVALID_PHONE_NUMBER"));
            return false;
          }
        }
      }
    }
    return true;
  }, [
    fieldToCollection,
    widget?.configurations?.conversationFlow?.preChatForm?.allowOnlyWorkEmails,
    email,
    name,
    phone,
    t,
  ]);

  const [isProcessing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const onPreChatFormSubmitted = () => {
    setProcessing(true);
    const [firstName, ...otherNames] = name.split(" ");
    SDK.updateContactDetails({
      firstName: firstName,
      lastName: otherNames.join(" "),
      primaryEmail: email,
      primaryMobile: phone,
      preference: { marketingConsentObtained: checked },
    })
      .then((d) => {
        dispatch(setSession(d));
      })
      .catch((e) => {
        showErrorMessage(e, t("SOMETHING_WRONG_CHECK_YOUR_INPUT_MESSAGE"));
      });
  };
  const submitPreChatFormOnEnter = (e) => {
    if (e.key === "Enter") {
      if (isFormValid) {
        onPreChatFormSubmitted();
      }
    }
  };

  const preChatFromTitle =
    widget?.configurations?.locale?.custom?.["FILL_BELOW_MESSAGE"] ||
    t("FILL_BELOW_MESSAGE");

  return (
    <div
      style={{
        transition: "all 0.4s linear",
        maxHeight: visible ? 1000 : 0,
        overflow: "hidden",
      }}
    >
      <div className="border rounded-lg rounded-b-none border-b-0 p-4 flex flex-col">
        <div className="font-semibold mb-4">{preChatFromTitle}</div>

        {fieldToCollection.map((field) => {
          if (field === "NAME") {
            return (
              <input
                placeholder={t("NAME_PLACEHOLDER")}
                className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                value={name}
                onChange={(e) => setName(e.target.value)}
                key={field}
                onKeyDown={submitPreChatFormOnEnter}
              />
            );
          } else if (field === "EMAIL") {
            if (
              widget?.configurations.conversationFlow.preChatForm
                .allowOnlyWorkEmails
            ) {
              return (
                <input
                  placeholder={`${t(
                    "EMAIL_TEXT"
                  )} - you@abc.com (${t("WORK_EMAIL_ONLY")})`}
                  className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value || "".trim());
                  }}
                  key={field}
                  onKeyDown={submitPreChatFormOnEnter}
                />
              );
            }
            return (
              <input
                placeholder={`${t("EMAIL_TEXT")} - you@abc.com`}
                className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value || "".trim());
                }}
                key={field}
                onKeyDown={submitPreChatFormOnEnter}
              />
            );
          } else if (field === "PHONE") {
            return (
              <React.Fragment key={field}>
                <MobileNumberInputLazy
                  onChange={setPhone}
                  onKeyDown={submitPreChatFormOnEnter}
                  defaultCountry={countryCode}
                />
                <div className="text-red-400 pb-3">{PhoneError}</div>
                {/* <input
                  placeholder="phone - Eg: 0444342432"
                  className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                  type="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  key={field}
                  onKeyDown={submitPreChatFormOnEnter}
                /> */}
              </React.Fragment>
            );
          } else if (field === "MARKETING_PREFERENCE") {
            return (
              <div className="flex items-center text-gray-500 mb-2" key={field}>
                <input
                  name="marketingConsentObtained"
                  type="checkbox"
                  onKeyDown={submitPreChatFormOnEnter}
                  className="mr-1 border"
                  onClick={() => {
                    setChecked(!checked);
                  }}
                />
                {t("I_LIKE_TO_RECEIVE_PROMO_VIA_EMAIL")}
              </div>
            );
          }
          return (
            <React.Fragment key={field}>{t("UNKNOWN_INPUT")}</React.Fragment>
          );
        })}

        {widget?.configurations?.conversationFlow?.preChatForm
          ?.privacyPolicyLink && (
          <div className="mb-4">
            <div className="">{t("PRIVACY_POLICY_STATEMENT_LINE_1")}</div>
            {t("PRIVACY_POLICY_STATEMENT_LINE_2")}{" "}
            <a
              target="_blank"
              href={
                widget?.configurations.conversationFlow.preChatForm
                  .privacyPolicyLink
              }
              referrerPolicy="no-referrer"
              className="cursor-pointer underline"
            >
              {t("PRIVACY_POLICY_STATEMENT_LINE_3")}
            </a>
          </div>
        )}

        {isFormValid ? (
          <Spinner isActive={isProcessing}>
            <Button
              label={t("CONTINUE_BUTTON_TEXT")}
              onClick={onPreChatFormSubmitted}
            />
          </Spinner>
        ) : (
          <ToolTipX content={t("PLEASE_FILL_VALID_DETAILS")}>
            <Button
              label={t("CONTINUE_BUTTON_TEXT")}
              style={{ opacity: 0.2 }}
              className="cursor-not-allowed"
            />
          </ToolTipX>
        )}
      </div>
    </div>
  );
};
