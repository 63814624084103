import { ToolTipX } from "components/common/tooltip/tooltip";
import { getUserName } from "helpers/get-user-name";
import { t } from "i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectWidget,
  selectWidgetColorPallet,
} from "store/modules/session/session.selectors";
import { selectAllActiveUsers } from "store/modules/users/users.selectors";
import { UserAvatar } from "../user-avatar/user-avatar";
import "./team-avatar.scss";

export const TeamAvatar = ({
  size = 48,
  isBordered,
  style,
}: {
  size?: number | string;
  isBordered?: boolean;
  style?: React.CSSProperties;
}) => {
  const allActiveUsers = useSelector(selectAllActiveUsers);
  const theme = useSelector(selectWidgetColorPallet);
  const visibleUsers = useMemo(() => {
    const threeUsers = allActiveUsers.slice(0, 3).map((user) => ({
      id: user.id,
      name: getUserName(user),
      avatar: user.data.avatar || "/assets/default-avatar.png",
    }));
    if (threeUsers.length < 3) {
      const requiredAvatars = 3 - threeUsers.length;
      for (let i = 0; i < requiredAvatars; i++) {
        threeUsers.push({
          id: `filler-${i.toString()}`,
          name: "Team Member",
          avatar: "/assets/default-avatar.png",
        });
      }
    }
    return threeUsers;
  }, [allActiveUsers]);

  const widget = useSelector(selectWidget);

  if (allActiveUsers.length < 3 && allActiveUsers?.[0]?.id) {
    return (
      <UserAvatar
        userId={allActiveUsers[0].id}
        isBordered={isBordered}
        size={size}
        style={style}
      />
    );
  }

  return (
    <div
      style={{
        width: size,
        height: size,
        ...style,
      }}
      className="team-avatar-container"
    >
      <ToolTipX content={t("TEAM_TEXT", { defaultValue: "Team" })}>
        <div className="relative">
          {/* 1st Avatar (Bottom Center) */}
          <div
            style={{
              width: size,
              height: size,
            }}
            className="team-avatar-part bottom-center"
          >
            <img
              src={visibleUsers[0].avatar}
              style={{
                borderColor: isBordered ? theme.primary : "white",
              }}
              alt=""
            />
          </div>
          {/* 2nd Avatar (Top Left) */}
          <div
            style={{
              width: size,
              height: size,
            }}
            className="team-avatar-part top-left"
          >
            <img
              src={visibleUsers[1].avatar}
              style={{
                borderColor: isBordered ? theme.primary : "white",
              }}
              alt=""
            />
          </div>
          {/* 3rd Avatar  (Top Right)  */}
          <div
            style={{
              width: size,
              height: size,
            }}
            className="team-avatar-part top-right"
          >
            <img
              src={visibleUsers[2].avatar}
              style={{
                borderColor: isBordered ? theme.primary : "white",
              }}
              alt=""
            />
          </div>
        </div>
      </ToolTipX>
    </div>
  );
};
