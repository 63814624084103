import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { selectWidgetColorPallet } from "store/modules/session/session.selectors";
import "./client-avatar.scss";

export const ClientAvatar = ({
  size = 48,
  badge,
  isBordered,
  style,
}: {
  size?: number | string;
  badge?: number;
  isBordered?: boolean;
  style?: React.CSSProperties;
}) => {
  const defaultAvatar = "/assets/default-avatar.png";
  const avatarUrl = defaultAvatar;
  const theme = useSelector(selectWidgetColorPallet);
  const isOnline = false;

  return (
    <div className="user-avatar" style={style}>
      {badge && <div className="text-white bg-red-700">{badge}</div>}

      <div
        style={{
          width: size,
          height: size,
          ...(isBordered
            ? {
                borderWidth: 2,
                borderColor: theme.primary,
              }
            : {}),
          background: `url(${defaultAvatar})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className={classNames("avatar-container", {
          online: isOnline,
        })}
      >
        <img src={avatarUrl} alt="" />
      </div>
    </div>
  );
};
