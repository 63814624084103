import { iUser } from "@sdk/services/models";
import { sample } from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectUserQueryMap,
  selectEntityMap: selectUsersMap,
  selectQueryMapAll: selectUsersMapAll,
  selectQueryAllList: selectUsersAllList,
  selectAllEntities: _selectAllUsers,
  selectAllEntitiesQuery: selectAllUsersQuery,
  selectEntityById: selectUserById,
  selectEntityQuery: selectUsersQuery,
} = CreateSelectorForEntities<iStore, iUser>({
  sliceName: "users",
});

export const selectAllActiveUsers = (state: iStore) =>
  _selectAllUsers(state).filter(
    (user) =>
      !user.data.isInternalUser &&
      user.metaData.isActive &&
      !user.metaData.isAPIUser
  );

export const selectRandomUser = (state: iStore) => {
  const allUsers = selectAllActiveUsers(state);
  const onlineUsers = allUsers.filter((item) => item.metaData.isAvailable);
  // Todo: Could Filter for permissions too
  if (onlineUsers.length > 0) {
    return sample(onlineUsers);
  }
  return sample(allUsers);
};
