import { iArticle } from "@sdk/services/knowledge-base/knowledge-base-models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectArticleQueryMap,
  selectEntityMap: selectArticlesMap,
  selectQueryMapAll: selectArticlesMapAll,
  selectQueryAllList: selectArticlesAllList,
  selectAllEntities: selectAllArticles,
  selectAllEntitiesQuery: selectAllArticlesQuery,
  selectEntityById: selectArticleById,
  selectEntityQuery: selectArticlesQuery,
} = CreateSelectorForEntities<iStore, iArticle>({
  sliceName: "articles",
});

export const selectArticlesByCollection = (collectionId: string) => (store) =>
  selectArticlesQuery(JSON.stringify({ collections: collectionId }))(store)
    ?.list;

export const selectArticlesByPortal = (selfServicePortalId: string) => (
  store
) => selectArticlesQuery(JSON.stringify({ selfServicePortalId }))(store)?.list;
