import { iTicket } from "@sdk/services/tickets-models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { selectUnreadCount } from "../messages/messages.selectors";

export const {
  selectQueryMap: selectTicketQueryMap,
  selectEntityMap: selectTicketsMap,
  selectQueryMapAll: selectTicketsMapAll,
  selectQueryAllList: selectTicketsAllList,
  selectAllEntities: selectAllTickets,
  selectAllEntitiesQuery: selectAllTicketsQuery,
  selectEntityById: selectTicketById,
  selectEntityQuery: selectTicketsQuery,
} = CreateSelectorForEntities<iStore, iTicket>({
  sliceName: "tickets",
});
