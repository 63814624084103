import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { EditIconIcon } from "components/common/icons/edit-icon";
import { TypingIndicator } from "components/common/typing-indicator/typing-indicator";
import debounce from "lodash/debounce";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { animated, useSpring } from "react-spring";
import useStayScrolled, { UseStayScrolledOptions } from "react-stay-scrolled";
import { selectWidgetMaximizedState } from "store/modules/app-state/app-state.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { resetUnreadCount } from "store/modules/messages/message.actions";
import { loadMessages } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { selectContact } from "store/modules/session/session.selectors";
import { getNavigatorType } from "utils/hooks/use-mobile-detector";
import { usePrevious } from "utils/use-previous";
import {
  GetWindowVisibility,
  OnVisibilityChange,
} from "utils/window-visibility-detector.service";
import { usePreChatFormVisibility } from "views/conversation-page/hooks/use-pre-chat-form-visibility";
import { EmailCollector } from "./attachments/email-collector";
import { MessagesListItem } from "./message-list-item";
import "./messages-list.scss";
import { useTeamOfflineWindow } from "helpers/use-team-offline";
import { TeamOfflineCard } from "./attachments/team-is-offline-card";

export const MessagesListBox = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const store = useStore();
  const conversation = useSelector(selectConversationById(conversationId));
  const contact = useSelector(selectContact);

  const { data: messages } = useSelector(
    selectMessageByConversationId(conversationId)
  );

  const conversationIsTemporary = !!conversation?.isTemporary;

  useEffect(() => {
    if (!conversationIsTemporary && conversationId) {
      loadMessages(conversationId)(store, true);
    }
  }, [conversationId, conversationIsTemporary, store]);

  const ref = useRef<HTMLElement>(null);
  const [{ scrollTop }, updateScroll] = useSpring(() => ({ scrollTop: 0 }));
  const runScroll = useCallback(
    (offset) =>
      updateScroll({
        scrollTop: offset,
        from: { scrollTop: ref.current ? ref.current!.scrollTop : 0 },
        reset: true,
      }),
    [updateScroll]
  );
  const [notifyNewMessage, setNotifyNewMessage] = useState(false);
  const { scrollBottom, stayScrolled, isScrolled } = useStayScrolled(
    ref as React.RefObject<HTMLElement>,
    {
      runScroll,
    } as UseStayScrolledOptions
  );

  const sendWatermarkSignal = useCallback(() => {
    const conversation = selectConversationById(conversationId)(
      store.getState()
    );
    const conversationIsTemporary = !!conversation?.isTemporary;

    if (
      (conversation?.metaData?.lastMessage.timestamp || 0) >
      (conversation?.metaData?.readWatermarks.client || 0)
    ) {
      // delaying read signals
      setTimeout(() => {
        if (conversationId.includes("TEMP")) {
          store.dispatch(resetUnreadCount(conversationId));
          return;
        }
        if (!selectWidgetMaximizedState(store.getState())) {
          return;
        }
        // If window is not visible , don't send
        if (!GetWindowVisibility()) {
          return;
        }
        SDK.sendReadSignals(
          conversationId,
          conversationIsTemporary,
          conversation?.metaData?.lastMessage.timestamp || 0
        );
        store.dispatch(resetUnreadCount(conversationId));
      }, 200);
    }
  }, [conversationId, store]);

  const onScroll = useCallback(() => {
    if (isScrolled()) setNotifyNewMessage(false);
    if (isScrolled()) {
      sendWatermarkSignal();
    }
  }, [isScrolled, sendWatermarkSignal]);

  // Add a Visibility Event and Reload Messages anyway
  useEffect(() => {
    const removeVisibilityChangeDetection = OnVisibilityChange(() => {
      const isWindowVisible = GetWindowVisibility();
      // console.log("Visibility Changed", isWindowVisible);
      if (isWindowVisible) {
        if (
          !conversationIsTemporary &&
          conversationId &&
          getNavigatorType().isMobile()
        ) {
          loadMessages(conversationId)(store, true);
        }
        if (isScrolled()) {
          sendWatermarkSignal();
        }
      }
    });
    return removeVisibilityChangeDetection;
  }, [
    conversationId,
    conversationIsTemporary,
    isScrolled,
    sendWatermarkSignal,
    store,
  ]);

  const lastMessageCount = usePrevious(messages.length);

  useEffect(() => {
    // Tell the user to scroll down to see the newest messages if the element wasn't scrolled down
    if (messages.length > (lastMessageCount || 0) && lastMessageCount !== 0) {
      // * Removed the feature as it was buggy (When bot is active). Got to spend some time and re-put this
      // setNotifyNewMessage(!(stayScrolled as any)());
      scrollBottom();
    }
  }, [lastMessageCount, messages.length, scrollBottom, stayScrolled]);

  // If the conversation is small, when the view appears, send watermark signal (This is required since onScroll function is not called for small conversations)
  useEffect(() => {
    const messageContainer = document.getElementById("chat-messages-container");
    if (
      conversation?.id &&
      messages &&
      messageContainer &&
      messageContainer.scrollHeight > messageContainer.clientHeight
    ) {
      sendWatermarkSignal();
    }
  }, [conversation?.id, messages, sendWatermarkSignal]);

  useLayoutEffect(() => {
    scrollBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPreChatFormVisible = usePreChatFormVisibility(conversationId);

  const [isUserTyping, setUserTyping] = useState(false);

  useEffect(() => {
    let timer;
    const processTypingIndicator = (data: {
      userType: "CONTACT" | "USER";
      contactId: string;
      value: string;
      conversationId: string;
    }) => {
      // console.log("processTypingIndicator", data, conversationId);
      if (data.userType === "USER" && data.conversationId === conversationId) {
        setUserTyping(true);
      }
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setUserTyping(false);
      }, 4000);
    };
    SocketConnector.socket.on("TYPING_INDICATOR", processTypingIndicator);
    return () => {
      SocketConnector.socket.off("TYPING_INDICATOR", processTypingIndicator);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [conversationId, setUserTyping]);

  useEffect(() => {
    setUserTyping(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(messages || []).length]);

  useEffect(() => {
    const handleResize = debounce(function handleResize() {
      scrollBottom();
    }, 200);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const { t } = useTranslation("common");

  return (
    <>
      <animated.div
        ref={ref as any}
        id="chat-messages-container"
        className="chat-messages flex-1 overflow-y-auto overflow-x-hidden pt-12 h-full w-full pb-12"
        onScroll={onScroll}
        {...{ scrollTop: scrollTop }}
      >
        {messages.map((message, index) => {
          return (
            <MessagesListItem
              message={message}
              index={index}
              messages={messages}
              conversation={conversation}
              key={message.id}
            />
          );
        })}
        {!isPreChatFormVisible &&
          !conversation?.chatBotId &&
          !conversation?.activeBot &&
          !conversation?.isTemporary &&
          messages.length < 7 &&
          !contact?.data?.primaryEmail && (
            <div>
              <EmailCollector />
            </div>
          )}

        <TeamOfflineCard conversation={conversation} />

        <div
          className="typing-indicator-container"
          style={{ height: 40, marginTop: 40, marginLeft: 40 }}
        >
          {isUserTyping && <TypingIndicator />}
        </div>
      </animated.div>
      {notifyNewMessage && (
        <div className="relative">
          <div
            className="new-message-notification cursor-pointer"
            onClick={() => scrollBottom()}
          >
            {t("SCROLL_DOWN_TO_NEW_MESSAGE")}
          </div>
        </div>
      )}
    </>
  );
};
