import { iUser } from "@sdk/services/models";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectWidgetColorPallet } from "store/modules/session/session.selectors";
import { selectAllActiveUsers } from "store/modules/users/users.selectors";
import { LightenDarkenColor } from "utils/color-utils";

export const AvatarGroup = ({
  userList,
  maxCount,
  avatarSize = "3rem",
}: {
  userList?: iUser[];
  maxCount?: number;
  avatarSize?: number | string;
}) => {
  const allActiveUsers = useSelector(selectAllActiveUsers);

  if (!userList) {
    userList = allActiveUsers;
  }
  const hiddenUserCount = useMemo(() => {
    const userCount = (userList || []).length;
    if (!maxCount) {
      return 0;
    }
    if (userCount <= maxCount) {
      return 0;
    }
    return userCount - maxCount;
  }, [maxCount, userList]);

  const visibleUsers = userList.slice(0, maxCount);
  // Todo: Filter online users first and make them visible
  const theme = useSelector(selectWidgetColorPallet);

  const { container, moreAvatar, avatarLeftOffset } = useMemo(() => {
    if (typeof avatarSize === "number") {
      return {
        container: {
          width: visibleUsers.length * (avatarSize - 15) + avatarSize,
          height: avatarSize,
        },
        avatarLeftOffset: (index) => index * (avatarSize - 15),
        moreAvatar: {
          left: visibleUsers.length * (avatarSize - 15),
          fontSize: avatarSize / 5,
        },
      };
    }
    if (avatarSize.includes("rem")) {
      const sizeNum = Number(avatarSize.replace("rem", ""));
      const offset = sizeNum / 3;
      return {
        container: {
          width: visibleUsers.length * (sizeNum - offset) + sizeNum + "rem",
          height: avatarSize,
        },
        avatarLeftOffset: (index) => index * (sizeNum - offset) + "rem",
        moreAvatar: {
          left: visibleUsers.length * (sizeNum - offset) + "rem",
          fontSize: sizeNum / 5 + "rem",
        },
      };
    }

    throw new Error("Invalid Avatar Size");
  }, [avatarSize, visibleUsers?.length]);

  return (
    <div
      className="relative"
      style={{
        ...container,
      }}
    >
      {visibleUsers.map((user, index) => (
        <div
          style={{
            position: "absolute",
            left: avatarLeftOffset(index),
            zIndex: visibleUsers.length - index,
          }}
          key={user.id}
        >
          <UserAvatar size={avatarSize} isBordered userId={user.id} />
        </div>
      ))}
      {Boolean(hiddenUserCount) && (
        <ToolTipX content={`${hiddenUserCount} more members`}>
          <div
            style={{
              width: avatarSize,
              height: avatarSize,
              borderWidth: 2,
              borderColor: theme.primary,
              position: "absolute",
              borderRadius: "50%",
              background: LightenDarkenColor(theme.primary, 40),
              ...moreAvatar,
            }}
            className="flex flex-row justify-center items-center text-white"
            // eslint-disable-next-line i18next/no-literal-string
          >
            + {hiddenUserCount}
          </div>
        </ToolTipX>
      )}
    </div>
  );
};
