import { ParentWindowService } from "@parent-window/parent-window";
import {
  iCreateSessionResponse,
  iMessage,
  iSessionChatInvite,
} from "@sdk/services/models";
import {
  AudioNotificationsService,
  AvailableAudios,
} from "@services/audio-notifications-service";
import { ReloadWidgetPage } from "helpers/reload-widget-page";
import {
  setActiveCampaign,
  setSocketConnectionState,
} from "store/modules/app-state/app-state.actions";
import {
  addNewConversation,
  patchConversation,
} from "store/modules/conversations/conversations.slice";
import {
  addNewMessage,
  patchMessage,
} from "store/modules/messages/message.actions";
import {
  patchSession,
  patchWidget,
} from "store/modules/session/session.actions";
import { selectUserById } from "store/modules/users/users.selectors";
import { patchUser } from "store/modules/users/users.slice";
import { store } from "store/store";
import { Socket } from "socket.io-client";
import { GenerateSimpleCampaign } from "store/modules/app-state/helpers/generate-simple-campaign";
import { CampaignsController } from "store/modules/app-state/campaigns.controller";
import { selectAllConversations } from "store/modules/conversations/conversations.selectors";
import find from "lodash/find";
import { GenerateSimpleCampaignMessage } from "helpers/local-conversation-generator";
import { last } from "lodash";
import { push, replace } from "connected-react-router";
import {
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";
import { loadSession } from "store/modules/session/session.helpers";
import { addNewTicket, patchTicket } from "store/modules/tickets/tickets.slice";
import { iActivatedMessageCampaign } from "@sdk/services/activated-message-campaigns-models";
import { iScreenTourLog } from "@sdk/services/screent-tour-models";

interface iSyncEvent {
  data: any;
  entityId: string;
  entityType: string;
  id: string;
  type: "UPDATE" | "NEW";
}

export const HandleSocketEvents = (
  socket: Socket<any, any>,
  onSocketResolved: (res: iCreateSessionResponse) => any
) => {
  const onConnect = () => {
    store.dispatch(setSocketConnectionState(true));
  };
  socket.on("connect", onConnect);

  const onReconnect = () => {
    // Todo: Dispatch Action
  };
  socket.on("reconnect", onReconnect);

  const onDisconnect = (reason) => {
    setTimeout(() => {
      if (!socket.connected) {
        store.dispatch(setSocketConnectionState(false));
      }
    }, 10000);
  };
  socket.on("disconnect", onDisconnect);

  const onGuestSessionResolved = (data: any) => {
    // console.log("GUEST_SESSION_RESOLVED", data);
    onSocketResolved(data);
  };
  socket.on("GUEST_SESSION_RESOLVED", onGuestSessionResolved);

  const onSyncEvent = (data: iSyncEvent) => {
    // console.log("SYNC", data.type, data.entityType);
    if (data.type === "UPDATE") {
      switch (data.entityType) {
        case "USER": {
          store.dispatch(patchUser({ ...data.data, id: data.entityId }));
          break;
        }
        case "CONVERSATION": {
          store.dispatch(
            patchConversation({ ...data.data, id: data.entityId })
          );
          break;
        }
        case "MESSAGE": {
          store.dispatch(patchMessage({ ...data.data, id: data.entityId }));
          break;
        }
        case "SESSION": {
          const existingSession = selectSession(store.getState());
          store.dispatch(patchSession({ ...data.data, id: data.entityId }));
          if (!existingSession?.contactId && data.data.contactId) {
            loadSession(store, true).catch((e) => {
              // Ignore error
            });
          }

          break;
        }
        case "WIDGET": {
          if (data?.data?.configurations?.blackListedClients) {
            window.location.reload();
          }
          store.dispatch(patchWidget({ ...data.data, id: data.entityId }));
          break;
        }
        case "TICKET": {
          store.dispatch(patchTicket({ ...data.data, id: data.entityId }));
          break;
        }
        default: {
          console.log("Unidentified entity", data.entityType);
        }
      }
    } else if (data.type === "NEW") {
      switch (data.entityType) {
        case "CONVERSATION": {
          store.dispatch(addNewConversation(data.data));
          CampaignsController.dismissAllCampaigns(true);
          store.dispatch(
            setActiveCampaign({ campaignId: "", campaignUser: "" })
          );
          if (window.location.href.includes("TEMP")) {
            // Delay is added to handle the navigation glitch
            setTimeout(() => {
              if (!window.location.href.includes(data.data.id)) {
                store.dispatch(push(`/conversations/${data.data.id}`));
              }
            }, 2000);
          } else if (!window.location.href.includes(data.data.id)) {
            store.dispatch(push(`/conversations/${data.data.id}`));
          }

          // Patch. Just dismissing again
          setTimeout(() => {
            CampaignsController.dismissAllCampaigns(true);
          }, 600);

          break;
        }

        case "MESSAGE": {
          const message: iMessage = data.data;
          if (message.isWhisper || message.isNotes || message.isPrivate) {
            return;
          }
          store.dispatch(addNewMessage(data.data));
          ParentWindowService.sendNewMessageNotification(message);
          break;
        }

        case "TICKET": {
          store.dispatch(addNewTicket(data.data));
          break;
        }

        case "ACTIVATED_MESSAGE_CAMPAIGN": {
          const campaign: iActivatedMessageCampaign = data.data;
          if (
            CampaignsController.ActiveMessageCampaigns.find(
              (c) => c.id === campaign.id
            )
          ) {
            return;
          }
          CampaignsController.dismissAllCampaigns(true);
          CampaignsController.ActiveMessageCampaigns = [
            campaign,
            ...CampaignsController.ActiveMessageCampaigns,
          ];
          CampaignsController.triggerCampaignWithCampaignId(campaign.id!);
          break;
        }
        case "SCREEN_TOUR_LOG": {
          const tourLog: iScreenTourLog = data.data;
          ParentWindowService.sendMessage({
            type: "ACTIVATE_TOUR",
            data: {
              tourId: tourLog.screenTourId,
            },
          });
          break;
        }

        default: {
          console.log("Unidentified entity", data.entityType);
        }
      }
    }
  };
  socket.on("SYNC", onSyncEvent);

  const onAllOtherEvent = (eventName, data) => {
    const event = {
      name: eventName,
      payload: data,
    };
    // console.log("event", event);
    if (event.name === "CONTACT_MERGED") {
      console.log("CONTACT_MERGED");
      ReloadWidgetPage();
    }

    if (event.name === "CONTACT_UPDATED") {
      loadSession(store, true).catch((e) => {
        // Ignore error
      });
    }

    if (event.name === "VISITOR_INVITED") {
      // console.log("VISITOR_INVITED", event);
      // Todo:
      // Check if active conversation exist
      // if not,
      // dismiss the old campaign message and start a new conversation
      const inviteCampaign = GenerateSimpleCampaign({
        id: "INVITE_MESSAGE",
        text: event.payload.message,
        quickReplies: undefined,
        coverImage: undefined,
        chatBotId: undefined,
        options: {
          userId: (event.payload as iSessionChatInvite).invitedBy,
        },
      });
      CampaignsController.dismissAllCampaigns();
      CampaignsController.triggerCampaign(inviteCampaign);
    }

    if (event.name === "VISITOR_INVITED_SUBSEQUENT_MESSAGE") {
      // console.log("VISITOR_INVITED_SUBSEQUENT_MESSAGE", event);
      // Check if temp conversation exist
      // if it exist, add a new message
      const tempConversation = find(selectAllConversations(store.getState()), {
        isTemporary: true,
      });
      if (tempConversation) {
        const message = GenerateSimpleCampaignMessage(
          {
            message: last(
              (event.payload as iSessionChatInvite).subsequentMessages
            )?.message,
            userId: (event.payload as iSessionChatInvite).invitedBy,
            conversationId: tempConversation.id!,
          },
          store
        );
        store.dispatch(addNewMessage(message));
        ParentWindowService.sendNewMessageNotification(message);
      }
    }
  };
  socket.on("*", onAllOtherEvent);

  // Todo:
  // this.SOCKET.on("CHECK_VALIDITY", () => {
  //   this.parentWindow.sendMessage({ type: "CHECK_VALIDITY" });
  // });

  // this.SOCKET.on("TAKE_SNAPSHOT", (data) => {
  //   // console.log("TAKE_SNAPSHOT",data);
  //   this.parentWindow.sendMessage({ type: "TAKE_SNAPSHOT", data });
  // });

  const onUserPresence = (data: any) => {
    //
  };
  socket.on("USER_PRESENCE", onUserPresence);

  return () => {
    socket.off("connect", onConnect);
    socket.off("reconnect", onReconnect);
    socket.off("disconnect", onDisconnect);
    socket.off("GUEST_SESSION_RESOLVED", onGuestSessionResolved);
    socket.off("*", onAllOtherEvent);
    socket.off("SYNC", onSyncEvent);
    socket.off("USER_PRESENCE", onUserPresence);
  };
};
