import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import { selectWidget } from "store/modules/session/session.selectors";

dayjs.extend(utc);
dayjs.extend(timezone);

export const useTeamOfflineWindow = () => {
  const widget = useSelector(selectWidget);
  const isInOnlineWindow = (() => {
    const offlineHourConfig = widget?.configurations?.offlineHours;
    if (offlineHourConfig) {
      const timeInRemoteTimeZone = offlineHourConfig.timezone
        ? dayjs().tz(offlineHourConfig.timezone)
        : dayjs();

      const dayOfTheWeek = timeInRemoteTimeZone.format("dddd");
      const onlineHours = offlineHourConfig?.dayWiseConfig?.[dayOfTheWeek];
      const currentHour = timeInRemoteTimeZone.hour();

      const currentMinutes = timeInRemoteTimeZone.minute();

      if (onlineHours?.startTime) {
        const [startingHour, startingMinute] = onlineHours.startTime.split(":");
        if (currentHour < Number(startingHour)) {
          return false;
        }
        if (currentHour === Number(startingHour)) {
          if (currentMinutes < Number(startingMinute)) {
            return false;
          }
        }
      }
      if (onlineHours?.endTime) {
        const [endingHour, endingMinute] = onlineHours.endTime.split(":");
        if (Number(endingHour) < currentHour) {
          return false;
        }
        if (currentHour === Number(endingHour)) {
          if (Number(endingMinute) < currentMinutes) {
            return false;
          }
        }
      }
    }
    return true;
  })();
  return !isInOnlineWindow;
};
