import { Button } from "components/common/button/button";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { FaqIcon } from "components/common/icons/faq-icon";
import { SearchIcon } from "components/common/icons/search";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { setLastVisitedTab } from "store/modules/app-state/app-state.actions";
import {
  selectAllFAQs,
  selectFaqCollections,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { HomePageWidgetContainer } from "./home-page-widget-container";

export const FAQsWidget = () => {
  // const { state: faqList, isLoading } = useSimpleLoaderStore(
  //   selectAllArticles,
  //   loadAllArticles
  // );

  const { t } = useTranslation("common");

  const faqList = useSelector(selectAllFAQs);
  const visibleFaqs = useMemo(() => {
    return faqList.slice(0, 3);
  }, [faqList]);

  const navigator = useNavigator();
  const store = useStore();

  if (visibleFaqs.length === 0) {
    return <></>;
  }

  return (
    <HomePageWidgetContainer
      icon={<FaqIcon size={"1.3rem"} />}
      title={t("FREQUENTLY_ASKED_QUESTIONS")}
      subHeading={t("FAQ_GOT_QUESTIONS")}
      noHeaderBorder={true}
    >
      <div
        className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md"
        onClick={() => {
          store.dispatch(setLastVisitedTab("FAQS"));
        }}
      >
        <input
          placeholder={t("SEARCH_FQS")}
          className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
        />
        <SearchIcon className="mr-2" />
      </div>
      <div className="faq-list-container mt-4">
        {visibleFaqs.map((faq) => (
          <div
            key={faq.id}
            className="flex flex-row hover-text-primary hover:bg-gray-200 p-2 cursor-pointer justify-between items-center rounded-lg"
            onClick={() => {
              navigator.navigateTo(`/faq/${faq.id}`);
            }}
          >
            <div className="title truncate mr-4">{faq.question}</div>
            <ArrowRightIcon size={"1.2rem"} />
          </div>
        ))}
      </div>
      <div className="browse-all-button p-4">
        <Button
          label={
            <div className="flex flex-row">
              <SearchIcon className="mr-2" /> {t("BROWSE_ALL")}
            </div>
          }
          type="secondary-button"
          onClick={() => {
            navigator.navigateTo(`/faq`);
          }}
          size="SMALL"
        />
      </div>
    </HomePageWidgetContainer>
  );
};
