import {
  iChatFlowConfig_GreetingsOption,
  iMessageAttachment,
  MessageAttachmentTypes,
} from "@sdk/services/models";
import classNames from "classnames";
import { Spinner } from "components/common/spinner/spinner";
import { useNavigator } from "helpers/hooks/use-navigator";
import { sendMessage } from "helpers/send-message";
import { useCallback, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { usePreChatFormVisibility } from "views/conversation-page/hooks/use-pre-chat-form-visibility";

import { message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const MessageQuickReplies = ({
  attachments,
  conversationId,
}: {
  attachments: iMessageAttachment[];
  conversationId: string;
}) => {
  const conversation = useSelector(selectConversationById(conversationId));
  const store = useStore();
  const navigator = useNavigator();

  const isPreChatFormVisible = usePreChatFormVisibility(conversationId);

  const quickReplyAttachment = attachments.find(
    (attachment) => attachment.type === "QUICK_REPLIES"
  );

  const [selectedOption, setSelectedOption] = useState("");

  const onSendMessage = useCallback(
    async (option: iChatFlowConfig_GreetingsOption) => {
      const message = option.label;
      await sendMessage(
        conversation,
        store,
        message,
        [
          {
            type: MessageAttachmentTypes.SELECTED_QUICK_REPLY,
            payload: {
              selectedOption: option,
            },
          },
        ],
        navigator
      );
    },
    [conversation, navigator, store]
  );

  const onOptionSelected = useCallback(
    (option: iChatFlowConfig_GreetingsOption) => {
      setSelectedOption(option.label);
      onSendMessage(option);
    },
    [onSendMessage]
  );

  const { t } = useTranslation("common");

  if (!quickReplyAttachment) {
    return <></>;
  }

  return (
    <div className="quick-replies-container overflow-hidden w-full">
      <div
        className="mt-8 pl-2"
        style={{
          // top: "calc(100% + 20px)",
          paddingBottom: 15,
          left: 20,
        }}
      >
        {quickReplyAttachment && (
          <div className="quick-replies-container">
            {quickReplyAttachment.payload.options.map((option, i) => (
              <Spinner
                className="mb-2 flex"
                isActive={option.label === selectedOption}
                key={i.toString()}
              >
                <div
                  className={classNames(
                    "p-2 flex px-4 quick-reply-pill font-semibold rounded-md",
                    {
                      "opacity-75 cursor-not-allowed": isPreChatFormVisible,
                      "cursor-pointer": !isPreChatFormVisible,
                    }
                  )}
                  onClick={() => {
                    if (!isPreChatFormVisible) {
                      onOptionSelected(option);
                    } else {
                      message.warning(t("FILL_IN_THE_FORM_TO_CONTINUE"));
                    }
                  }}
                >
                  {option.label}
                </div>
              </Spinner>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
