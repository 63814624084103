declare const CC_IS_PROD: any;

export const ENV = (() => {
  const env = CC_IS_PROD
    ? "PROD"
    : window.location.hostname.indexOf("localhost") > -1 ||
      window.location.hostname.indexOf("192.168") > -1
    ? "LOCAL"
    : "PROD";

  // const env: any = "PROD";
  if (env === "PROD") {
    return {
      env: "PROD",
      ENGINE_BASE_PATH: "https://engine.clickconnector.app/api/v1",
      SOCKET_BASE_PATH: "https://engine.clickconnector.app",
    };
  }
  return {
    env: "LOCAL",
    ENGINE_BASE_PATH: "http://localhost:7567/api/v1",
    SOCKET_BASE_PATH: "http://localhost:7567",
    // ENGINE_BASE_PATH: "http://192.168.8.103:7567/api/v1",
    // SOCKET_BASE_PATH: "http://192.168.8.103:7567",
  };
})();
