import { iMessageAttachment } from "@sdk/services/models";
import { DownloadIcon } from "components/common/icons/download";
import { last } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { AddEllipsis } from "utils/add-ellipsis";
import { GetCCFilename } from "utils/get-cc-filename";
import { ExtensionsFileMap } from "utils/get-extension-type";
import { MessageEventScheduledCard } from "./attachments/message-event-scheduled-card";
import { MessageLinkCard } from "./attachments/message-link-card";
import { MessageRelatedArticles } from "./attachments/related-articles-attachments";
import { GPTAssistantMessagesListBox } from "../gpt-assistant-input/gpt-assistant-message-list";
import { MagicWandIcon } from "components/common/icons/magic-wand-icon";
import { IconContainer } from "components/common/icons/icon-container";
import { LazyAPIVideoPlayer } from "components/common/api-video-player/lazy-api-video-player";

const isUnsupportedImagePreview = (url: string) => {
  const extension = last(GetCCFilename(url).split(".")) || "unknown";
  const unsupportedImagePreviews = ["heic", "HEIC"];
  return unsupportedImagePreviews.includes((extension || "").toLowerCase());
};

const PreviewIcon = (
  <IconContainer size={"1.3rem"}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"></path>
    </svg>
  </IconContainer>
);

const ExtensionIcon = ({ url }: { url: string }) => {
  const extension = last(GetCCFilename(url).split(".")) || "unknown";

  const iconMaps = {
    // 'graphics_editor',
    video: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M17 9.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4.2zM5 8v2h2V8H5z" />
      </svg>
    ),
    audio: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M16 2l5 5v14.008a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16zm-5 10.05a2.5 2.5 0 1 0 2 2.45V10h3V8h-5v4.05z" />
      </svg>
    ),
    image: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M5 11.1l2-2 5.5 5.5 3.5-3.5 3 3V5H5v6.1zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm11.5 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
      </svg>
    ),
    spreadsheet: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
      </svg>
    ),
    // 'project_management',
    presentation: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM5 8v8h2v-2h6V8H5zm2 2h4v2H7v-2z" />
      </svg>
    ),
    // 'diagramming',
    word_processing: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zM2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM11 8v4.989L9 11l-1.99 2L7 8H5v8h2l2-2 2 2h2V8h-2z" />
      </svg>
    ),
    document: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M16 2l5 5v14.008a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16zm-4 14a4 4 0 1 0 0-8H8v8h4zm-2-6h2a2 2 0 1 1 0 4h-2v-4z" />
      </svg>
    ),
    pdf: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="currentColor"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"></path>
      </svg>
    ),
  };

  const icon = iconMaps[ExtensionsFileMap[extension.toLowerCase()]];

  if (icon) {
    return icon;
  }

  return <DownloadIcon />;
};

const isImageExtension = (url) => {
  const extension = last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "image";
};

export const MessageAttachments = ({
  attachments,
}: {
  attachments: iMessageAttachment[];
}) => {
  const fileAttachments = attachments.filter(
    (attachment) =>
      attachment.type === "FILE" &&
      !((attachment.payload?.file?.url || "") as string).includes("api.video")
  );

  const apiVideoAttachments = attachments.filter(
    (attachment) =>
      attachment.type === "FILE" &&
      ((attachment.payload?.file?.url || "") as string).includes("api.video")
  );

  const linkAttachments = attachments.filter(
    (attachment) => attachment.type === "LINK"
  );

  const coverImages = attachments.filter(
    (attachment) => attachment.type === "GREETINGS_COVER"
  );

  const messageReferenceAttachments = attachments.filter(
    (attachment) => attachment.type === "MESSAGE_REFERENCE"
  );

  const eventDetails = attachments.filter(
    (attachment) => attachment.type === "SCHEDULED_EVENT_DETAILS"
  );

  const relatedArticles = attachments.filter(
    (attachment) => attachment.type === "RELATED_ARTICLES"
  );

  const aiAssistSessions = attachments.filter(
    (attachment) => attachment.type === "AI_ASSIST_SESSION"
  );

  const otherAttachments = attachments.filter(
    (attachment) =>
      !(
        attachment.type === "FILE" ||
        attachment.type === "LINK" ||
        attachment.type === "QUICK_REPLIES" ||
        attachment.type === "GREETINGS_COVER" ||
        attachment.type === "SCHEDULED_EVENT_DETAILS" ||
        attachment.type === "TASK" ||
        attachment.type === "ACTION_LIST" ||
        attachment.type === "MESSAGE_REFERENCE" ||
        attachment.type === "RELATED_ARTICLES" ||
        attachment.type === "AI_ASSIST_SESSION"
      )
  );

  const openUrlInNewWindow = (url: string) => {
    const win = window.open(url, "_blank");
    win && win.focus();
  };

  const { t } = useTranslation("common");

  return (
    <>
      {fileAttachments.length > 0 && (
        <div className="file-attachments">
          {fileAttachments.map((attachment, index) => (
            <div className="attachment" key={index.toString()}>
              {(attachment.payload?.type?.indexOf("image") > -1 ||
                isImageExtension(attachment.payload?.file?.url)) &&
                !isUnsupportedImagePreview(attachment.payload?.file?.url) && (
                  <div
                    className="flex justify-center items-center flex-col"
                    onClick={() =>
                      openUrlInNewWindow(attachment.payload.file.url)
                    }
                  >
                    <img
                      src={attachment.payload.file.url}
                      width={"auto"}
                      style={{ maxHeight: 120 }}
                      className="flex justify-center items-center"
                      alt=""
                      // height={120}
                    />
                    <div className="hover text-sm">
                      {PreviewIcon} {t("VIEW")}
                    </div>
                  </div>
                )}
              {(!(
                attachment.payload?.type?.indexOf("image") > -1 ||
                isImageExtension(attachment.payload?.file?.url)
              ) ||
                isUnsupportedImagePreview(attachment.payload?.file?.url)) && (
                <div
                  className="flex justify-center items-center flex-col"
                  onClick={() =>
                    openUrlInNewWindow(attachment.payload.file.url)
                  }
                >
                  <ExtensionIcon url={attachment.payload.file.url} />
                  <div className="text text-xs">
                    {AddEllipsis(
                      GetCCFilename(
                        attachment.payload.file.name ||
                          attachment.payload.file.url
                      ),
                      20
                    )}
                  </div>
                  <div className="hover">
                    <DownloadIcon size={"1.3rem"} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {apiVideoAttachments.length > 0 &&
        apiVideoAttachments.map((attachment) => (
          <div key={attachment?.payload?.file?.url} style={{ width: 300 }}>
            <LazyAPIVideoPlayer
              // eslint-disable-next-line i18next/no-literal-string
              videoId={attachment?.payload?.file?.url.split("/")[4]}
            />
          </div>
        ))}
      {linkAttachments.length > 0 &&
        linkAttachments.map((attachment, index) => (
          <MessageLinkCard attachment={attachment} key={index.toString()} />
        ))}
      {coverImages.length > 0 && (
        <div className="file-attachments">
          {coverImages.map((attachment, index) => (
            <div key={index.toString()}>
              <img
                src={attachment.payload.coverImage}
                width={"auto"}
                style={{ maxHeight: 120 }}
                className="flex justify-center items-center"
                alt=""
              />
            </div>
          ))}
        </div>
      )}
      {eventDetails.length > 0 &&
        eventDetails.map((attachment, index) => (
          <MessageEventScheduledCard
            attachment={attachment}
            key={index.toString()}
          />
        ))}

      {relatedArticles.length > 0 &&
        relatedArticles.map((attachment, index) => (
          <MessageRelatedArticles
            attachment={attachment}
            key={index.toString()}
          />
        ))}

      {aiAssistSessions.length > 0 &&
        aiAssistSessions.map((attachment, index) => (
          <div key={index.toString()} style={{ zoom: 0.8 }}>
            <div className="flex flex-row gap-4 font-bold mb-4 ml-8">
              <MagicWandIcon /> {t("MAGIC_ASSISTANT")}
            </div>
            <div className="pl-2">
              <GPTAssistantMessagesListBox
                // eslint-disable-next-line i18next/no-literal-string
                conversationId={"DEMO"}
                messages={attachment.payload.messages}
              />
            </div>
          </div>
        ))}

      {messageReferenceAttachments.length > 0 &&
        messageReferenceAttachments.map((attachment, index) => (
          <div
            className="attachment-inner-cont message-reference"
            key={index.toString()}
          >
            <div className="text-gray-600 dark:text-gray-400 mode_transition text-xs">
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {t("MESSAGE_REPLY_TO")}:{" "}
            </div>
            <div className="bubble">{attachment.payload.message?.message}</div>
            <MessageAttachments
              attachments={attachment.payload.message?.attachments || []}
            />
          </div>
        ))}
      {otherAttachments.map((attachment, index) => (
        <div className="attachment" key={index.toString()}>
          {t("UNKNOWN_ATTACHMENT")}
        </div>
      ))}
    </>
  );
};
