import { Button } from "components/common/button/button";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { SearchIcon } from "components/common/icons/search";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { setLastVisitedTab } from "store/modules/app-state/app-state.actions";
import {
  loadAllArticles,
  loadAllArticlesByPortalId,
} from "store/modules/articles/articles.helpers";
import {
  selectAllArticles,
  selectArticlesByPortal,
} from "store/modules/articles/articles.selectors";
import { selectWidget } from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { HomePageWidgetContainer } from "./home-page-widget-container";

export const ArticlesWidget = () => {
  const { t, i18n } = useTranslation("common");

  const widget = useSelector(selectWidget);

  const selfServicePortalId =
    widget?.configurations.knowledgeBase?.selfServicePortalId;

  const { state: articleList, isLoading } = useSimpleLoaderStore(
    selectArticlesByPortal(selfServicePortalId!),
    loadAllArticlesByPortalId(selfServicePortalId!),
    [selfServicePortalId],
    !selfServicePortalId
  );

  const navigator = useNavigator();

  const visibleArticles = useMemo(() => {
    return articleList.slice(0, 3);
  }, [articleList]);

  const store = useStore();

  return (
    <HomePageWidgetContainer>
      <div
        className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md"
        onClick={() => {
          store.dispatch(setLastVisitedTab("FAQS"));
        }}
      >
        <input
          placeholder={t("SEARCH_KB_INPUT_PLACEHOLDER")}
          className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
        />
        <SearchIcon className="mr-2" />
      </div>

      <div className="faq-list-container mt-4">
        {visibleArticles.map((article) => (
          <div
            key={article.id}
            className="flex flex-row pointer hover-text-primary hover:bg-gray-200 p-2 cursor-pointer justify-between items-center rounded-lg"
            onClick={() => {
              navigator.navigateTo(`/knowledge-base/articles/${article.id}`);
            }}
          >
            <div className="title truncate">{article.title}</div>
            <ArrowRightIcon size={"1.1rem"} />
          </div>
        ))}
      </div>
      <div
        className="browse-all-button flex flex-row justify-end items-center p-4 cursor-pointer hover-text-primary"
        onClick={() => {
          // navigator.navigateTo(`/knowledge-base`);
          store.dispatch(setLastVisitedTab("KNOWLEDGE_BASE"));
        }}
      >
        {t("BROWSE_ALL")} <ArrowRightIcon size={"1.1rem"} />
      </div>
    </HomePageWidgetContainer>
  );
};
