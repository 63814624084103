import { Store } from "@reduxjs/toolkit";
import {
  iChatFlowConfig_Greetings,
  iConversation,
  iMessage,
  iMessageAttachment,
  MessageAttachmentTypes,
} from "@sdk/services/models";
import { last } from "lodash";
import { selectContact } from "store/modules/session/session.selectors";
import { uuidv4 } from "utils/generate-uuid";
import { ReplaceTokens } from "utils/token-replacer";

export interface iLocalConversationMessage {
  text: string;
}

export function generateConversationWithGreetings(
  config: iChatFlowConfig_Greetings | { messages: iLocalConversationMessage[] },
  options: {
    userId?: string;
    chatBotId?: string;
    messageCampaignId?: string;
  } = {},
  store: Store
) {
  const conversationId = "xxxx-Widget-TEMP-Generated-xxxx";
  let lastMessage =
    "text" in config ? config.text : last(config.messages)?.text || "";

  const contact = selectContact(store.getState());
  const widget = selectContact(store.getState());
  lastMessage = ReplaceTokens(lastMessage || "", {
    CONTACT_FIRST_NAME: contact?.data?.firstName || "",
    CONTACT_LAST_NAME: contact?.data?.lastName || "",
    CONTACT_EMAIL: contact?.data?.primaryEmail || "",
    CONTACT_PHONE: contact?.data?.primaryMobile || "",
    WORKSPACE_NAME: "",
  });
  const conversation: iConversation = {
    id: conversationId,
    organizationId: "",
    connectionId: "",
    connectionType: "LIVE_CHAT",
    contactId: "",
    data: {
      messageCampaignId: options.messageCampaignId,
    },
    priority: 1,
    subject: "",
    status: "OPEN",
    channel: "",
    tags: [],
    pipeline: {
      id: "",
      stage: "",
    },
    members: [],
    moments: [],
    references: [],
    metaData: {
      isActive: true,
      createdAt: new Date().getTime(),
      lastMessage: {
        text: lastMessage,
        timestamp: new Date().getTime(),
        client: 1,
        team: 1,
        isByClient: true,
      },
      unreadMessages: 4,
      readWatermarks: {
        client: new Date().getTime(),
        team: new Date().getTime(),
        byUser: {},
      },
      flags: {},
      totalMessages: 1,
      firstResponseTime: 0,
      dueData: 1,
      initSource: "WEBSITE_CHAT",
    },
    isTemporary: true,
    chatBotId: options.chatBotId,
  };

  // console.log("config", config);

  const messages: iMessage[] = (() => {
    if ("messages" in config) {
      return config.messages.map((message) =>
        GenerateSimpleCampaignMessage(
          {
            message: message.text,
            userId: options.userId,
            conversationId,
          },
          store
        )
      );
    }

    const attachments: iMessageAttachment[] = [];

    if (config.options && config.options.length > 0) {
      attachments.push({
        payload: {
          options: config.options,
        },
        type: MessageAttachmentTypes.QUICK_REPLIES,
      });
    }

    if (config.coverImage) {
      attachments.push({
        payload: {
          coverImage: config.coverImage,
        },
        type: MessageAttachmentTypes.GREETINGS_COVER,
      });
    }

    return [
      GenerateSimpleCampaignMessage(
        {
          message: config.text,
          userId: options.userId,
          conversationId,
          attachments,
          messageData: config.messageData,
        },
        store
      ),
    ] as iMessage[];
  })();

  return {
    conversation,
    messages,
  };
}

export const GenerateSimpleCampaignMessage = (
  {
    conversationId,
    userId,
    message,
    attachments,
    messageData,
  }: {
    conversationId: string;
    userId?: string;
    message?: string;
    attachments?: iMessageAttachment[];
    messageData?: any;
  },
  store: Store
) => {
  const contact = selectContact(store.getState());
  const widget = selectContact(store.getState());
  message = ReplaceTokens(message || "", {
    CONTACT_FIRST_NAME: contact?.data?.firstName || "",
    CONTACT_LAST_NAME: contact?.data?.lastName || "",
    CONTACT_EMAIL: contact?.data?.primaryEmail || "",
    CONTACT_PHONE: contact?.data?.primaryMobile || "",
    WORKSPACE_NAME: "",
  });
  return {
    id: uuidv4(),
    conversationId: conversationId,
    organizationId: "",
    connectionId: "",
    contactId: "",
    connectionType: "LIVE_CHAT",
    from: {
      senderType: "USER",
      id: userId || "cc",
    },
    references: [],
    metaData: {
      sentAt: new Date().getTime(),
      deliveredAt: new Date().getTime(),
    },
    message: message,
    attachments: attachments || [],
    data: messageData || {},
  } as iMessage;
};
