/* eslint-disable i18next/no-literal-string */
import {
  CheckOutlined,
  Loading3QuartersOutlined,
  StarFilled,
} from "@ant-design/icons";
import { useSDKAction } from "@sdk";
import classnames from "classnames";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import React from "react";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import { useSelector } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectWidgetConfigurations } from "store/modules/session/session.selectors";
import { selectUserById } from "store/modules/users/users.selectors";

export const RoundedChatContainer = ({ className = "", ...props }) => {
  return (
    <div
      className={`component_border_radius p-5 mt-2 mx-6 theme_bg_lighter ${className}`}
    >
      {props.children}
    </div>
  );
};

export const RateUserComponent = ({
  conversationId,
  onRated,
}: {
  conversationId: string;
  onRated?: () => any;
}) => {
  const conversation = useSelector(selectConversationById(conversationId));
  const userId = conversation?.members?.[0]?.userId;
  const specialist = useSelector(selectUserById(userId));
  const specialistName = specialist?.data.firstName;

  const existingRating = conversation?.chatRating;

  const widgetConfiguration = useSelector(selectWidgetConfigurations);
  const { t } = useTranslation("common");

  const { isProcessing, doAction: rateConversation } = useSDKAction({
    action: (sdk) => (req: { rating: number; comment: string }) =>
      sdk.rateConversation(conversationId, req).then((d) => {
        onRated && onRated();
        return d;
      }),
    // successMessage: "Chat bot has been deactivated",
    failureMessage: t("SOMETHING_WENT_WRONG_MESSAGE"),
  });

  if (existingRating) {
    return (
      <div className="theme_bg_lighter flex flex-col items-center border-gray-200 pt-6">
        <div className=" text-center">{t("RATED_THE_CONVERSATION")}</div>
        <Rating
          emptySymbol={<StarFilled className="text-3xl mt-2 text-gray-300" />}
          fullSymbol={<StarFilled className="text-3xl mt-2 rating" />}
          className="rate_member"
          readonly={true}
          initialRating={existingRating.rating}
        />
      </div>
    );
  }

  // Don't show the rating if no user is there
  if (
    !userId ||
    !widgetConfiguration ||
    !widgetConfiguration?.conversationFlow?.chatRatings?.enabled
  ) {
    return <></>;
  }

  return (
    <div className="theme_bg_lighter flex flex-col items-center border-t border-gray-200 pt-6">
      <UserAvatar userId={userId} isBordered />
      <div className=" text-center">
        {specialistName} {t("HOW_THE_CONVERSATION_WENT")}
      </div>
      {/* <Spinner isActive={isProcessing} hideChildrenOnProcess={false}> */}
      <Rating
        emptySymbol={<StarFilled className="text-3xl mt-2 text-gray-300" />}
        fullSymbol={<StarFilled className="text-3xl mt-2 rating" />}
        className="rate_member"
        onClick={(rating) => {
          rateConversation({ rating: rating, comment: "" });
        }}
      />
      {/* </Spinner> */}
    </div>
  );
};

export const RatedComponent = ({ userId }) => {
  const user = useSelector(selectUserById(userId));
  const { t } = useTranslation("common");
  return (
    <RoundedChatContainer className="flex flex-col items-center mt-12">
      <div className="rounded-full h-12 w-12 flex items-center justify-center mb-4 notification_bg_green -mt-10">
        <CheckOutlined className="text-white text-lg" />
      </div>
      <div className="font-semibold">
        {user?.data.firstName} {t("THANKS_YOU_FOR_OPINION_MESSAGE")}
      </div>
    </RoundedChatContainer>
  );
};

export const UploadingFile = ({ type, senderType }) => {
  const { t } = useTranslation("common");
  return (
    <div
      className={classnames(
        senderType === "CLIENT" ? " justify-end" : "",
        "flex m-6"
      )}
    >
      {type === "image/jpeg" || type === "image/png" ? (
        <div
          className="cursor-pointer h-10 w-10 rounded-lg flex items-center justify-center"
          style={{
            backgroundImage: `url(/assets/default-image.png)`,
            backgroundSize: "cover",
          }}
        >
          <Loading3QuartersOutlined spin />
        </div>
      ) : type === "application/pdf" ? (
        <div
          className="cursor-pointer h-10 w-10"
          style={{ backgroundImage: `url(/assets/default-image.png)` }}
        >
          <Loading3QuartersOutlined spin />
        </div>
      ) : (
        <>{t("LOADING_FILE_WITH_DOTED_LINE")}(s)</>
      )}
    </div>
  );
};
