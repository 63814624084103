import { EllipsisOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { AvatarGroup } from "components/common/avatar-group/avatar-group";
import { Popover } from "components/common/popover/popover";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectContact,
  selectWidget,
} from "store/modules/session/session.selectors";
import { TopMenu } from "./top-menu";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import { replaceEntityTokens } from "utils/replace-entity-tokens";

export const HomeHeader = ({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) => {
  const { t } = useTranslation("common");

  const widget = useSelector(selectWidget);
  const _greetingMessage =
    widget?.configurations?.locale?.custom?.["GREETINGS_MESSAGE"] ||
    t("GREETINGS_MESSAGE");

  const _guideMessage =
    widget?.configurations?.locale?.custom?.["GUIDE_MESSAGE"] ||
    t("GUIDE_MESSAGE");

  const contact = useSelector(selectContact);

  const { greetingMessage, guideMessage } = useMemo(() => {
    const context = {
      CONTACT_FIRST_NAME: contact?.data.firstName,
      CONTACT_LAST_NAME: contact?.data.lastName,
    };
    return {
      greetingMessage: convertHtmlToPlainText(
        replaceEntityTokens(_greetingMessage, context)
      ),
      guideMessage: convertHtmlToPlainText(
        replaceEntityTokens(_guideMessage, context)
      ),
    };
  }, [
    _greetingMessage,
    _guideMessage,
    contact?.data.firstName,
    contact?.data.lastName,
  ]);

  return (
    <div
      style={{
        ...style,
      }}
      className={classNames(
        "flex flex-col text-white animate fade-in-down",
        className
      )}
    >
      <div className="flex flex-col max-container w-full p-6 pt-2">
        <div className="top-menu flex flex-row justify-end items-center  w-full">
          <Popover overLay={<TopMenu />} style={{ width: "18rem" }}>
            <EllipsisOutlined className="text-3xl cursor-pointer mt-3 h-8 w-12 flex items-center justify-center rounded-full hover:bg-black hover:bg-opacity-25 outline-none" />
          </Popover>
        </div>
        <div className="greeting-message-container text-3xl font-bold w-full mt-24">
          {greetingMessage}
        </div>
        <div className="guide-message-container text-lg  w-full">
          {guideMessage}
        </div>
        <div className="user-list relative mt-4  w-full" style={{}}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <AvatarGroup maxCount={4} avatarSize={"3.2rem"} />
        </div>
      </div>
    </div>
  );
};
