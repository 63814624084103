import { uniq } from "lodash";
import { PaginatedResults } from "../../@sdk/utils/paginated-results";

export interface ListQueryState {
  list: string[];
  lastFetched: number;
  isLoading: boolean;
  hasError?: boolean;
  totalItems?: number;
  lastFetchedPage?: number;
}

export const defaultQueryValue: ListQueryState = {
  list: [],
  lastFetched: 0,
  isLoading: false,
  hasError: false,
};

export const setQueryListState = (
  state: {
    byIds: {
      [id: string]: any;
    };
    queries: {
      [query: string]: ListQueryState;
    };
  },
  queryName: string,
  list: any[]
) => {
  state.queries[queryName] = {
    lastFetched: Date.now(),
    isLoading: false,
    hasError: false,
    list: [],
  };
  for (const item of list) {
    state.queries[queryName].list.push(item.id!);
    state.byIds[item.id!] = item;
  }
};

export const setPaginatedQueryListState = (
  state: {
    byIds: {
      [id: string]: any;
    };
    queries: {
      [query: string]: ListQueryState;
    };
  },
  queryName: string,
  res: PaginatedResults<any>
) => {
  if (!state.queries[queryName] || state.queries[queryName].hasError) {
    if (res.page !== 1) {
      throw "Page should start with 1";
    }
    state.queries[queryName] = {
      lastFetched: Date.now(),
      isLoading: false,
      hasError: false,
      list: [],
      lastFetchedPage: 1,
      totalItems: res.totalDocs,
    };
    for (const item of res.docs) {
      state.queries[queryName].list.push(item.id!);
      state.byIds[item.id!] = item;
    }
  } else {
    if ((state.queries[queryName].lastFetchedPage || 0) + 1 >= res.page) {
      Object.assign(state.queries[queryName], {
        lastFetched: Date.now(),
        isLoading: false,
        hasError: false,
        lastFetchedPage: Math.max(
          res.page,
          state.queries[queryName].lastFetchedPage || 0
        ),
        totalItems: res.totalDocs,
      });
      for (const item of res.docs) {
        state.queries[queryName].list.push(item.id!);
        state.byIds[item.id!] = item;
      }
      state.queries[queryName].list = uniq(state.queries[queryName].list);
    } else {
      console.error(
        "Error while setting state. 1) State accepts only immediate next page query  and past page query results 2) Paginated data is not found",
        {
          state: JSON.parse(JSON.stringify(state.queries[queryName])),
          res,
          queryName,
        }
      );
      throw "Error while setting state. 1) State accepts only immediate next page query  and past page query results 2) Paginated data is not found";
    }
  }
};
