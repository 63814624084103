import { EllipsisOutlined, UploadOutlined } from "@ant-design/icons";
import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { MessageAttachmentTypes } from "@sdk/services/models";
import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { Popover } from "components/common/popover/popover";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { TeamAvatar } from "components/common/team-avatar/team-avatar";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import { getUserName } from "helpers/get-user-name";
import React, { useEffect, useMemo, useState } from "react";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectUserById } from "store/modules/users/users.selectors";
import { useNavigationAnimationPatch } from "utils/hooks/use-navigation-animation-patch";
import { ChatFooter } from "./components/chat-footer";
import { ConversationMenu } from "./components/conversation-menu";
import { RateUserComponent } from "./components/message-components";
import { MessagesListBox } from "./components/message-list-box/messages-list-box";
import "./conversation.page.scss";
import { selectWidget } from "store/modules/session/session.selectors";
import { Button } from "components/common/button/button";
import { LinkedTicketIndicator } from "./components/linked-ticket-indicator/linked-ticket-indicator";

export const ConversationPage = () => {
  const { t, i18n } = useTranslation("common");

  const dispatch = useDispatch();
  const history = useHistory();
  const conversationId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[2];
  }, [history.location.pathname]);

  const conversation = useSelector(selectConversationById(conversationId));

  const messagesVisibility = useNavigationAnimationPatch();

  const [isFileDropActive, setFileDropStatus] = useState(false);
  const [fileUploadingStatus, setFileUploadingStatus] = useState(false);

  const onFileDropped = (
    files: FileList | null,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    if (files) {
      // Todo: If it is a temporary conversation, we should show an error
      if (conversation.isTemporary) {
        return;
      }
      const numberOfFiles = files?.length;
      numberOfFiles && setFileUploadingStatus(true);
      for (let x = 0; x < numberOfFiles; x++) {
        const file = files[x];
        SDK.uploadFile(files[x], {
          type: file.type,
          entityId: conversationId,
        }).then((data) => {
          SDK.sendMessage(conversationId!, {
            text: file.name,
            attachments: [
              {
                payload: {
                  file: {
                    url: data.url,
                  },
                  type: data.reference.type,
                },
                type: MessageAttachmentTypes.FILE,
              },
            ],
          })
            .then((d) => {
              setFileUploadingStatus(false);
            })
            .catch((e) => {
              setFileUploadingStatus(true);
              // Todo: Alert User
            });
        });
      }
    }
  };

  const joinedUser = useSelector(
    selectUserById(conversation?.members?.[0]?.userId)
  );

  const widget = useSelector(selectWidget);
  const teamName =
    widget?.configurations?.locale?.custom?.["TEAM_NAME"] ||
    t("DEFAULT_TEAM_NAME");

  const salesTeamTagLine =
    widget?.configurations?.locale?.custom?.["SALES_TEAM_TAGLINE"] ||
    t("SALES_TEAM_TAGLINE");

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={
            <div className="flex flex-row">
              <div className="user-avatar-container" style={{ marginTop: 4 }}>
                {conversation?.members.length > 0 ? (
                  <UserAvatar
                    userId={conversation.members?.[0]?.userId}
                    isBordered
                    size={42}
                  />
                ) : (
                  <TeamAvatar isBordered size={40} />
                )}
              </div>
              <div className="flex-col pl-4">
                {conversation?.members.length === 0 ? (
                  <>
                    <div className="title truncate line-clamp-1">{teamName}</div>
                    <div className="slogan text-sm font-normal line-clamp-1">
                      {salesTeamTagLine}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title line-clamp-1">{getUserName(joinedUser)}</div>
                    <div className="slogan text-sm font-normal line-clamp-1">
                      {joinedUser?.data?.description || teamName}
                    </div>
                  </>
                )}
              </div>
            </div>
          }
          menu={
            <div className="top-menu">
              <Popover
                overLay={<ConversationMenu conversationId={conversationId} />}
                style={{ width: "18rem" }}
              >
                <EllipsisOutlined className="text-3xl cursor-pointer h-8 w-12 flex items-center justify-center rounded-full hover:bg-black hover:bg-opacity-25 outline-none" />
              </Popover>
            </div>
          }
        />
        <LinkedTicketIndicator conversationId={conversationId} />
        <div className="primary-bg flex-1 overflow-hidden">
          <FileDrop
            onFrameDragEnter={(event) => {
              setFileDropStatus(true);
            }}
            onFrameDragLeave={(event) => {
              setFileDropStatus(false);
            }}
            onFrameDrop={(event) => {
              setFileDropStatus(false);
            }}
            onDrop={onFileDropped}
          >
            <div
              className="messages-list-container h-full w-full flex-1 px-2 overflow-hidden bg-white relative"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              {messagesVisibility && (
                // Messages List
                <MessagesListBox conversationId={conversationId} />
              )}
              {isFileDropActive && !conversation?.isTemporary && (
                <div className="p-8 h-full w-full absolute top-0 left-0 bg-white opacity-75 z-50">
                  <div className="  border-dashed border-2 rounded-lg flex flex-col items-center justify-center text-xl h-full">
                    <div className="rounded-lg bg-gray-200 h-12 w-12 flex items-center justify-center">
                      <UploadOutlined className="text-2xl" />
                    </div>
                    {t("DROP_FILE_TEXT")}
                  </div>
                </div>
              )}
            </div>
          </FileDrop>
        </div>

        {fileUploadingStatus && (
          <div className="bg-gray-800 text-white text-center p-2 flex flex-row justify-center items-center">
            {t("UPLOADING_ATTACHMENT_TEXT")}{" "}
            <LoadingIndicator size={30} style={{ color: "white" }} />
          </div>
        )}
        {conversation?.status === "CLOSED" && (
          <>
            <RateUserComponent conversationId={conversationId} />
            <div className="flex flex-col p-2 items-center text-center text-sm text-gray-600">
              {t("CONVERSATION_CLOSED_TEXT")}
              <br />
              {t("GO_BACK_TEXT")}
            </div>
          </>
        )}
        {conversation?.status !== "CLOSED" && (
          <ChatFooter
            conversationId={conversationId}
            onFileDropped={onFileDropped}
            isFileDropActive={isFileDropActive}
          />
        )}
      </div>
    </div>
  );
};
