import React, { Suspense } from "react";

const FormInput = React.lazy(() => import("./form-input"));

export const LazyFormInput = (props: {
  primaryConversationId: string;
  type: "TICKET" | "FORM";
  ticketId: string;
  formId: string;
}) => {
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <Suspense fallback={<>...</>}>
      <FormInput {...props} />
    </Suspense>
  );
};
