import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { Button } from "components/common/button/button";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { FaqIcon } from "components/common/icons/faq-icon";
import { SearchIcon } from "components/common/icons/search";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import { filter } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import {
  selectAllFAQs,
  selectFaqCollections,
} from "store/modules/session/session.selectors";
import { useSearch } from "utils/hooks/use-search";

export const SearchFAQPage = ({ disableBack }: { disableBack?: boolean }) => {
  const { t } = useTranslation("common");

  const store = useStore();
  const navigator = useNavigator();
  // const { state: allFaqs, isLoading } = useSimpleLoaderStore(
  //   selectAllArticles,
  //   loadAllArticles
  // );

  // const {
  //   state: allCollections,
  //   isLoading: isCollectionsLoading,
  // } = useSimpleLoaderStore(
  //   selectAllArticleCollections,
  //   loadAllArticleCollections
  // );

  const allCollections = useSelector(selectFaqCollections);
  const allFaqs = useSelector(selectAllFAQs);

  const searchController = useSearch(allFaqs || []);

  const [activeKeys, setActiveKeys] = useState([0] as number[]);
  const onClickCollapse = (key) => {
    const initialActiveKeys = [...activeKeys];
    if (activeKeys.includes(key)) {
      const indexOfElement = initialActiveKeys.indexOf(key);
      initialActiveKeys.splice(indexOfElement, 1);
    } else {
      initialActiveKeys.push(key);
    }
    setActiveKeys(initialActiveKeys);
  };

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={t("FAQS_PAGE_TITLE")}
          disableBack={disableBack}
          icon={
            <div className="dark-bg rounded-lg p-2 flex justify-center items-center">
              <FaqIcon size={'1.3rem'} />
            </div>
          }
        />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className="h-full w-full flex-1 p-4 bg-white relative"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            {/* Search BAr */}
            <div className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md">
              <input
                placeholder={t("TYPE_YOUR_QUESTIONS_PLACEHOLDER")}
                className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
                onChange={(el) => {
                  searchController.setSearchTerm(el.currentTarget.value);
                }}
                autoFocus
              />
              <SearchIcon className="mr-2" />
            </div>

            {/* Results */}
            {!searchController.searchTerm && (
              <div className="animate fade-in-up">
                <div className="mt-6 text-gray-800 mb-8">
                {t("FAQ_SEARCH_HELP")}
                </div>
                {allCollections?.map((collection, index) => {
                  return (
                    /*===================================================categories-link-box===================================================*/
                    <React.Fragment key={index.toString()}>
                      <div
                        key={index}
                        className={classnames(
                          activeKeys.includes(index)
                            ? "bg-gray-200 text-primary"
                            : "article-collection-heading",
                          " mb-2 flex justify-between items-center rounded-lg cursor-pointer font-semibold"
                        )}
                        style={{ height: "44px" }}
                        onClick={() => {
                          onClickCollapse(index);
                        }}
                      >
                        <div
                          className="ml-4 whitespace-no-wrap overflow-hidden mr-8"
                          style={{ textOverflow: "ellipsis" }}
                        >
                          {collection.label}
                        </div>
                        <div className="mr-3 ">
                          {activeKeys.includes(index) ? (
                            <MinusOutlined className="flex items-center text-lg text-primary mr-5" />
                          ) : (
                            <PlusOutlined className="flex items-center text-lg" />
                          )}
                        </div>
                      </div>
                      <div
                        className={classnames(
                          activeKeys.includes(index) ? "mb-4" : "",
                          "overflow-hidden transition-all duration-500"
                        )}
                        style={{
                          maxHeight: activeKeys.includes(index) ? 1000 : 0,
                        }}
                      >
                        {filter(allFaqs, {
                          collectionId: collection.id,
                        }).map((faq, faqIndex) => {
                          return (
                            <div
                              key={faq.id}
                              className="flex flex-row pointer hover:bg-gray-200 p-2 cursor-pointer justify-between items-center rounded-lg"
                              onClick={() => {
                                navigator.navigateTo(`/faq/${faq.id}`);
                              }}
                            >
                              <div
                                className="title whitespace-no-wrap overflow-hidden mr-4"
                                style={{ textOverflow: "ellipsis" }}
                              >
                                {faq.question}
                              </div>
                              <ArrowRightIcon />
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {searchController.searchTerm && (
              <div className="animate fade-in-up">
                <div className="text-lg font-semibold mt-6 mb-3">
                  {t("SEARCH_RESULTS_TITLE")}
                </div>
                {searchController.filteredData.map((faq) => (
                  <div
                    key={faq.id}
                    className="flex flex-row pointer hover:bg-gray-100 p-4 px-8 cursor-pointer justify-between items-center"
                    onClick={() => {
                      navigator.navigateTo(`/faq/${faq.id}`);
                    }}
                  >
                    <div className="title">{faq.question}</div>
                    <ArrowRightIcon />
                  </div>
                ))}
                {searchController.filteredData.length === 0 && (
                  <div className="border-dashed border rounded-lg m-8 mb-2 p-8 flex flex-col justify-center items-center">
                    <SearchIcon size={40} className="text-gray-600" />
                    <div className="text-gray-600 text-center">
                      {t("NO_RESULTS")}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col justify-center items-center  animate fade-in-up">
              <div className="mt-16 mb-2 text-sm text-gray-800 flex justify-center">
                {t("NO_ANSWER_TEXT")}
              </div>

              <div className="border border-primary rounded-lg">
                <Button
                  label={t("CHAT_WITH_US_BUTTON")}
                  onClick={async () => {
                    const { conversation } = await initLocalConversation(store);
                    navigator.navigateTo(`/conversations/${conversation.id}`);
                  }}
                  type="secondary-button"
                  className="py-1"
                />
              </div>
            </div>
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};
