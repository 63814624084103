import classNames from "classnames";
import { FaqIcon, FaqIconSolid } from "components/common/icons/faq-icon";
import { HomeIcon, HomeIconSolid } from "components/common/icons/home-icon";
import {
  KnowledgeBaseIcon,
  KnowledgeBaseIconSolid,
} from "components/common/icons/knowledge-base-icon";
import {
  ProfilePreferencesIcon,
  ProfilePreferencesIconSolid,
} from "components/common/icons/profile-preferences";
import { orderBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { setLastVisitedTab } from "store/modules/app-state/app-state.actions";
import { selectLastVisitedTab } from "store/modules/app-state/app-state.selectors";
import { loadAllConversations } from "store/modules/conversations/conversations.helpers";
import {
  selectAllConversations,
  selectUnreadMessages,
} from "store/modules/conversations/conversations.selectors";
import {
  selectContact,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { KbHomePage } from "views/knowledge-base-pages/kb-home.page";
import { SearchFAQPage } from "views/search-faqs-page/search-faq.page";
import "./home.scss";
import { HomeTab } from "./tabs/home-tab";
import { PreferencesTab } from "./tabs/preferences-tab";
import { useTranslation } from "react-i18next";
import { selectAllTickets } from "store/modules/tickets/tickets.selectors";
import { loadAllTickets } from "store/modules/tickets/tickets.helpers";
import {
  FolderIcon,
  FolderIconSolid,
} from "components/common/icons/folder-icon";
import { TicketListPage } from "views/tickets-list-page/ticket-list.page";
import { ProfileTab } from "./tabs/profile-tab";

const BottomTabIcon = ({
  id,
  icon,
  label,
  setSelectedTab,
  selectedTab,
}: {
  setSelectedTab: (id: string) => any;
  id: string;
  label: string;
  icon: JSX.Element;
  selectedTab: string;
}) => {
  return (
    <div
      className={classNames(
        "flex-1 flex flex-col justify-center items-center cursor-pointer hover:bg-gray-100 h-full rounded-lg",
        { " font-bold text-primary": selectedTab === id }
      )}
      onClick={() => setSelectedTab(id)}
      id={`bottom-tab-${id}`}
    >
      {icon}
      <div className="text-xs">{label}</div>
    </div>
  );
};

export const HomePage = () => {
  const store = useStore();

  const _selectedTab = useSelector(selectLastVisitedTab);
  const selectedTab = _selectedTab || "HOME";

  const setSelectedTab = useCallback(
    (tab: string) => {
      store.dispatch(setLastVisitedTab(tab));
    },
    [store]
  );

  const { state: conversations, isLoading } = useSimpleLoaderStore(
    selectAllConversations,
    loadAllConversations
  );

  const hasUnread = useMemo(() => {
    const recentConversations = orderBy(
      conversations,
      ["metaData.lastMessage.timestamp"],
      ["desc"]
    ).slice(0, 2);
    const hasUnread = recentConversations
      .filter((item) => !item.isTemporary)
      .map(
        (item) =>
          selectUnreadMessages(item.connectionId)(store.getState()).hasUnread
      )
      .filter((hasUnread) => hasUnread);
    return hasUnread.length > 0;
  }, [conversations, store]);

  const widget = useSelector(selectWidget);
  const contact = useSelector(selectContact);

  const { state: tickets, isLoading: isTicketsLoading } = useSimpleLoaderStore(
    selectAllTickets,
    loadAllTickets
  );

  const availableTabs = useMemo(() => {
    const tabs = ["HOME"];
    if (widget?.configurations.knowledgeBase?.isActive) {
      tabs.push("KNOWLEDGE_BASE");
    }
    if (widget?.configurations.shopifyPlugin?.isActive) {
      // tabs.push("KNOWLEDGE_BASE");
    }
    if ((widget?.configurations?.faqCollections?.length || 0) > 0) {
      tabs.push("FAQS");
    }
    if (tickets.length > 0) {
      tabs.push("TICKETS");
    }
    return tabs;
  }, [
    tickets.length,
    widget?.configurations?.faqCollections?.length,
    widget?.configurations.knowledgeBase?.isActive,
    widget?.configurations.shopifyPlugin?.isActive,
  ]);

  const tabContainerStyle = useMemo(() => {
    let offset = 0;
    if (availableTabs.length > 1) {
      offset += 75;
    }
    // if (showPoweredBy) {
    //   offset += 30;
    // }
    if (offset > 0) {
      return {
        height: `calc(100% - ${offset}px)`,
      };
    }
    return {};
  }, [availableTabs.length]);

  const { t, i18n } = useTranslation("common");

  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = document.getElementById(`bottom-tab-${selectedTab}`);
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };
    setTabPosition();
  }, [selectedTab]);

  return (
    <div className="h-full w-full flex flex-col">
      <div className="tab-container flex-1" style={tabContainerStyle}>
        {selectedTab === "HOME" && <HomeTab />}
        {selectedTab === "FAQS" && <SearchFAQPage disableBack={true} />}
        {selectedTab === "KNOWLEDGE_BASE" && <KbHomePage disableBack={true} />}
        {selectedTab === "PREFERENCES" && <PreferencesTab />}
        {selectedTab === "PROFILE" && <ProfileTab />}
        {selectedTab === "TICKETS" && <TicketListPage disableBack={true} />}
      </div>

      {availableTabs.length > 1 && (
        <div
          className="bottom-tabs flex flex-row justify-center items-center  border-t border-gray-200 z-50 bg-white relative"
          style={{ height: 70, boxShadow: "0px 0px 30px 10px #0000000f" }}
        >
          <span
            className="absolute bottom-0 top-0 flex overflow-hidden rounded-xl transition-all duration-300 flex-col"
            style={{
              left: tabUnderlineLeft,
              width: tabUnderlineWidth,
              zIndex: 10,
            }}
          >
            <span className="h-1 w-full rounded-lg bg-gray-300" />
          </span>
          <span
            className="absolute bottom-0 top-0 flex overflow-hidden rounded-xl transition-all duration-300 flex-col"
            style={{
              left: tabUnderlineLeft,
              width: tabUnderlineWidth,
              zIndex: -10,
            }}
          >
            <span className="h-full w-full rounded-lg bg-gray-200" />
          </span>
          <BottomTabIcon
            id={"HOME"}
            label={t("TAB_LABEL_HOME")}
            icon={
              <div className="relative">
                {selectedTab === "HOME" ? <HomeIconSolid /> : <HomeIcon />}

                {hasUnread && (
                  <div
                    className="text-white bg-red-600 h-3 w-3 rounded-full absolute"
                    style={{ top: -6, right: -6 }}
                  ></div>
                )}
              </div>
            }
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {availableTabs.includes("TICKETS") && (
            <BottomTabIcon
              id={"TICKETS"}
              label={t("TAB_LABEL_TICKETS")}
              icon={
                selectedTab === "TICKETS" ? <FolderIconSolid /> : <FolderIcon />
              }
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          )}
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {availableTabs.includes("FAQS") && (
            <BottomTabIcon
              id={"FAQS"}
              label={t("TAB_LABEL_FAQS")}
              icon={selectedTab === "FAQS" ? <FaqIconSolid /> : <FaqIcon />}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          )}
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {availableTabs.includes("KNOWLEDGE_BASE") && (
            <BottomTabIcon
              id={"KNOWLEDGE_BASE"}
              label={t("TAB_LABEL_KNOWLEDGE_BASE")}
              icon={
                selectedTab === "KNOWLEDGE_BASE" ? (
                  <KnowledgeBaseIconSolid />
                ) : (
                  <KnowledgeBaseIcon />
                )
              }
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          )}

          {/* {contact && (
            <BottomTabIcon
              id={"PREFERENCES"}
              label={t("TAB_LABEL_PREFERENCES")}
              icon={<ProfilePreferencesIcon />}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          )} */}
          {/* Todo - Add config */}
          {widget?.configurations?.customerSignIn?.isEnabled && (
            <BottomTabIcon
              id={"PROFILE"}
              label={t("TAB_LABEL_PROFILE")}
              icon={
                selectedTab === "PROFILE" ? (
                  <ProfilePreferencesIconSolid />
                ) : (
                  <ProfilePreferencesIcon />
                )
              }
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          )}
        </div>
      )}
    </div>
  );
};

const poweredByReveresLanguage = ["tr"];

export const PoweredByLabel = ({
  label,
  language,
}: {
  label: string;
  language: string;
}) => {
  if (poweredByReveresLanguage.includes(language)) {
    return (
      <div className="powered-by-cc flex flex-row justify-center items-center bg-white text-sm font-semibold p-2">
        <img
          src="/logo192.png"
          alt="CC Logo"
          style={{
            height: 20,
            filter: "brightness(0)",
            marginRight: "0.1rem",
          }}
          // className="mr-2"
        />
        <a
          href={"https://clickconnector.com/"}
          target="_blank"
          className="mr-1"
          // eslint-disable-next-line i18next/no-literal-string
        >
          ClickConnector
        </a>
        {label}
      </div>
    );
  }

  // TOdo
  return (
    <div className="powered-by-cc flex flex-row justify-center items-center bg-white text-sm font-semibold p-2">
      <img
        src="/logo192.png"
        alt="CC Logo"
        style={{
          height: 20,
          filter: "brightness(0)",
          marginRight: "0.1rem",
        }}
        // className="mr-2"
      />
      {label}
      <a
        href={"https://clickconnector.com/"}
        target="_blank"
        className="ml-1"
        // eslint-disable-next-line i18next/no-literal-string
      >
        ClickConnector
      </a>
    </div>
  );
};
