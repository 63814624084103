import { ParentWindowService } from "@parent-window/parent-window";
import { SDK } from "@sdk";
import {
  iChatBot,
  iChatBotFlowConfig,
  iMessageNode,
} from "@sdk/services/chat-bot-models";
import { iCampaign, TriggerConditionPresets } from "@sdk/services/models";
import { push } from "connected-react-router";
import { initLocalCampaignConversation } from "helpers/init-local-conversation";
import { find, last, uniq, without } from "lodash";
import { store } from "store/store";
import { getUrlData, UrlDataObj } from "utils/get-url-data";
import { justWait } from "utils/just-wait";
import {
  selectAllConversations,
  selectConversationsMapAll,
} from "../conversations/conversations.selectors";
import { setConversationsQueryList } from "../conversations/conversations.slice";
import { selectSession, selectWidget } from "../session/session.selectors";
import { selectAllActiveUsers } from "../users/users.selectors";
import {
  addCampaignEvent,
  clearActiveCampaign,
  setActiveCampaign,
} from "./app-state.actions";
import { selectCampaignState } from "./app-state.selectors";
import { GenerateSimpleCampaign } from "./helpers/generate-simple-campaign";
import { iActivatedMessageCampaign } from "@sdk/services/activated-message-campaigns-models";

export class CampaignsController {
  public static VISIT_TIMEOUT = 30 * 60 * 1000;

  public static CAMPAIGN_TRIGGERED = false;

  public static READY = false;
  public static STARTED = false;
  public static ActiveMessageCampaigns: iActivatedMessageCampaign[] = [];

  public static LoadedChatBots: { [chatBotId: string]: Partial<iChatBot> } = {};

  public static async init() {
    if (!this.STARTED) {
      this.STARTED = true;
      await justWait(400); // Not sure why i added this
      await this.loadActiveMessageCampaigns();
      this.initTimer();
      this.reStoreCampaignState();
      this.READY = true;
    }
  }

  public static async loadActiveMessageCampaigns() {
    const activeMessageCampaigns = await SDK.getActiveMessageCampaigns();
    this.ActiveMessageCampaigns = activeMessageCampaigns;
  }

  public static async onMessageCampaignActivated(messageCampaignId: string) {
    const campaign = this.ActiveMessageCampaigns.find(
      (item) => item.id === messageCampaignId
    );
    // Mark Campaign as seen locally and remotely
    if (campaign && !campaign?.isSeen) {
      campaign.isSeen = true;
      await SDK.markMessageCampaignAsSeen(messageCampaignId);
    }
  }

  public static initTimer() {
    let intervalCount = 0;
    const interval = setInterval(async () => {
      await this.run();
      if (intervalCount > 12) {
        clearInterval(interval);
      }
      intervalCount++;
    }, 5000);
  }

  public static addEvent(pageData: {
    url: string;
    title: string;
    visitTimestamp: number;
    referer: string;
  }) {
    store.dispatch(
      addCampaignEvent({
        ...pageData,
        pageData: getUrlData(pageData.url),
      })
    );
    if (this.STARTED) {
      this.run();
    }
  }

  public static async getChatbotConfig(widgetId: string, chatBotId: string) {
    if (!this.LoadedChatBots[chatBotId]) {
      const chatBotConfig = await SDK.getChatBotConfiguration(
        widgetId!,
        chatBotId
      );
      this.LoadedChatBots[chatBotId] = chatBotConfig;
    }
    const chatBotConfig = this.LoadedChatBots[chatBotId];

    const chatBotFlow = chatBotConfig?.flow as iChatBotFlowConfig;
    // Better Way to start the node
    let startNode = (chatBotFlow?.nodes || []).find(
      (item) => item.id === "START"
    )?.data as iMessageNode;

    if (!startNode) {
      startNode = chatBotFlow?.nodes?.[0]?.data as iMessageNode;
    }
    return {
      text: startNode?.text,
      // quickReplies: startNode.quickButtons.map((item) => ({
      //   label: item.label,
      //   action: "MESSAGE",
      //   data: {},
      // })),
      coverImage: undefined,
      chatBotId: chatBotId,
      options: {
        additionalMessageData: {
          botContext: startNode,
        },
      },
    };
  }

  public static async getRegisteredCampaigns() {
    const campaignState = selectCampaignState(store.getState());
    const widget = selectWidget(store.getState())!;
    const session = selectSession(store.getState())!;
    let registeredCampaigns = [
      ...(widget?.configurations.conversationFlow.campaigns || []),
    ];

    const activeMessageCampaigns: iCampaign[] = [];

    for (const item of this.ActiveMessageCampaigns) {
      if (item.messageConfig?.chatbotId) {
        const chatBotConfig = await CampaignsController.getChatbotConfig(
          widget.id!,
          item.messageConfig?.chatbotId
        );
        // console.log("chatBotConfig", chatBotConfig);
        activeMessageCampaigns.push({
          ...GenerateSimpleCampaign({
            id: item.id!,
            text: chatBotConfig.text,
            quickReplies: undefined,
            coverImage: chatBotConfig.coverImage,
            chatBotId: item.messageConfig?.chatbotId,
            messageCampaignId: item.id,
            options: chatBotConfig.options,
          }),
        });
      } else {
        activeMessageCampaigns.push({
          ...GenerateSimpleCampaign({
            id: item.id!,
            text: item.messageConfig?.message || "",
            quickReplies: undefined,
            coverImage: undefined,
            chatBotId: item.messageConfig?.chatbotId,
            messageCampaignId: item.id,
            options: {
              // Todo
            },
          }),
        });
      }
    }

    console.log("activeMessageCampaigns", activeMessageCampaigns);
    // Add Activated Message Campaigns
    registeredCampaigns = [...registeredCampaigns, ...activeMessageCampaigns];

    const autoTriggerConfig =
      widget?.configurations.conversationFlow.greetingsMessage;
    if (autoTriggerConfig && autoTriggerConfig.autoTrigger) {
      if (autoTriggerConfig.chatBotId) {
        const chatBotConfig = await CampaignsController.getChatbotConfig(
          widget.id!,
          autoTriggerConfig.chatBotId
        );

        if (chatBotConfig?.text) {
          registeredCampaigns.push(
            GenerateSimpleCampaign(
              {
                id: "DEFAULT_GREETINGS_CHAT_BOT",
                text: chatBotConfig.text,
                // quickReplies: startNode.quickButtons.map((item) => ({
                //   label: item.label,
                //   action: "MESSAGE",
                //   data: {},
                // })),
                coverImage: chatBotConfig.coverImage,
                chatBotId: chatBotConfig.chatBotId,
                options: chatBotConfig.options,
              } // autoTriggerConfig.coverImage
            )
          );
        }
      } else {
        registeredCampaigns.push(
          GenerateSimpleCampaign({
            id: "DEFAULT_GREETINGS",
            text: autoTriggerConfig.text,
            quickReplies: autoTriggerConfig.options,
            coverImage: autoTriggerConfig.coverImage,
          })
        );
      }
    }
    if (
      session?.chatInvite &&
      session?.chatInvite.invitedTime + 60 * 60 * 1000 > Date.now()
    ) {
      registeredCampaigns.push(
        GenerateSimpleCampaign({
          id: "INVITE_MESSAGE",
          text: session.chatInvite.message,
          quickReplies: undefined,
          coverImage: undefined,
          chatBotId: undefined,
          options: {
            additionalMessages: session.chatInvite.subsequentMessages,
            userId: session.chatInvite.invitedBy,
          },
        })
      );
    }
    return registeredCampaigns;
  }

  public static async run() {
    const campaignState = selectCampaignState(store.getState());
    const conversations = selectAllConversations(store.getState());
    const openConversation = find(conversations, { status: "OPEN" });
    const allActiveUsers = selectAllActiveUsers(store.getState());
    const onlineUsers = find(allActiveUsers, { "metaData.available": true });

    // console.log(
    //   "Run Campaign",
    //   campaignState.activatedCampaign,
    //   this.CAMPAIGN_TRIGGERED,
    //   openConversation,
    //   allActiveUsers
    // );

    if (campaignState.activatedCampaign) {
      return;
    }
    if (this.CAMPAIGN_TRIGGERED) {
      return;
    }

    if (openConversation) {
      return;
    }
    // if ((conversations || []).length > 0) {
    //   return;
    // }
    // if (!onlineUsers) {
    //   return;
    // }

    const registeredCampaigns = await this.getRegisteredCampaigns();

    const currentVisit = last(campaignState.eventTraces.pagesVisits);

    for (const campaign of registeredCampaigns) {
      const triggerCondition = campaign.data.triggerCondition;
      if (triggerCondition.preset === TriggerConditionPresets.NONE) {
        // console.log(
        //   "TriggerConditionPresets.NONE",
        //   TriggerConditionPresets.NONE
        // );
        const DELAY = 5000;
        if (
          triggerCondition.data &&
          triggerCondition.data.forUtmTags &&
          triggerCondition.data.forUtmTags.length > 0
        ) {
          let conditionMatchedOnce = false;

          let conditionFailed = false;
          // console.log('campaignState.eventTraces.pagesVisits', campaignState.eventTraces.pagesVisits);
          for (const pageVisit of campaignState.eventTraces.pagesVisits) {
            if (pageVisit.pageData) {
              for (const condition of triggerCondition.data.forUtmTags) {
                if (
                  pageVisit.pageData.queryParams[condition.tag] ===
                  condition.value
                ) {
                  conditionMatchedOnce = true;
                } else {
                  conditionFailed = true;
                }
              }
            }
          }

          //* AND Condition ix executed as logic to trigger the campaign

          if (
            conditionMatchedOnce &&
            !conditionFailed &&
            currentVisit &&
            currentVisit.visitTimestamp + DELAY < new Date().getTime()
          ) {
            // *Trigger the campaign
            await this.triggerCampaignWithCampaignId(campaign.id);
            break;
          }
        } else if (
          triggerCondition.data &&
          (triggerCondition.data.pageContains?.length || 0) > 0
        ) {
          if (
            currentVisit &&
            currentVisit.visitTimestamp + DELAY < new Date().getTime() &&
            currentVisit?.url.includes(triggerCondition.data.pageContains || "")
          ) {
            // *Trigger the campaign
            await this.triggerCampaignWithCampaignId(campaign.id, {
              isTemporary: true,
            });
            break;
          }
        } else {
          // No UTM Conditions are given or not pageContains is given
          // console.log("currentVisit", currentVisit);
          if (
            currentVisit &&
            currentVisit.visitTimestamp + DELAY < new Date().getTime()
          ) {
            // *Trigger the campaign
            await this.triggerCampaignWithCampaignId(campaign.id);
            break;
          }
        }
      } else if (
        triggerCondition.preset ===
        TriggerConditionPresets.ON_EVENT_RECEIVED_FROM_PARENT
      ) {
        // Todo: No need to handle it now
      } else if (
        triggerCondition.preset ===
        TriggerConditionPresets.ON_NAVIGATION_TO_SECOND_PAGE
      ) {
        if (campaignState.eventTraces.pagesVisits.length > 1) {
          // *Trigger the campaign
          await this.triggerCampaignWithCampaignId(campaign.id);
          break;
        }
      }
    }
  }

  public static async triggerCampaignWithCampaignId(
    campaignId: string,
    options: {
      userId?: string;
      muteNotifications?: boolean;
      isTemporary?: boolean;
    } = {}
  ) {
    const registeredCampaigns = await this.getRegisteredCampaigns();
    const campaign = find(registeredCampaigns, { id: campaignId });
    if (campaign) {
      this.triggerCampaign(campaign, options);
    }
  }

  public static async triggerChatbotAsCampaign(chatbotId: string) {
    const widget = selectWidget(store.getState())!;
    const chatBotConfig = await CampaignsController.getChatbotConfig(
      widget.id!,
      chatbotId
    );

    const campaign = GenerateSimpleCampaign(
      {
        id: "DEFAULT_GREETINGS_CHAT_BOT",
        text: chatBotConfig.text,
        // quickReplies: startNode.quickButtons.map((item) => ({
        //   label: item.label,
        //   action: "MESSAGE",
        //   data: {},
        // })),
        coverImage: chatBotConfig.coverImage,
        chatBotId: chatbotId,
        options: chatBotConfig.options,
      } // autoTriggerConfig.coverImage
    );

    await this.dismissAllCampaigns();
    await this.triggerCampaign(campaign);
  }

  public static async triggerCampaign(
    campaign: iCampaign,
    options: {
      userId?: string;
      muteNotifications?: boolean;
      isTemporary?: boolean;
    } = {}
  ) {
    // console.log("triggerCampaign", campaign, options);
    // Todo:
    const { campaignUser } = initLocalCampaignConversation(
      campaign,
      options
    )(store);
    if (!options.isTemporary) {
      store.dispatch(
        setActiveCampaign({ campaignId: campaign.id, campaignUser })
      );
    }
    this.CAMPAIGN_TRIGGERED = true;
    if (campaign.data.messageCampaignId) {
      this.onMessageCampaignActivated(campaign.data.messageCampaignId);
    }
  }

  public static dismissAllCampaigns(withoutNavigation?: boolean) {
    const allConversationIds =
      selectConversationsMapAll(store.getState())?.list || [];
    const newConversationList = allConversationIds.filter(
      (id) => !id.includes("TEMP")
    );
    const widget = selectWidget(store.getState());

    store.dispatch(
      setConversationsQueryList({
        query: "all",
        list: newConversationList,
      })
    );
    // Todo: Go to home screen
    if (!withoutNavigation) {
      store.dispatch(push(`/`));
    }

    // Send Signal to Parent Window to remove the campaign
    ParentWindowService.dismissChatBubble();
    this.CAMPAIGN_TRIGGERED = false;
  }

  public static async reStoreCampaignState() {
    let campaignState = selectCampaignState(store.getState());
    const conversations = selectAllConversations(store.getState());
    const existingActiveConversation = find(
      conversations,
      (conversation) =>
        conversation.status === "OPEN" && !conversation.isTemporary
    );
    const session = selectSession(store.getState())!;

    if (
      campaignState &&
      !campaignState.activatedCampaign?.includes(session.organizationId)
    ) {
      store.dispatch(clearActiveCampaign({}));
      campaignState = selectCampaignState(store.getState());
    }

    if (session) {
      if (
        session.chatInvite &&
        session.chatInvite.invitedTime + 60 * 60 * 1000 > Date.now()
      ) {
        this.triggerCampaignWithCampaignId("INVITE_MESSAGE", {
          muteNotifications: true,
          userId: campaignState.campaignUser,
        });
        return;
      }
    }
    if (campaignState.activatedCampaign) {
      const availableCampaigns = await this.getRegisteredCampaigns();
      if (
        availableCampaigns.find(
          (item) => item.id === campaignState.activatedCampaign
        )
      ) {
        if (
          (campaignState.activatedTimestamp || 0) + 60 * 60 * 1000 >
            Date.now() &&
          !existingActiveConversation
        ) {
          if (!this.CAMPAIGN_TRIGGERED) {
            this.triggerCampaignWithCampaignId(
              campaignState.activatedCampaign,
              {
                muteNotifications: true,
                userId: campaignState.campaignUser,
              }
            );
          }
        } else {
          // Reset Active Campaigns
          store.dispatch(
            setActiveCampaign({ campaignId: "", campaignUser: "" })
          );
        }
      } else {
        // Reset Active Campaigns
        store.dispatch(setActiveCampaign({ campaignId: "", campaignUser: "" }));
      }
    }
  }
}
