import { BrowserWakeUpHandler } from "@libs/browser-wake-up-handler";
import {
  ParentWindowService,
  processLastIdentifiedUser,
} from "@parent-window/parent-window";
import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { ReferenceSignalHandler } from "@services/reference-signal-handler";
import { HandleSocketEvents } from "@socket-engine/handle-socket-events";
import { AppRootRouter } from "app-router";
import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { CustomErrorBoundary } from "error-boundary";
import { GenerateColorTheme } from "helpers/generate-theme";
import { useEffect, useMemo, useState } from "react";

import { Provider, useSelector } from "react-redux";
import { setInit } from "store/modules/app-state/app-state.actions";
import { selectSocketConnectionState } from "store/modules/app-state/app-state.selectors";
import { CampaignsController } from "store/modules/app-state/campaigns.controller";
import { refreshOfAllConversations } from "store/modules/messages/messages.helpers";
import { loadSession, loadWidget } from "store/modules/session/session.helpers";
import {
  selectSession,
  selectWidget,
  selectWidgetColorPallet,
} from "store/modules/session/session.selectors";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllActiveUsers } from "store/modules/users/users.selectors";
import { reWatchAdditionalEntities } from "store/re-watch-additional-entitities";
import { store } from "store/store";
import { useSimpleLoaderStore } from "store/store.hooks";
import { getPageReferer } from "utils/get-page-referer";
import { isInIframe } from "utils/is-in-iframe";
import { loadStyle } from "utils/load-style";
import { useLocalStorageStore } from "utils/use-local-storage-store";
import "./App.scss";
import { parseQuery } from "./utils/parse-query";

import React from "react";
import { useTranslation } from "react-i18next";
import { loadAllConversations } from "store/modules/conversations/conversations.helpers";

function App({
  widgetId,
  accessToken,
  isSandboxMode,
  sandboxConfig,
}: {
  widgetId: string;
  accessToken?: string;
  isSandboxMode?: boolean;

  sandboxConfig?: any;
}) {
  const WIDGET_ID = widgetId;
  const ORGANIZATION_ID = widgetId.split("-")[0];

  //* Ideally this could be in the store as well
  const [sessionToken, setSessionToken] = useLocalStorageStore<string>(
    `CC_SESSION_TOKEN_${WIDGET_ID}`,
    accessToken || "",
    isSandboxMode
  );

  const [isReady, setIsReady] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    BrowserWakeUpHandler.init(async (sleepTime) => {
      if (sleepTime > 3 * 10000) {
        refreshOfAllConversations()(store, true).catch((e) => {
          console.log("Error while refreshing conversations");
        });
        reWatchAdditionalEntities();
      }
    });
  }, []);

  useEffect(() => {
    SocketConnector.firstToken = sessionToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Init Socket Controller
  useEffect(() => {
    SocketConnector.connect({
      token: sessionToken,
      organizationId: ORGANIZATION_ID,
      widgetId: WIDGET_ID,
      store,
      isSandboxMode,
      sandboxConfig,
    });

    // console.log('Connect Socket')
    const removeSocketListeners = HandleSocketEvents(
      SocketConnector.socket,
      (data) => {
        Object.assign(SocketConnector.socket!.io!.opts!.query!, {
          accessToken: data.accessToken,
          existingSessionToken: data.accessToken,
        });

        SDK.configure({ token: data.accessToken });
        setSessionToken(data.accessToken);
        SocketConnector.lastToken = data.accessToken;
        ParentWindowService.sendMessage({
          type: "INIT",
          data: { accessToken: data.accessToken },
        });
        setTimeout(() => {
          setIsReady(true);
        }, 0);

        const referer = ReferenceSignalHandler.getReferer();
        // console.log('Session Resolved', data.session?.metaData, isInIframe(), referer,document.referrer);

        if (!isInIframe()) {
          // Update Referer
          if (!data.session?.metaData?.referer) {
            if (referer) {
              SDK.updateReferer({ url: referer }).catch((e) => {
                console.log("Error while updating referer", e);
              });
            }
          }
        } else {
          // Is in iframe
          if (data.session.metaData.createdTime + 10000 > Date.now()) {
            // New session
            if (!data.session?.metaData?.referer) {
              // referer record is not there
              ParentWindowService.sendMessage({
                type: "GET_REFERER",
                data: {},
              });
            }
          }
        }

        if (!data.session.device?.timezone) {
          try {
            SDK.updateDevice({
              screenH: window.screen.height,
              screenW: window.screen.width,
              timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
              timezoneOffset: new Date().getTimezoneOffset(),
            }).catch((e) => {
              console.log("Error while updating referer", e);
            });
          } catch (e) {
            // Ignore Errors
          }
        }
      }
    );
    return removeSocketListeners;
  }, [
    ORGANIZATION_ID,
    WIDGET_ID,
    isSandboxMode,
    sandboxConfig,
    sessionToken,
    setSessionToken,
  ]);

  // Init SDK
  useEffect(() => {
    SDK.configure({ token: sessionToken });
  }, [sessionToken]);

  // Load Widget Configurations
  const {
    state: widget,
    retry: reload,
    isLoading: isWidgetLoading,
    hasError: widgetHasError,
  } = useSimpleLoaderStore(
    selectWidget,
    loadWidget(WIDGET_ID),
    [sessionToken],
    !sessionToken
  );

  // Load Users
  const {
    state: users,
    isLoading: isUsersLoading,
    hasError: userLoadingHasError,
  } = useSimpleLoaderStore(
    selectAllActiveUsers,
    loadAllUsers(WIDGET_ID),
    [sessionToken],
    !sessionToken
  );

  // Load Session
  const {
    state: session,
    isLoading: isSessionLoading,
    hasError: sessionHasError,
  } = useSimpleLoaderStore(
    selectSession,
    loadSession,
    [sessionToken],
    !sessionToken
  );

  // Init Parent Window Service and Campaigns
  useEffect(() => {
    ParentWindowService.init({ WIDGET_ID });
  }, [WIDGET_ID]);

  useEffect(() => {
    const isVerified = session?.metaData?.isVerified;
    // const sessionToken = sessionToken
    ParentWindowService.sendMessage({
      type: "SESSION_VERIFICATION_UPDATED",
      data: {
        isVerified,
        sessionToken,
        contactId: session?.contactId,
      },
    });
  }, [session?.contactId, session?.metaData?.isVerified, sessionToken]);

  useEffect(() => {
    if (session?.id && widget?.id) {
      const parsedSandboxConfig = (() => {
        try {
          return sandboxConfig ? JSON.parse(sandboxConfig) : {};
        } catch (e) {
          return {};
        }
      })();
      if (parsedSandboxConfig?.chatBotId) {
        loadAllConversations(store)
          .then(() =>
            CampaignsController.triggerChatbotAsCampaign(
              parsedSandboxConfig.chatBotId
            )
          )
          .catch((e) => {
            console.log("Error while starting sandbox chatbot", e);
          });
      } else {
        CampaignsController.init();
      }
    }
  }, [sandboxConfig, session?.id, widget?.id]);

  // When session is loaded, process user.identify again - This is needed when session has been cleared from last identify call
  useEffect(() => {
    if (session?.id && widget?.id) {
      processLastIdentifiedUser(widget?.id, session);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.id, widget?.id]);

  const colorPallet = useSelector(selectWidgetColorPallet);

  //Load Theme Styles
  useEffect(() => {
    loadStyle(
      GenerateColorTheme(colorPallet.primary, "#333"),
      "cc_globalTheme"
    );
    if (widget) {
      ParentWindowService.sendMessage({
        type: "UPDATE_WIDGET_CONFIGURATIONS",
        data: widget,
      });
    }
  }, [colorPallet, widget]);

  //Block Black Listed Clients
  useEffect(() => {
    if (widget && session && !isBlocked) {
      const blackListedIps =
        widget?.configurations?.blackListedClients?.ips || [];
      const clientIp = session.ip;
      if (blackListedIps.includes(clientIp)) {
        setIsBlocked(true);
        ParentWindowService.sendMessage({
          type: "CLIENT_BLOCKED",
          data: widget,
        });
      } else {
        const whiteListedCountries =
          widget?.configurations?.blackListedClients?.whiteListedCountries ||
          [];

        if (whiteListedCountries?.length > 0) {
          // Check Country
          if (
            !whiteListedCountries.includes(
              session?.location?.data?.country || ""
            )
          ) {
            setIsBlocked(true);
            ParentWindowService.sendMessage({
              type: "CLIENT_BLOCKED",
              data: widget,
            });
          }
        }
      }
    }
  }, [session, widget, isBlocked]);

  useEffect(() => {
    if (widget && !widget.metaData.isActive) {
      setIsBlocked(true);
      ParentWindowService.sendMessage({
        type: "WIDGET_DISABLED",
        data: widget,
      });
    }
  }, [widget]);

  // Set App Init
  useEffect(() => {
    // console.log("Set Init Time");
    store.dispatch(setInit(true));
  }, []);

  const socketConnectionStatus = useSelector(selectSocketConnectionState);

  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    const widgetLanguage = widget?.configurations.locale.language || "en";
    if (widgetLanguage !== "en") {
      i18n.changeLanguage(widgetLanguage);
    }
  }, [i18n, widget?.configurations?.locale?.language]);

  const localConfigForParents = useMemo(
    () => ({
      REPLY: t("REPLY"),
      DISMISS: t("DISMISS"),
      USER_FROM_COMPANY: t("USER_FROM_COMPANY"),
      NEW_MESSAGE_TAB_MESSAGE: t("NEW_MESSAGE_TAB_MESSAGE"),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, i18n.language]
  );

  useEffect(() => {
    ParentWindowService.sendMessage({
      type: "UPDATE_LOCALE",
      data: localConfigForParents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ParentWindowService.sendMessage({
      type: "UPDATE_LOCALE",
      data: localConfigForParents,
    });
  }, [localConfigForParents]);

  if ((!widget && isWidgetLoading) || !isReady) {
    return (
      <div className="h-full w-full flex flex-row justify-center items-center">
        <LoadingIndicator size={48} />
      </div>
    );
  }

  if (widgetHasError) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="cc" />
        <div className="text-gray-600"> {t("WIDGET_HAS_ERROR")}</div>
      </div>
    );
  }

  if (isBlocked) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="cc" />
        <div className="text-gray-600">{t("IS_BLOCKED")}</div>
      </div>
    );
  }

  //  Todo: If widget is not visible, then just show a loading indicator and then lazy load the Router

  return (
    <div className="h-full w-full">
      <>
        {!socketConnectionStatus && (
          <div className="disconnected">{t("LOADING_MESSAGE")}</div>
        )}

        <AppRootRouter WIDGET_ID={WIDGET_ID} />
      </>
    </div>
  );
}

function WrappedApp() {
  const {
    widgetId,
    accessToken,
    referer,
    isSandboxMode,
    sandboxConfig,
  } = useMemo(() => {
    const parsedUrl = parseQuery(
      // eslint-disable-next-line no-useless-escape
      window.location.href.replace(/^[^\?]+\??/, "")
    );
    const paths = window.location.pathname.split("/");
    // Todo: Check WIdget ID using isValidateWidgetId
    const widgetId: string =
      parsedUrl.widgetId ||
      (window as any).WIDGET_ID ||
      (paths[1] !== "undefined" ? paths[1] : undefined);
    const accessToken: string = parsedUrl.accessToken;
    (window as any).WIDGET_ID = widgetId;
    const referer = parsedUrl.referer || getPageReferer();
    return {
      widgetId,
      accessToken,
      referer,
      isSandboxMode: parsedUrl.isSandboxMode === "true",
      sandboxConfig: parsedUrl.sandboxConfig,
    };
  }, []);

  useEffect(() => {
    ReferenceSignalHandler.markReferer(referer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInIframe()) {
      document.body.classList.add("body-bg");
    }
  }, []);

  const { t } = useTranslation("common");

  // Todo: Need to take referer page and then add as activity

  if (!widgetId) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="" />
        <div className="text-gray-600"> {t("NO_WIDGET_ID_FOUND")}</div>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <CustomErrorBoundary>
        <App
          widgetId={widgetId}
          accessToken={accessToken}
          isSandboxMode={isSandboxMode}
          sandboxConfig={sandboxConfig}
        />
      </CustomErrorBoundary>
    </Provider>
  );
}

export default WrappedApp;
