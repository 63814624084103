import classNames from "classnames";
import React from "react";

export const HomePageWidgetContainer = ({
  icon,
  title,
  subHeading,
  extraContent,
  noHeaderBorder,
  children,
  animationClass,
}: {
  icon?: JSX.Element;
  title?: string;
  subHeading?: string;
  extraContent?: JSX.Element;
  noHeaderBorder?: boolean;
  children;
  animationClass?: string;
}) => {
  return (
    <div
      className={`home-page-widget m-4 mt-0 p-3 rounded-lg animate ${
        animationClass || "fade-in-right"
      }  bg-white`}
    >
      {title && (
        <div
          className={classNames(
            "title-section flex flex-row justify-between items-center mb-2",
            {
              "border-b border-gray-200 pb-2": !noHeaderBorder,
            }
          )}
        >
          <div className="font-semibold text-lg text-gray-800">
            <div className="bg-gray-200 rounded-lg p-2 flex justify-center items-center">
              {icon}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center px-2 flex-1">
            <div className="">
              <div className="font-semibold text-gray-800">{title}</div>
              {subHeading && (
                <div className="text-gray-600 text-sm">{subHeading}</div>
              )}
            </div>
            {extraContent}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
