import { useSDKAction } from "@sdk";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSession } from "store/modules/session/session.selectors";

export const RateArticle = ({ articleId }: { articleId: string }) => {
  const [articleAlreadyRated, setArticleRated] = useState(false);

  const [notHelpfulClicked, setNotHelpfulClicked] = useState(false);

  const { t } = useTranslation("common");

  const { doAction: _rateArticle } = useSDKAction({
    action: (sdk) => (articleId: string, req) =>
      sdk.articles.rateArticle(articleId, req),
    failureMessage: t("SOMETHING_WENT_WRONG_MESSAGE"),
  });

  const rateArticle = useCallback(
    async (articleId: string, req: { rating: number; feedback?: string }) => {
      try {
        setArticleRated(true);
        _rateArticle(articleId, req);
      } catch (error) {
        // console.error("Error fetching search results:", error);
      }
    },
    [_rateArticle]
  );

  const [feedback, setFeedback] = useState("");

  if (articleAlreadyRated) {
    return (
      <div className="mt-2 flex justify-center items-center gap-x-2">
        <h3 className="text-gray-800 dark:text-white">
          {t("KB_RATE_THANK_YOU")}
        </h3>
      </div>
    );
  }

  if (notHelpfulClicked) {
    return (
      <div className="mt-2 flex flex-col justify-center items-center gap-x-2 border border-dashed border-gray-400 p-10 rounded-lg">
        <div className="flex flex-row w-full">
          <h3 className="text-gray-800 dark:text-white mb-2">
            {t("KB_RATE_FEEDBACK")}
          </h3>
        </div>

        <textarea
          className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none mb-4"
          rows={3}
          placeholder={t("KB_RATE_FEEDBACK_PLACEHOLDER")}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          autoFocus
        ></textarea>

        <div className="flex flex-row justify-end items-center w-full">
          <button
            type="button"
            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            onClick={() => rateArticle(articleId, { rating: 1, feedback })}
          >
            {t("KB_RATE_FEEDBACK_SUBMIT")}
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="m12 5 7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-2 flex justify-center items-center gap-x-2">
      <h3 className="text-gray-800 dark:text-white text-sm">
        {t("KB_RATE_TITLE")}
      </h3>
      <button
        type="button"
        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        onClick={() => rateArticle(articleId, { rating: 5 })}
      >
        <svg
          className="flex-shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M7 10v12" />
          <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
        </svg>
        {t("YES")}
      </button>
      <button
        type="button"
        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        onClick={() => setNotHelpfulClicked(true)}
      >
        <svg
          className="flex-shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 14V2" />
          <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
        </svg>
        {t("NO")}
      </button>
    </div>
  );
};
