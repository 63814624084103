import classNames from "classnames";
import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React from "react";

export const Spinner = ({
  isActive,
  children,
  className,
  style,
  size = 30,
  hideChildrenOnProcess,
}: {
  isActive: boolean;
  children: JSX.Element;
  className?: string;
  style?: React.CSSProperties;
  size?: number | string;
  hideChildrenOnProcess?: boolean;
}) => {
  return (
    <div className={classNames("relative", className)} style={style}>
      {children}
      {isActive && (
        <div
          className="flex flex-row justify-center items-center absolute left-0 top-0 z-10 w-full h-full"
          style={{
            background: hideChildrenOnProcess ? "#ffffff" : "#ffffffaa",
          }}
        >
          <LoadingIndicator size={30} />
        </div>
      )}
    </div>
  );
};
