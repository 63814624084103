import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import classnames from "classnames";
import { EmojiIcon } from "components/common/icons/emoji";
import { MoreIcon } from "components/common/icons/more";
import { SendIcon } from "components/common/icons/send";
import { Spinner } from "components/common/spinner/spinner";
import { ContextAwareClickListener } from "helpers/context-aware-click-listener";
import { useNavigator } from "helpers/hooks/use-navigator";
import { sendMessage } from "helpers/send-message";
import { last } from "lodash";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useStore } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { resetUnreadCount } from "store/modules/messages/message.actions";
import { loadMoreMessages } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { useThrottle } from "utils/use-throttle";
import { usePreChatFormVisibility } from "../hooks/use-pre-chat-form-visibility";
import { ChatBottomDrawer } from "./chat-bottom-drawer";
// import EmojiPicker from "./emoji-picker";
import { useTranslation } from "react-i18next";
import { QuickRepliesFooter } from "./chat-input/quick-replies-footer";
import { PreChatForm } from "./pre-chat-form";
import { GPTAssistantInput } from "./gpt-assistant-input/gpt-assistant-input";
import {
  iFormNode,
  iGPTAssistantNode,
  iMessageNode,
} from "@sdk/services/chat-bot-models";
import { selectWidget } from "store/modules/session/session.selectors";
import {
  MessageAttachmentTypes,
  iMessage,
  iMessageAttachment,
} from "@sdk/services/models";
import { MessageInput } from "components/common/message-input/message-input";
import { LazyFormInput } from "./form-input/form-input-laxy";
import { CloseIcon } from "components/common/icons/close-icon";
import { GlobalEventEmitter } from "utils/global-event-emitter";

const EmojiPicker = React.lazy(() => import("./emoji-picker"));

export const ChatFooter = ({
  conversationId,
  onFileDropped,
  isFileDropActive,
}: {
  conversationId: string;
  onFileDropped: (
    files: FileList | null,
    event: React.DragEvent<HTMLDivElement>
  ) => any;
  isFileDropActive?: boolean;
}) => {
  const { t } = useTranslation("common");
  const navigator = useNavigator();
  const store = useStore();
  const [isProcessing, setProcessing] = useState(false);

  const conversation = useSelector(selectConversationById(conversationId));
  const widget = useSelector(selectWidget);

  const inputRef = useRef<any>();
  const sendButtonRef = useRef<any>();
  const [inputValue, setInputValue] = useState("");

  const onEmojiPicked = (emojiObject, event) => {
    setInputValue(inputValue + emojiObject.emoji);
  };

  const [replyContext, setReplyContext] = useState(
    null as null | {
      messageText: string;
      messageId: string;
    }
  );

  const onSendMessage = useCallback(
    async (message: string, attachments?: iMessageAttachment[]) => {
      try {
        // Todo: Got to change this when attachment is added
        if (!message) {
          // toast.error("Sending Message");
          return;
        }
        setProcessing(true);
        setInputValue("");
        // Empty Attachment
        await sendMessage(
          conversation,
          store,
          message,
          attachments || [],
          navigator,
          replyContext?.messageId ? [replyContext?.messageId] : undefined
        );
        setReplyContext(null);
        setProcessing(false);
      } catch (e) {
        setInputValue(message);
        setProcessing(false);
      }
    },
    [conversation, navigator, replyContext?.messageId, store]
  );

  // Auto Focus Input
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 800);
  }, []);

  // Prevent keyboard closing from button click
  useEffect(() => {
    if (sendButtonRef && sendButtonRef.current) {
      const EventListener = (e) => {
        e.preventDefault();
        onSendMessage(inputValue);
      };
      const currentElement = sendButtonRef?.current;
      currentElement?.addEventListener("touchend", EventListener);
      return () => {
        currentElement?.removeEventListener("touchend", EventListener);
      };
    }
  }, [inputValue, onSendMessage, sendButtonRef]);

  const handleOnPaste = useCallback(
    (event: ClipboardEvent) => {
      const items = (
        event.clipboardData || (event as any).originalEvent.clipboardData
      ).items;
      let blob = null;

      for (const item of items) {
        if (item.type.indexOf("image") === 0) {
          blob = item.getAsFile();
        }
      }

      // load image if there is a pasted image
      if (blob !== null) {
        onFileDropped(
          [
            new File([blob], `clipboard-${new Date().getTime()}.png`, {
              type: "image/png",
            }),
          ] as any,
          event as any // Not Used
        );
      }
    },
    [onFileDropped]
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener("paste", handleOnPaste, false);
      inputRef.current.setAttribute("data-enable-grammarly", "true");
      inputRef.current.setAttribute("data-gramm", "true");
      const currentInputRef = inputRef.current;
      return function cleanup() {
        if (currentInputRef) {
          currentInputRef.removeEventListener("paste", handleOnPaste, false);
        }
      };
    }
  }, [handleOnPaste, inputRef]);

  const [chatBottomDrawerVisible, setChatBottomDrawerVisibility] = useState(
    false
  );

  const isPreChatFormVisible = usePreChatFormVisibility(conversationId);

  const [isEmojiSelectorVisible, setEmojiSelectorVisibility] = useState(false);

  const sendTypingIndicator = useThrottle((inputValue) => {
    if (inputValue) {
      SocketConnector.socket.emit("SEND_TO_CHANNEL", {
        channel: conversationId,
        data: {
          event: "TYPING_INDICATOR",
          data: {
            userType: "CONTACT",
            contactId: conversation.contactId,
            value: inputValue,
            conversationId,
          },
        },
      });
    }
  }, 1500);

  const { data: messages } = useSelector(
    selectMessageByConversationId(conversationId)
  );

  const lastMessage = useMemo(
    () => last(messages.filter((item) => item?.from?.senderType !== "SYSTEM")),
    [messages]
  );

  const botContext = lastMessage?.data?.botContext;

  const [showInput, setShowInput] = useState(true);

  useEffect(() => {
    const registerEvents = (data: {
      messageText: string;
      messageId: string;
    }) => {
      setReplyContext(data);
    };
    GlobalEventEmitter.addListener("REPLY_MESSAGE", registerEvents);
    return () => {
      GlobalEventEmitter.removeListener("REPLY_MESSAGE", registerEvents);
    };
  }, [conversationId]);

  useEffect(() => {
    const registerEvents = (data: { view: string }) => {
      setChatBottomDrawerVisibility(true);
    };
    GlobalEventEmitter.addListener("ACTIVATE_CHAT_DRAWER", registerEvents);
    return () => {
      GlobalEventEmitter.removeListener("ACTIVATE_CHAT_DRAWER", registerEvents);
    };
  }, [conversationId]);

  useEffect(() => {
    if (conversation?.activeBot) {
      setShowInput(false);
      const timeout = lastMessage?.from.senderType === "CLIENT" ? 3100 : 500;
      const timer = setTimeout(() => {
        setShowInput(true);
      }, timeout);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowInput(true);
    }
  }, [conversation?.activeBot, lastMessage]);

  const onExitGPTAssistant = useCallback(
    (messages: iMessage[]) => {
      // Is Chat Bot
      // Go to Next Chat Bot // Send Message // Speak to Support or Team With Session
      // Not Chat Bot
      // Alway start of conversation
      // Send a message with All Messages In Session
      if (conversation?.isTemporary) {
        onSendMessage("Speak with support", [
          {
            type: MessageAttachmentTypes.AI_ASSIST_SESSION,
            payload: {
              messages: messages,
            },
          },
        ]);
      } else if (botContext) {
        const firstCustomerMessage = messages.find(
          (message) => message.from.senderType === "CLIENT"
        );
        onSendMessage("Speak with support", [
          {
            type: MessageAttachmentTypes.AI_ASSIST_SESSION,
            payload: {
              messages: messages,
            },
          },
        ]);
      }
    },
    [botContext, conversation?.isTemporary, onSendMessage]
  );

  if (
    (botContext as iGPTAssistantNode)?.inputType === "GPT_ASSISTANT" ||
    (!isPreChatFormVisible &&
      conversation?.isTemporary &&
      !widget?.configurations.conversationFlow.greetingsMessage.chatBotId &&
      widget?.configurations.conversationFlow.greetingsMessage
        .startWithAIAssist)
  ) {
    return (
      <GPTAssistantInput
        conversationId={conversationId}
        onExit={onExitGPTAssistant}
        assistantId={
          (botContext as iGPTAssistantNode)?.inputType === "GPT_ASSISTANT"
            ? (botContext as iGPTAssistantNode).magicAssistantId
            : widget?.configurations.conversationFlow.greetingsMessage
                .magicAssistantId
        }
      />
    );
  }

  if (!showInput) {
    return <></>;
  }

  if (botContext) {
    if ((botContext as iFormNode)?.inputType === "FORM_INPUT") {
      return (
        <LazyFormInput
          primaryConversationId={conversationId}
          type={(botContext as iFormNode).type}
          ticketId={(botContext as iFormNode).ticketId}
          formId={(botContext as iFormNode).formId}
        />
      );
    }

    if ((botContext as iMessageNode).quickButtons) {
      const validQuickReplies = (
        (botContext as iMessageNode).quickButtons || []
      ).filter((quickButton) => quickButton.label);
      if (validQuickReplies && validQuickReplies.length > 0) {
        return (
          <div
            className="flex flex-col bg-white chat-footer p-8 shadow-inner animate-fast fade-in-up"
            // style={{ height: 80 }}
          >
            <QuickRepliesFooter
              replies={(botContext as iMessageNode).quickButtons!}
              conversationId={conversationId}
            />
          </div>
        );
      }
    }
  }

  return (
    <div className="flex flex-col px-2 bg-white chat-footer animate-fast fade-in-up">
      {replyContext && (
        <div
          className="w-full bg-gray-200 rounded-lg rounded-b-none p-2 cursor-pointer hover:bg-gray-300 animate-fast fade-in-right"
          onClick={() => setReplyContext(null)}
        >
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm text-gray-600">{t("REPLYING_TO")}</div>
            <CloseIcon />
          </div>
          <div className="text-gray-600 line-clamp-1">
            {replyContext.messageText}
          </div>
        </div>
      )}

      {!isPreChatFormVisible && (
        <div className="input-line w-full flex flex-row border-t border-gray-400 border-solid p-2">
          <div className="input-container flex-1">
            <MessageInput
              inputValue={inputValue}
              setInputValue={setInputValue}
              onSendMessage={onSendMessage}
              inputRef={inputRef}
              sendTypingIndicator={sendTypingIndicator}
              onFocus={() => {
                const messageListContainer = document.getElementById(
                  "chat-messages-container"
                );
                if (messageListContainer) {
                  messageListContainer.scroll({
                    top: messageListContainer.scrollHeight,
                    // eslint-disable-next-line i18next/no-literal-string
                    behavior: "smooth",
                  });
                }
              }}
              onClick={() => {
                // console.log('On Click',!conversation.isTemporary, conversation?.metaData?.lastMessage.timestamp,conversation?.metaData?.readWatermarks.client);
                loadMoreMessages(conversationId)(store);
                if (conversation) {
                  if (
                    !conversation.isTemporary &&
                    (conversation?.metaData?.lastMessage.timestamp || 0) >
                      (conversation?.metaData?.readWatermarks.client || 0)
                  ) {
                    SDK.sendReadSignals(
                      conversationId!,
                      conversation?.isTemporary!,
                      conversation?.metaData?.lastMessage.timestamp || 0
                    );
                    store.dispatch(resetUnreadCount(conversationId));
                  }
                }
              }}
            />
          </div>
          <div
            className={classnames("action-buttons text-gray-800  flex", {
              "flex-row": inputValue.length < 84,
              "flex-col": inputValue.length >= 84,
            })}
          >
            {!conversation?.isTemporary && (
              <div
                className="cursor-pointer hover:opacity-75 p-2"
                onClick={() =>
                  setChatBottomDrawerVisibility(!chatBottomDrawerVisible)
                }
              >
                <MoreIcon />
              </div>
            )}

            {!conversation?.activeBot && (
              <div
                className="cursor-pointer hover:opacity-75 p-2"
                onClick={() => setEmojiSelectorVisibility(true)}
              >
                <EmojiIcon />
              </div>
            )}

            <div
              className="cursor-pointer hover:opacity-75 p-2"
              onClick={() => onSendMessage(inputValue)}
              ref={sendButtonRef}
            >
              <Spinner isActive={isProcessing} hideChildrenOnProcess={true}>
                <SendIcon />
              </Spinner>
            </div>
          </div>
          {isEmojiSelectorVisible && (
            <ContextAwareClickListener
              onClickedOutside={() => setEmojiSelectorVisibility(false)}
            >
              <div
                className={classnames(
                  isEmojiSelectorVisible ? "" : "hidden",
                  "fixed rounded-md border shadow-lg animate-fast fade-in-up"
                )}
                style={{ bottom: "55px", right: "40px" }}
              >
                <Suspense
                  fallback={
                    <div className="p-4 white-bg">
                      {t("LOADING_TEXT_WITH_DOTED_LINE")}
                    </div>
                  }
                >
                  <EmojiPicker onEmojiClick={onEmojiPicked} />
                </Suspense>
              </div>
            </ContextAwareClickListener>
          )}
        </div>
      )}

      <ChatBottomDrawer
        isOpen={!isFileDropActive && chatBottomDrawerVisible}
        onClose={() => setChatBottomDrawerVisibility(false)}
        onFileDropped={onFileDropped}
        conversationId={conversationId}
      />
      <PreChatForm visible={isPreChatFormVisible} />
    </div>
  );
};
