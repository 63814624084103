import React, { useEffect, useRef } from "react";

export const ContextAwareClickListener = ({
  children,
  onClickedOutside,
}: {
  children: JSX.Element;
  onClickedOutside: () => any;
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (wrapperRef?.current as any) &&
        !(wrapperRef?.current as any).contains(event.target)
      ) {
        onClickedOutside();
      }
    };
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [wrapperRef, onClickedOutside]);

  return <div ref={wrapperRef}>{children}</div>;
};
