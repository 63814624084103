import { iMatchedKnowledgeDocument } from "@sdk/services/knowledge-documents-model";
import { iMessageAttachment } from "@sdk/services/models";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { ArticleIcon } from "components/common/icons/article-icon";
import { SparklingIcon } from "components/common/icons/sparkling-icon";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const SourceIcons = {
  KB_ARTICLE: <ArticleIcon size={12} />,
  TEXT: <ArticleIcon size={18} />,
  WEB_PAGE: <ArticleIcon size={12} />,
};

export const MessageRelatedArticles = ({
  attachment,
}: {
  attachment: iMessageAttachment;
}) => {
  const navigator = useNavigator();
  const { t } = useTranslation("common");

  const onNavigateToSource = useCallback(
    (doc: iMatchedKnowledgeDocument) => {
      if (doc.data.articleId) {
        navigator.navigateTo(`/knowledge-base/articles/${doc.data.articleId}`);
      } else if (doc.data.pageUrl) {
        const win = window.open(doc.data.pageUrl, "_blank");
        win && win.focus();
      }
    },
    [navigator]
  );

  const matchedDocuments = useMemo(() => {
    return ((attachment.payload.matchedDocuments ||
      []) as iMatchedKnowledgeDocument[]).filter(
      (item) => item.type !== "TEXT"
    );
  }, [attachment.payload.matchedDocuments]);

  if (matchedDocuments.length === 0) {
    return (
      // eslint-disable-next-line i18next/no-literal-string
      // <div className="text-sm text-gray-600">
      //   - {t("MESSAGE_ATTACHMENT_INTERNAL_EXTRACTED")}
      // </div>
      <></>
    );
  }

  return (
    <div className="w-full" style={{}}>
      <div className="text-sm font-semibold flex flex-row items-center mb-2">
        <SparklingIcon size={14} />{" "}
        <div className="ml-2">{t("MESSAGE_ATTACHMENT_RELATED_ARTICLES")}</div>
      </div>
      {matchedDocuments.map((doc) => (
        <div
          key={doc.id}
          className="p-2 flex flex-row justify-between items-center cursor-pointer hover:bg-gray-200 rounded-lg overflow-hidden"
          onClick={() => onNavigateToSource(doc)}
        >
          <div className="flex flex-row items-center">
            {SourceIcons[doc.type]}
            <div className="ml-2"> {doc.title}</div>
          </div>
          <div className="">
            <ArrowRightIcon />
          </div>
        </div>
      ))}
    </div>
  );
};
