import { AnyARecord } from "dns";
import { useMemo, useState } from "react";

export const useSearch = <T>(originalData: T[]) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return originalData;
    }
    return originalData.filter(o =>
      Object.keys(o as AnyARecord).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [originalData, searchTerm]);

  return {
    filteredData,
    searchTerm,
    setSearchTerm
  };
};
