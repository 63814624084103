import { IconContainer } from "components/common/icons/icon-container";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectWidgetConfigurations } from "store/modules/session/session.selectors";
import { HomePageWidgetContainer } from "./home-page-widget-container";

export const AnnouncementsWidget = () => {
  const widgetConfigurations = useSelector(selectWidgetConfigurations);
  const { t } = useTranslation("common");

  const isEnabled =
    widgetConfigurations?.announcements?.message &&
    widgetConfigurations?.announcements?.isEnabled;

  if (!isEnabled) {
    return <></>;
  }

  return (
    <HomePageWidgetContainer
      title={t("ANNOUNCEMENT_TITLE")}
      icon={
        <IconContainer size={"1.3rem"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M9 17C9 17 16 18 19 21H20C20.5523 21 21 20.5523 21 20V13.937C21.8626 13.715 22.5 12.9319 22.5 12C22.5 11.0681 21.8626 10.285 21 10.063V4C21 3.44772 20.5523 3 20 3H19C16 6 9 7 9 7H5C3.89543 7 3 7.89543 3 9V15C3 16.1046 3.89543 17 5 17H6L7 22H9V17ZM11 8.6612C11.6833 8.5146 12.5275 8.31193 13.4393 8.04373C15.1175 7.55014 17.25 6.77262 19 5.57458V18.4254C17.25 17.2274 15.1175 16.4499 13.4393 15.9563C12.5275 15.6881 11.6833 15.4854 11 15.3388V8.6612ZM5 9H9V15H5V9Z"></path>
          </svg>
        </IconContainer>
      }
      noHeaderBorder={true}
    >
      <div className="animate fade-in-right announcement-container">
        <div
          dangerouslySetInnerHTML={{
            __html: widgetConfigurations?.announcements?.message!,
          }}
        ></div>
      </div>
    </HomePageWidgetContainer>
  );
};
