import { ENV } from "../config";
import { MainService } from "./main.service";
import { DynamicFormsServices } from "./services/dynamic-forms/dynamic-forms.service";
import { FilesService } from "./services/file-upload/file-services";
import { KnowledgeBaseService } from "./services/knowledge-base/knowledge-base-services";
import { WidgetServices } from "./services/services";

const config = (() => {
  return {
    basePath: ENV.ENGINE_BASE_PATH,
    token: "",
  };
})();

export const SDK = (new MainService(config) as any) as WidgetServices &
  FilesService &
  KnowledgeBaseService &
  DynamicFormsServices;
