import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./iframe-modal.scss";

interface ModalProps {
  url: string;
  onClose: () => void;
}

export const IframeModal: React.FC<ModalProps> = ({ url, onClose }) => {
  const modalRoot = document.getElementById("modal-root") as HTMLElement;
  const modalContent = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContent.current &&
        !modalContent.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleLoad = () => {
    setLoading(false);
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="modal-container">
        {loading && (
          <div className="flex items-center justify-center w-full h-full">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-700"></div>
          </div>
        )}
        <div
          ref={modalContent}
          className={`modal-content ${loading ? "opacity-0 absolute" : ""}`}
        >
          <button
            className="absolute p-2 rounded-full bg-transparent text-4xl text-white bg-black"
            onClick={onClose}
            style={{ top: 5, right: 5, width: "4.25rem" }}
            // eslint-disable-next-line i18next/no-literal-string
          >
            &times;
          </button>
          <iframe
            src={url}
            className={`w-full h-full`}
            frameBorder="0"
            onLoad={handleLoad}
            title="iframe-embed-frame"
          ></iframe>
        </div>
      </div>
    </div>,
    modalRoot
  );
};
