/* eslint-disable i18next/no-literal-string */
import React, { useMemo } from "react";
import { iCustomField } from "@sdk/services/custom-fields-model";
import dayjs from "dayjs";
import find from "lodash/find";

export const CustomFieldRenderer = ({
  schema,
  value,
}: {
  schema: iCustomField;
  value: any;
}) => {
  if (
    schema.inputType === "TEXT" ||
    schema.inputType === "TEXTAREA" ||
    schema.inputType === "EMAIL" ||
    schema.inputType === "NUMBER" ||
    schema.inputType === "PHONE"
  ) {
    return <div>{value || "N/A"}</div>;
  }

  if (schema.inputType === "CHECK_BOX" || schema.inputType === "SWITCH") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return <div>{value ? "Yes" : "No"}</div>;
  }

  if (schema.inputType === "DATE") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return <div>{dayjs(value).format("DD-MM-YYYY")}</div>;
  }

  if (schema.inputType === "MULTI_SELECT") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return (
      <div>
        {Array.isArray(value)
          ? (value || []).map(
              (item) =>
                find(schema.inputConfig.options || [], { value: item })?.label
            )
          : "N/A"}
      </div>
    );
  }

  if (schema.inputType === "RADIO" || schema.inputType === "SELECT") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return (
      <div>
        {find(schema.inputConfig.options || [], { value: value })?.label}
      </div>
    );
  }
  return <>Unidentified Field</>;
};
