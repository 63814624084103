import { iConversation } from "@sdk/services/models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { selectUnreadCount } from "../messages/messages.selectors";

export const {
  selectQueryMap: selectConversationQueryMap,
  selectEntityMap: selectConversationsMap,
  selectQueryMapAll: selectConversationsMapAll,
  selectQueryAllList: selectConversationsAllList,
  selectAllEntities: selectAllConversations,
  selectAllEntitiesQuery: selectAllConversationsQuery,
  selectEntityById: selectConversationById,
  selectEntityQuery: selectConversationsQuery,
} = CreateSelectorForEntities<iStore, iConversation>({
  sliceName: "conversations",
});

export const selectUnreadMessages = (conversationId: string) => (
  state: iStore
) => {
  // const shouldLog = conversationId === "b929e0-48b01-75abc-19c16-6a64c";
  const conversation = selectConversationById(conversationId)(state);
  const unReadCount = selectUnreadCount(conversationId)(state);
  const clientWatermark = conversation
    ? conversation.metaData.readWatermarks.client
    : 0;

  const hasUnread =
    conversation &&
    !conversation.metaData.lastMessage.isByClient &&
    clientWatermark < conversation.metaData.lastMessage.timestamp;

  return {
    hasUnread,
    count: unReadCount,
  };
};

export const selectAllUnreadMessages = (state: iStore) => {
  const conversations = Object.keys(state.conversations.byIds);
  return conversations.reduce(
    (acc, conversationId) => {
      const { hasUnread, count } = selectUnreadMessages(conversationId)(state);
      acc.hasUnread = acc.hasUnread || hasUnread;
      acc.count = acc.count + count;
      return acc;
    },
    {
      hasUnread: false,
      count: 0,
    }
  );
};
