import { SDK } from "@sdk";
import {
  iChatFlowConfig_GreetingsOption,
  iMessageAttachment,
  MessageAttachmentTypes,
} from "@sdk/services/models";
import { Spinner } from "components/common/spinner/spinner";
import { createNewConversation } from "helpers/create-new-conversation";
import { useNavigator } from "helpers/hooks/use-navigator";
import { sendMessage } from "helpers/send-message";
import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { resetUnreadCount } from "store/modules/messages/message.actions";
import { loadMessages } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";

interface iQuickReply {
  id: string;
  label: string;
}

export const QuickRepliesFooter = ({
  replies,
  conversationId,
}: {
  replies: iQuickReply[];
  conversationId: string;
}) => {
  const conversation = useSelector(selectConversationById(conversationId));
  const store = useStore();
  const navigator = useNavigator();

  const [selectedOption, setSelectedOption] = useState("");

  const onOptionSelected = (option: iQuickReply) => {
    setSelectedOption(option.label);
    onSendMessage(option);
  };

  const onSendMessage = async (option: iQuickReply) => {
    const message = option.label;
    await sendMessage(
      conversation,
      store,
      message,
      [
        {
          type: MessageAttachmentTypes.SELECTED_QUICK_REPLY,
          payload: {
            selectedOption: option,
          },
        },
      ],
      navigator
    );
    setSelectedOption("");
  };

  // Onload Scroll the message container
  useEffect(()=>{
    const messageListContainer = document.getElementById(
      "chat-messages-container"
    );
    if (messageListContainer) {
      messageListContainer.scroll({
        top: messageListContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  },[]);

  return (
    <div className="quick-replies-container overflow-hidden w-full">
      {replies.map((option, i) => (
        <Spinner
          className="mb-2 flex"
          isActive={option.label === selectedOption}
          key={i.toString()}
        >
          <div
            className="p-2 flex px-4 cursor-pointer quick-reply-pill font-semibold rounded-md w-full"
            onClick={() => onOptionSelected(option)}
          >
            {option.label}
          </div>
        </Spinner>
      ))}
    </div>
  );
};
