import { difference } from "lodash";
import { iEntitySlice } from "./entity-slice-creator";
import { QueryAliasResolver } from "./query-alias-resolver";
import { QueryConfig } from "./query-config";

const Query = require("query");

const isJson = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const deduceListChangesOnNewEntity = (
  entity: any & { id: string },
  state: Partial<iEntitySlice<any>>,
  resolveQueryAlias?: QueryAliasResolver
): {
  listToRemoveFrom: string[];
  listToAddTo: string[];
  matchedQueries: (QueryConfig & {
    alias: string;
  })[];
} => {
  const entityId = entity.id;

  const entityQueryMap = state.queries! || {};
  const queryConfigAliases = Object.keys(entityQueryMap);

  const existingList = queryConfigAliases.filter(
    (query) => entityQueryMap[query].list.indexOf(entityId) > -1
  );

  const allQueries: (QueryConfig & {
    alias: string;
  })[] = queryConfigAliases.map((queryConfigAlias) => {
    if (resolveQueryAlias) {
      const resolvedQuery = resolveQueryAlias(queryConfigAlias);
      if (resolvedQuery) {
        return resolvedQuery;
      }
    }

    if (!isJson(queryConfigAlias)) {
      console.error("Probably Query Alias Resolver is missing");
    }

    const parsedQueryConfig = JSON.parse(queryConfigAlias) as QueryConfig;
    return {
      ...parsedQueryConfig,
      alias: queryConfigAlias,
    };
  });

  const matchedQueries = allQueries.filter((queryConfig) => {
    const matches = Query.query([entity], queryConfig.query);
    return matches.length > 0;
  });

  // Remove from the removed List
  const listToRemoveFrom = difference(
    existingList,
    matchedQueries.map((item) => item.alias)
  );

  // Add to the new lists
  const listToAddTo = difference(
    matchedQueries.map((item) => item.alias),
    existingList
  );
  return { listToRemoveFrom, listToAddTo, matchedQueries };
};
