import { ParentWindowService } from "@parent-window/parent-window";
import { ChatIcon2 } from "components/common/icons/chat-icon2";
import { Spinner } from "components/common/spinner/spinner";

import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { selectActiveTours } from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { HomePageWidgetContainer } from "./home-page-widget-container";
import { loadActivatedTours } from "store/modules/session/session.helpers";
import { setActivatedTours } from "store/modules/session/session.actions";
import classNames from "classnames";

export const ToursWidget = () => {
  const store = useStore();
  // Todo: Probably it's going to load every single time tour is loaded. Pause it
  const { state: tours, isLoading } = useSimpleLoaderStore(
    selectActiveTours,
    loadActivatedTours
  );

  // console.log("tours", tours);

  const activateTour = useCallback(
    (tourId: string) => {
      // console.log('tourId', tourId)
      ParentWindowService.sendMessage({
        type: "ACTIVATE_TOUR",
        data: {
          tourId: tourId,
        },
      });
      store.dispatch(
        setActivatedTours(
          (tours || []).map((item) => {
            return item.id === tourId
              ? { ...item, startedTimestamp: Date.now() }
              : item;
          })
        )
      );
    },
    [store, tours]
  );

  useEffect(() => {
    const tourToActivateAutomatically = tours?.find(
      (item) => item.startedTimestamp === 0
    );

    if (tourToActivateAutomatically) {
      activateTour(tourToActivateAutomatically.screenTourId);
    }
  }, [activateTour, tours]);

  const { t, i18n } = useTranslation("common");

  if (!tours || tours?.length === 0) {
    return <></>;
  }

  return (
    <HomePageWidgetContainer
      icon={<ChatIcon2 size={"1.2rem"} />}
      title={"Active Product Tours"}
      noHeaderBorder
    >
      <Spinner isActive={isLoading}>
        <div className="tour-list-container animate fade-in-right">
          {tours.map((tour) => (
            <div
              key={tour.id}
              className={classNames(
                "flex flex-row pointer hover:bg-gray-300 p-4 cursor-pointer rounded-lg"
              )}
              onClick={() => activateTour(tour.screenTourId!)}
            >
              <div className="flex-col flex-1 pl-4 overflow-hidden">
                <div className="first-line flex flex-row items-center">
                  {/* Name */}
                  <div
                    className={classNames(
                      "name flex-1 overflow-hidden whitespace-no-wrap text-overflow-ellipses"
                    )}
                  >
                    {/* Todo: Print Tour Name along with a button to continue or start  */}
                    {tour.screenTourLabel}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Spinner>
    </HomePageWidgetContainer>
  );
};
