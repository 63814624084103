import { Button } from "components/common/button/button";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { useHistory } from "react-router";
import { selectFaqById } from "store/modules/session/session.selectors";
import "../knowledge-base-pages/editor-js-preview.scss";

export const ViewFAQPage = () => {
  const history = useHistory();
  const articleId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[2];
  }, [history.location.pathname]);

  // const article = useSelector(selectArticleById(articleId));
  const article = useSelector(selectFaqById(articleId));
  const store = useStore();
  const navigator = useNavigator();
  const { t } = useTranslation("common");

  useEffect(() => {
    const parentDocument = document.getElementById("faq-body-container");
    if (parentDocument) {
      const links = parentDocument.getElementsByTagName("a");
      for (var i = 0, len = links.length; i < len; i++) {
        links[i].target = "_blank";
      }
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader title={t("FREQUENTLY_ASKED_QUESTIONS")} />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className=" h-full w-full flex-1 bg-white relative p-8 pt-12"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            <div className="title font-bold text-xl mb-4">
              {article.question}
            </div>
            <div
              className="text-gray-800 editor-js-preview"
              id="faq-body-container"
              dangerouslySetInnerHTML={{ __html: article?.body || "" }}
            ></div>

            <div className="flex flex-col justify-center items-center  animate fade-in-up">
              <div className="mt-16 mb-2 text-sm text-gray-800 flex justify-center">
                {t("NO_ANSWER_TEXT")}
              </div>

              <div className="border border-primary rounded-lg">
                <Button
                  label={t("CHAT_WITH_US_BUTTON")}
                  onClick={async () => {
                    const { conversation } = await initLocalConversation(store);
                    navigator.navigateTo(`/conversations/${conversation.id}`);
                  }}
                  type="secondary-button"
                  className="py-1"
                />
              </div>
            </div>
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};
