interface Entity {
  type: string;
  original: string;
  value: string;
  data: any;
}

export const extractEntityTokens = (html: string) => {
  const entities: Entity[] = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  doc.querySelectorAll("span[data-input-meta]").forEach((element) => {
    const entityData: Entity = {
      type: element.getAttribute("data-input-meta-trigger") || "",
      original: element.outerHTML,
      value: element.getAttribute("data-input-meta-value") || "",
      data: {
        ...JSON.parse(element.getAttribute("data-input-meta-data") || "{}"),
      },
    };
    entities.push(entityData);
    // Remove attributes from the span element
  });

  return entities;
};
