import {
  CheckCircleOutlined,
  CreditCardOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { useSDKAction } from "@sdk";
import { Button } from "components/common/button/button";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { IconContainer } from "components/common/icons/icon-container";
import { ShoppingCart } from "components/common/icons/shopping-cart";
import { Spinner } from "components/common/spinner/spinner";

import { useNavigator } from "helpers/hooks/use-navigator";

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { selectAppInitTime } from "store/modules/app-state/app-state.selectors";

import {
  selectWidget,
  selectWidgetConfigurations,
} from "store/modules/session/session.selectors";
import { HomePageWidgetContainer } from "./home-page-widget-container";

export const ShopifyWidget = () => {
  const navigator = useNavigator();

  const appInitTime = useSelector(selectAppInitTime);

  const store = useStore();
  const widget = useSelector(selectWidget);

  const widgetConfigurations = useSelector(selectWidgetConfigurations);

  const { t } = useTranslation("common");

  const [orderId, setOrderId] = useState("");

  const submitPreChatFormOnEnter = (e) => {
    if (e.key === "Enter") {
      if (orderId) {
        onCheckOrder();
      }
    }
  };

  const {
    doAction: fetchOrderStatus,
    response,
    isProcessing,
    hasError,
  } = useSDKAction({
    action: (sdk) => (widgetId, orderId) =>
      sdk.getOrderStatus(widgetId, orderId).then((d) => {
        setTimeout(() => {
          document.getElementById("order-status-link")?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }, 500);
        return d;
      }),
    failureMessage: "Order is not available",
  });

  const [onLastCheckedOrderId, setLastCheckedOrderId] = useState("");

  const onCheckOrder = useCallback(() => {
    fetchOrderStatus(widget?.id, orderId);
    setLastCheckedOrderId(orderId);
  }, [fetchOrderStatus, orderId, widget?.id]);

  const isLoading = false;

  return (
    <HomePageWidgetContainer
      icon={<ShoppingCart size={"1.3rem"} />}
      title={t("SHOPIFY_CHECK_STATUS")}
      subHeading={t("SHOPIFY_ENTER_ORDER_ID")}
      noHeaderBorder={true}
    >
      <div className="animate fade-in-right">
        <div className="p-4 animate flex flex-row">
          <input
            placeholder={t("SHOPIFY_ORDER_ID")}
            className="border rounded-l-lg px-4 outline-none focus:shadow-lg flex-1"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            onKeyDown={submitPreChatFormOnEnter}
          />
          <Spinner isActive={isProcessing}>
            <Button
              label={
                <div className="flex flex-row">
                  <ArrowRightIcon className="" />
                </div>
              }
              onClick={onCheckOrder}
              className="rounded-l-none"
              size="SMALL"
            />
          </Spinner>
        </div>
      </div>
      {!isProcessing && hasError && (
        <div className="order-status animate fade-in-up flex flex-col justify-center items-center">
          <div className="text-sm mb-2">{t("SHOPIFY_ORDER_NOT_AVAILABLE")}</div>
        </div>
      )}
      {response?.id && !hasError && (
        <Spinner isActive={isProcessing}>
          <div className="order-status animate fade-in-up flex flex-col justify-center items-center">
            <div className="text-sm mb-2">{t("SHOPIFY_YOUR_ORDER_STATUS")}</div>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <div className="text-sm mb-2">#{onLastCheckedOrderId}</div>

            <div className="flex flex-row justify-between items-center w-full my-2">
              <div className="">{t("SHOPIFY_PAYMENT_STATUS")}</div>
              {orderFinancialStatusItems[
                (response.financial_status as string).toUpperCase()
              ] ? (
                <>
                  <div
                    className="rounded-lg p-2 flex flex-row justify-center items-center"
                    style={{
                      background:
                        orderFinancialStatusItems[
                          (response.financial_status as string).toUpperCase()
                        ].color,
                    }}
                  >
                    <div className="icon-container mr-2">
                      {
                        orderFinancialStatusItems[
                          (response.financial_status as string).toUpperCase()
                        ].icon
                      }
                    </div>
                    <div className="label-container">
                      {
                        orderFinancialStatusItems[
                          (response.financial_status as string).toUpperCase()
                        ].label
                      }
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="rounded-lg p-2 flex flex-row justify-center items-center"
                    style={{
                      background: "#f4f4f4",
                    }}
                  >
                    <div className="label-container">
                      {response.financial_status}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-row justify-between items-center w-full my-2">
              <div className="">{t("SHOPIFY_FULFILLMENT_STATUS")}</div>
              {orderFulfillmentStatusItems[
                (
                  (response.fulfillment_status as string) ||
                  t("SHOPIFY_UNFULFILLED")
                ).toUpperCase()
              ] ? (
                <>
                  <div
                    className="rounded-lg p-2 flex flex-row justify-center items-center"
                    style={{
                      background:
                        orderFulfillmentStatusItems[
                          (
                            (response.fulfillment_status as string) ||
                            t("SHOPIFY_UNFULFILLED")
                          ).toUpperCase()
                        ].color,
                    }}
                  >
                    <div className="icon-container mr-2">
                      {
                        orderFulfillmentStatusItems[
                          (
                            (response.fulfillment_status as string) ||
                            t("SHOPIFY_UNFULFILLED")
                          ).toUpperCase()
                        ].icon
                      }
                    </div>
                    <div className="label-container">
                      {
                        orderFulfillmentStatusItems[
                          (
                            (response.fulfillment_status as string) ||
                            t("SHOPIFY_UNFULFILLED")
                          ).toUpperCase()
                        ].label
                      }
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="rounded-lg p-2 flex flex-row justify-center items-center"
                    style={{
                      background: "#f4f4f4",
                    }}
                  >
                    <div className="label-container">
                      {response.fulfillment_status}
                    </div>
                  </div>
                </>
              )}
            </div>

            <a
              target="_blank"
              href={response.order_status_url}
              rel="noreferrer"
              className="mt-2 underline"
              id="order-status-link"
            >
              {t("SHOPIFY_VIEW_COMPLETE_STATUS")}
            </a>
          </div>
        </Spinner>
      )}
    </HomePageWidgetContainer>
  );
};

interface iShopifyOrderFinancialStatusItem {
  id: string;
  label: string;
  icon: JSX.Element;
  color: string;
}

const orderFinancialStatusItems: {
  [id: string]: iShopifyOrderFinancialStatusItem;
} = {
  AUTHORIZED: {
    id: "AUTHORIZED",
    label: "Authorized",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M11.0049 2L18.3032 4.28071C18.7206 4.41117 19.0049 4.79781 19.0049 5.23519V7H21.0049C21.5572 7 22.0049 7.44772 22.0049 8V16C22.0049 16.5523 21.5572 17 21.0049 17L17.7848 17.0011C17.3982 17.5108 16.9276 17.9618 16.3849 18.3318L11.0049 22L5.62486 18.3318C3.98563 17.2141 3.00488 15.3584 3.00488 13.3744V5.23519C3.00488 4.79781 3.28913 4.41117 3.70661 4.28071L11.0049 2ZM11.0049 4.094L5.00488 5.97V13.3744C5.00488 14.6193 5.58406 15.7884 6.56329 16.5428L6.75154 16.6793L11.0049 19.579L14.7869 17H10.0049C9.4526 17 9.00488 16.5523 9.00488 16V8C9.00488 7.44772 9.4526 7 10.0049 7H17.0049V5.97L11.0049 4.094ZM11.0049 12V15H20.0049V12H11.0049ZM11.0049 10H20.0049V9H11.0049V10Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  PAID: {
    id: "PAID",
    label: "Paid",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 1L20.2169 2.82598C20.6745 2.92766 21 3.33347 21 3.80217V13.7889C21 15.795 19.9974 17.6684 18.3282 18.7812L12 23L5.6718 18.7812C4.00261 17.6684 3 15.795 3 13.7889V3.80217C3 3.33347 3.32553 2.92766 3.78307 2.82598L12 1ZM12 3.04879L5 4.60434V13.7889C5 15.1263 5.6684 16.3752 6.7812 17.1171L12 20.5963L17.2188 17.1171C18.3316 16.3752 19 15.1263 19 13.7889V4.60434L12 3.04879ZM16.4524 8.22183L17.8666 9.63604L11.5026 16L7.25999 11.7574L8.67421 10.3431L11.5019 13.1709L16.4524 8.22183Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  PARTIALLY_PAID: {
    id: "PARTIALLY_PAID",
    label: "Partially Paid",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M3.00488 2.99979H21.0049C21.5572 2.99979 22.0049 3.4475 22.0049 3.99979V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979ZM20.0049 10.9998H4.00488V18.9998H20.0049V10.9998ZM20.0049 8.99979V4.99979H4.00488V8.99979H20.0049ZM14.0049 14.9998H18.0049V16.9998H14.0049V14.9998Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  PARTIALLY_REFUNDED: {
    id: "PARTIALLY_REFUNDED",
    label: "Partially Refunded",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M20.0049 8V5H4.00488V8H20.0049ZM20.0049 10H4.00488V19H20.0049V10ZM3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V4C2.00488 3.44772 2.4526 3 3.00488 3ZM11.0049 14H17.0049V16H6.50488L11.0049 11.5V14Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  PENDING: {
    id: "PENDING",
    label: "Pending",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M6 4H4V2H20V4H18V6C18 7.61543 17.1838 8.91468 16.1561 9.97667C15.4532 10.703 14.598 11.372 13.7309 12C14.598 12.628 15.4532 13.297 16.1561 14.0233C17.1838 15.0853 18 16.3846 18 18V20H20V22H4V20H6V18C6 16.3846 6.81616 15.0853 7.8439 14.0233C8.54682 13.297 9.40202 12.628 10.2691 12C9.40202 11.372 8.54682 10.703 7.8439 9.97667C6.81616 8.91468 6 7.61543 6 6V4ZM8 4V6C8 6.88457 8.43384 7.71032 9.2811 8.58583C10.008 9.33699 10.9548 10.0398 12 10.7781C13.0452 10.0398 13.992 9.33699 14.7189 8.58583C15.5662 7.71032 16 6.88457 16 6V4H8ZM12 13.2219C10.9548 13.9602 10.008 14.663 9.2811 15.4142C8.43384 16.2897 8 17.1154 8 18V20H16V18C16 17.1154 15.5662 16.2897 14.7189 15.4142C13.992 14.663 13.0452 13.9602 12 13.2219Z"></path>
        </svg>
      </IconContainer>
    ), // Money icon
    color: "#f4f4f4",
  },
  REFUNDED: {
    id: "REFUNDED",
    label: "Refunded",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M20.0049 8V5H4.00488V8H20.0049ZM20.0049 10H4.00488V19H20.0049V10ZM3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V4C2.00488 3.44772 2.4526 3 3.00488 3ZM11.0049 14H17.0049V16H6.50488L11.0049 11.5V14Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  VOIDED: {
    id: "VOIDED",
    label: "Voided",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
};

interface iShopifyOrderFulfillmentStatusItem {
  id: string;
  label: string;
  description: string;
  icon: JSX.Element;
  color: string;
}

const orderFulfillmentStatusItems: {
  [id: string]: iShopifyOrderFulfillmentStatusItem;
} = {
  FULFILLED: {
    id: "FULFILLED",
    label: "Fulfilled",
    description: "All of the items in the order have been fulfilled.",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  IN_PROGRESS: {
    id: "IN_PROGRESS",
    label: "In Progress",
    description:
      "Some of the items in the order have been fulfilled, or a request for fulfillment has been sent to the fulfillment service.",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M6 4H4V2H20V4H18V6C18 7.61543 17.1838 8.91468 16.1561 9.97667C15.4532 10.703 14.598 11.372 13.7309 12C14.598 12.628 15.4532 13.297 16.1561 14.0233C17.1838 15.0853 18 16.3846 18 18V20H20V22H4V20H6V18C6 16.3846 6.81616 15.0853 7.8439 14.0233C8.54682 13.297 9.40202 12.628 10.2691 12C9.40202 11.372 8.54682 10.703 7.8439 9.97667C6.81616 8.91468 6 7.61543 6 6V4ZM8 4V6C8 6.88457 8.43384 7.71032 9.2811 8.58583C10.008 9.33699 10.9548 10.0398 12 10.7781C13.0452 10.0398 13.992 9.33699 14.7189 8.58583C15.5662 7.71032 16 6.88457 16 6V4H8ZM12 13.2219C10.9548 13.9602 10.008 14.663 9.2811 15.4142C8.43384 16.2897 8 17.1154 8 18V20H16V18C16 17.1154 15.5662 16.2897 14.7189 15.4142C13.992 14.663 13.0452 13.9602 12 13.2219Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  ON_HOLD: {
    id: "ON_HOLD",
    label: "On Hold",
    description: "All of the unfulfilled items in this order are on hold.",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12.5002 2C12.2241 2 12.0002 2.22386 12.0002 2.5V12H10.0002V4.5C10.0002 4.22386 9.77634 4 9.5002 4C9.22405 4 9.0002 4.22386 9.0002 4.5V14C8.64653 14 7.00024 14 7.00024 14C6.61911 12.3792 5.64236 11.4407 4.5954 11.3216C4.87926 12.0664 5.36117 13.2592 6.16634 15.0995C7.02511 17.0622 7.89128 18.5218 9.00374 19.4986C10.0783 20.442 11.4586 21 13.5002 21C16.5378 21 19.0002 18.5377 19.0002 15.5002V7C19.0002 6.72386 18.7763 6.5 18.5002 6.5C18.2241 6.5 18.0002 6.72386 18.0002 7V12H16.0002V4C16.0002 3.72386 15.7763 3.5 15.5002 3.5C15.2241 3.5 15.0002 3.72386 15.0002 4V12H13.0002V2.5C13.0002 2.22386 12.7763 2 12.5002 2ZM21.0002 15.5002C21.0002 19.6424 17.6423 23 13.5002 23C11.0417 23 9.17214 22.308 7.68416 21.0015C6.23411 19.7283 5.22528 17.9381 4.33405 15.9012C3.40393 13.7753 2.89004 12.4804 2.60991 11.7235C2.25318 10.7597 2.74616 9.41212 4.08583 9.31846C5.24076 9.23771 6.22061 9.61249 7.0002 10.2587V4.5C7.0002 3.11929 8.11949 2 9.5002 2C9.68522 2 9.86554 2.0201 10.0391 2.05823C10.2477 0.888227 11.2702 0 12.5002 0C13.5602 0 14.4661 0.659694 14.8298 1.59091C15.0431 1.53167 15.268 1.5 15.5002 1.5C16.8809 1.5 18.0002 2.61929 18.0002 4V4.55001C18.1618 4.51722 18.329 4.5 18.5002 4.5C19.8809 4.5 21.0002 5.61929 21.0002 7V15.5002Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  OPEN: {
    id: "OPEN",
    label: "Open",
    description:
      'None of the items in the order have been fulfilled. Replaced by "UNFULFILLED" status.',
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M16.5 2C19.5376 2 22 4.46243 22 7.5V10C22 10.8881 21.6141 11.686 21.0009 12.2353L21 17C21 18.3059 20.1656 19.4169 19.0009 19.829L19 21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21L5.00009 19.8293C3.83485 19.4175 3 18.3063 3 17V6C3 3.79086 4.79086 2 7 2H16.5ZM9.5 11H5V17C5 17.5128 5.38604 17.9355 5.88338 17.9933L6 18H18C18.5128 18 18.9355 17.614 18.9933 17.1166L19 17V13L12.9644 13.0009C12.7214 14.6966 11.2629 16 9.5 16H6V14H9.5C10.2797 14 10.9204 13.4051 10.9931 12.6445L11 12.5C11 11.7203 10.4051 11.0796 9.64446 11.0069L9.5 11ZM16.5 4H7C5.94564 4 5.08183 4.81588 5.00549 5.85074L5 6V9H9.5C10.8962 9 12.1014 9.81751 12.6632 11H19C19.5128 11 19.9355 10.614 19.9933 10.1166L20 10V7.5C20 5.63144 18.5357 4.10487 16.692 4.00518L16.5 4Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  PARTIALLY_FULFILLED: {
    id: "PARTIALLY_FULFILLED",
    label: "Partially Fulfilled",
    description: "Some of the items in the order have been fulfilled.",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M8.00008 6V9H5.00008V6H8.00008ZM3.00008 4V11H10.0001V4H3.00008ZM13.0001 4H21.0001V6H13.0001V4ZM13.0001 11H21.0001V13H13.0001V11ZM13.0001 18H21.0001V20H13.0001V18ZM10.7072 16.2071L9.29297 14.7929L6.00008 18.0858L4.20718 16.2929L2.79297 17.7071L6.00008 20.9142L10.7072 16.2071Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  PENDING_FULFILLMENT: {
    id: "PENDING_FULFILLMENT",
    label: "Pending Fulfillment",
    description:
      'A request for fulfillment of some items awaits a response from the fulfillment service. Replaced by "IN_PROGRESS" status.',
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M6 4H4V2H20V4H18V6C18 7.61543 17.1838 8.91468 16.1561 9.97667C15.4532 10.703 14.598 11.372 13.7309 12C14.598 12.628 15.4532 13.297 16.1561 14.0233C17.1838 15.0853 18 16.3846 18 18V20H20V22H4V20H6V18C6 16.3846 6.81616 15.0853 7.8439 14.0233C8.54682 13.297 9.40202 12.628 10.2691 12C9.40202 11.372 8.54682 10.703 7.8439 9.97667C6.81616 8.91468 6 7.61543 6 6V4ZM8 4V6C8 6.88457 8.43384 7.71032 9.2811 8.58583C10.008 9.33699 10.9548 10.0398 12 10.7781C13.0452 10.0398 13.992 9.33699 14.7189 8.58583C15.5662 7.71032 16 6.88457 16 6V4H8ZM12 13.2219C10.9548 13.9602 10.008 14.663 9.2811 15.4142C8.43384 16.2897 8 17.1154 8 18V20H16V18C16 17.1154 15.5662 16.2897 14.7189 15.4142C13.992 14.663 13.0452 13.9602 12 13.2219Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  RESTOCKED: {
    id: "RESTOCKED",
    label: "Restocked",
    description:
      'All of the items in the order have been restocked. Replaced by "UNFULFILLED" status.',
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M21 11.6458V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11.6458C2.37764 10.9407 2 10.0144 2 9V3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V9C22 10.0144 21.6224 10.9407 21 11.6458ZM19 12.874C18.6804 12.9562 18.3453 13 18 13C16.8053 13 15.7329 12.4762 15 11.6458C14.2671 12.4762 13.1947 13 12 13C10.8053 13 9.73294 12.4762 9 11.6458C8.26706 12.4762 7.19469 13 6 13C5.6547 13 5.31962 12.9562 5 12.874V20H19V12.874ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9V4H4V9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  SCHEDULED: {
    id: "SCHEDULED",
    label: "Scheduled",
    description:
      "All of the unfulfilled items in this order are scheduled for fulfillment at later time.",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM11 13V17H6V13H11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
  UNFULFILLED: {
    id: "UNFULFILLED",
    label: "Unfulfilled",
    description: "None of the items in the order have been fulfilled.",
    icon: (
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
        </svg>
      </IconContainer>
    ),
    color: "#f4f4f4",
  },
};
