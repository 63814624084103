import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./message-input.scss";
import { ContentEditableInput } from "./content-editable-input";
import TextareaAutosize from "react-textarea-autosize";
import { useViewRefresher } from "utils/hooks/use-view-refresher";

function convertToPlain(html) {
  // Create a new div element
  const tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  console.log("tempDivElement", tempDivElement);
  // Retrieve the text property of the element
  return tempDivElement.innerText || "";
}

function replaceCaret(el: HTMLElement) {
  // Place the caret at the end of the element
  const target = document.createTextNode("");
  el.appendChild(target);
  // do not move caret if element was not focused
  const isTargetFocused = document.activeElement === el;
  if (target !== null && target.nodeValue !== null && isTargetFocused) {
    var sel = window.getSelection();
    if (sel !== null) {
      var range = document.createRange();
      range.setStart(target, target.nodeValue.length);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    if (el instanceof HTMLElement) el.focus();
  }
}

export const MessageInput = (props: {
  inputValue: string;
  setInputValue: (val: string) => any;
  onSendMessage: (val: string) => any;
  inputRef: any;
  sendTypingIndicator?: (val: string) => any;
  onFocus?: () => any;
  onClick?: () => any;
}) => {
  return <MessageInputWithTextArea {...props} />;
};

export const _MessageInput = ({
  inputValue,
  setInputValue,
  onSendMessage,
  sendTypingIndicator,
  inputRef,
  onFocus,
  onClick,
}: {
  inputValue: string;
  setInputValue: (val: string) => any;
  onSendMessage: (val: string) => any;
  inputRef: any;
  sendTypingIndicator?: (val: string) => any;
  onFocus?: () => any;
  onClick?: () => any;
}) => {
  const { t } = useTranslation("common");

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        const inputValue = (inputRef.current.innerText || "").trim();
        e.preventDefault();
        onSendMessage(inputValue);
        sendTypingIndicator && sendTypingIndicator("");
      }
    },
    [inputRef, onSendMessage, sendTypingIndicator]
  );

  const onChange = useCallback(
    (e) => {
      const inputValue = (inputRef.current.innerText || "").trim();
      console.log("inputValue", inputValue, e.target.value);
      setInputValue(e.target.value);
      sendTypingIndicator && sendTypingIndicator(inputValue);
    },
    [inputRef, sendTypingIndicator, setInputValue]
  );

  useEffect(() => {
    if (inputRef.current) {
      const currentInputRef = inputRef.current;
      const pasteListener = function (e) {
        e.preventDefault();
        // const html = e.clipboardData.getData("text/html");
        // console.log("html", html);
        const text = e.clipboardData.getData("text/plain");
        // console.log("text", text);
        // document.execCommand('insertText', false, text)
        setTimeout(() => {
          currentInputRef.innerText = text;
          replaceCaret(e.target);
        }, 0);
      };
      currentInputRef.addEventListener("paste", pasteListener);
      return () => {
        currentInputRef.removeEventListener("paste", pasteListener);
      };
    }
  });

  useEffect(() => {
    console.log("inputValue changed", inputValue);
    if (inputRef.current) {
      if (inputRef.current.innerText !== inputValue) {
        inputRef.current.innerText = inputValue;
      }
    }
  }, [inputRef, inputValue]);

  return (
    <ContentEditableInput
      value={inputValue}
      onChange={onChange}
      innerRef={inputRef}
      onKeyDown={onKeyDown}
      className="message-input-div w-full p-2 outline-none"
      placeholder={t("SEND_MESSAGE_PLACEHOLDER")}
      onFocus={onFocus}
      onClick={onClick}
    />
  );
};

const MessageInputWithTextInput = ({
  inputValue,
  setInputValue,
  onSendMessage,
  inputRef,
}: {
  inputValue: string;
  setInputValue: (val: string) => any;
  onSendMessage: (val: string) => any;
  inputRef: any;
}) => {
  const { t } = useTranslation("common");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSendMessage(inputValue);
      }}
      autoComplete="off"
    >
      {/* 
              Disabling Auto Fill
              https://adamsilver.io/blog/stopping-chrome-from-ignoring-autocomplete-off/ 
              https://gist.github.com/niksumeiko/360164708c3b326bd1c8
               */}
      <input
        placeholder={t("SEND_MESSAGE_PLACEHOLDER")}
        className="w-full p-2 outline-none"
        name="ccMessageVal"
        id="ccMessageVal"
        autoComplete="off"
        role="presentation"
        value={inputValue}
        onFocus={() => {
          const messageListContainer = document.getElementById(
            "chat-messages-container"
          );
          if (messageListContainer) {
            messageListContainer.scroll({
              top: messageListContainer.scrollHeight,
              behavior: "smooth",
            });
          }
        }}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        ref={inputRef}
        onKeyDown={(e) => {
          // * Covered by enter key
          // if (e.key === "Enter") {
          //   onSendMessage(inputValue);
          // }
        }}
        onClick={() => {
          // Todo
        }}
      />
      <button type="submit" style={{ display: "none" }}>
        {t("SEND_BUTTON")}
      </button>
    </form>
  );
};

const MessageInputWithTextArea = ({
  inputValue,
  setInputValue,
  onSendMessage,
  inputRef,
  onFocus,
  sendTypingIndicator,
  onClick,
}: {
  inputValue: string;
  setInputValue: (val: string) => any;
  onSendMessage: (val: string) => any;
  inputRef: any;
  sendTypingIndicator?: (val: string) => any;
  onFocus?: () => any;
  onClick?: () => any;
}) => {
  const { t } = useTranslation("common");

  const { visible } = useViewRefresher();
  if (!visible) {
    return <></>;
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSendMessage(inputValue);
      }}
      autoComplete="off"
    >
      {/* 
              Disabling Auto Fill
              https://adamsilver.io/blog/stopping-chrome-from-ignoring-autocomplete-off/ 
              https://gist.github.com/niksumeiko/360164708c3b326bd1c8
               */}
      <TextareaAutosize
        placeholder={t("SEND_MESSAGE_PLACEHOLDER")}
        className="w-full p-2 outline-none resize-none"
        // eslint-disable-next-line i18next/no-literal-string
        name="ccMessageVal"
        id="ccMessageVal"
        // eslint-disable-next-line i18next/no-literal-string
        autoComplete="off"
        // eslint-disable-next-line i18next/no-literal-string
        role="presentation"
        value={inputValue}
        onFocus={onFocus}
        onChange={(e) => {
          setInputValue(e.target.value);
          sendTypingIndicator && sendTypingIndicator(inputValue);
        }}
        ref={inputRef}
        maxRows={20}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSendMessage(inputValue);
          }
        }}
        onClick={onClick}
      />
      <button type="submit" style={{ display: "none" }}>
        {t("SEND_BUTTON")}
      </button>
    </form>
  );
};
