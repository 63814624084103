import classNames from "classnames";
import React from "react";

export const SoundOffIcon = ({
  size = '1.7rem',
  className,
}: {
  size?: number | string;
  className?: string;
}) => {
  return (
    <div
      className={classNames("icon-container", className)}
      style={{ width: size, height: size }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        className="icon-svg"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M10 7.22L6.603 10H3v4h3.603L10 16.78V7.22zM5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm14.525-4l3.536 3.536-1.414 1.414L19 13.414l-3.536 3.536-1.414-1.414L17.586 12 14.05 8.464l1.414-1.414L19 10.586l3.536-3.536 1.414 1.414L20.414 12z" />
      </svg>
    </div>
  );
};
