import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense } from "react";

const APIVideoPlayer = React.lazy(() => import("./api-video-player"));

export const LazyAPIVideoPlayer = (props: { videoId: string }) => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <APIVideoPlayer {...props} />
    </Suspense>
  );
};
