import { AppStateSliceActions } from "./app-state.slice";

export const {
  setMuteState,
  setWidgetMaximizedState,
  setSocketConnectionState,
  addCampaignEvent,
  setActiveCampaign,
  setInit,
  clearActiveCampaign,
  setLastVisitedTab,
  setPreferredSubjectLine
} = AppStateSliceActions;
