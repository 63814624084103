import { Button } from "components/common/button/button";
import { useNavigator } from "helpers/hooks/use-navigator";
import { filter } from "lodash";
import find from "lodash/find";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadTicketTypes } from "store/modules/session/session.helpers";
import { selectAllTicketTypes } from "store/modules/session/session.selectors";
import { loadAllTickets } from "store/modules/tickets/tickets.helpers";
import { selectAllTickets } from "store/modules/tickets/tickets.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { Collapse } from "react-collapse";
import { ArrowLeftIcon } from "components/common/icons/arrow-left";
import { iTicket } from "@sdk/services/tickets-models";
import dayjs from "dayjs";
import { ArrowDownIcon } from "components/common/icons/arrow-down";
import { ArrowUpIcon } from "components/common/icons/arrow-up";
import classNames from "classnames";

export const LinkedTicketIndicator = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const navigator = useNavigator();

  const { t } = useTranslation("common");

  const { state: tickets, isLoading } = useSimpleLoaderStore(
    selectAllTickets,
    loadAllTickets
  );

  const {
    state: ticketTypes,
    isLoading: isLoadingTicketTypes,
  } = useSimpleLoaderStore(
    selectAllTicketTypes,
    loadTicketTypes,
    [],
    tickets.length === 0
  );

  const matchedTicket = useMemo(() => {
    if (tickets.length === 0 || ticketTypes.length === 0) {
      return undefined;
    }
    return filter(
      tickets,
      (ticket) => ticket.primaryConversationId === conversationId
    ).map((ticket) => ({
      ...ticket,
      emoji:
        (ticketTypes || []).find((type) => type.id === ticket.ticketTypeId)
          ?.emoji || "📥",
    }));
  }, [conversationId, ticketTypes, tickets]);

  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!matchedTicket || matchedTicket.length === 0) {
    return <></>;
  }

  if (matchedTicket.length > 1) {
    return (
      <div
        className={classNames("tickets-found-section primary-bg", {
          "primary-bg": !isCollapsed,
        })}
      >
        <div
          className={classNames(
            "p-3 flex items-center justify-center font-bold cursor-pointer",
            {
              "primary-button": !isCollapsed,
              "bg-white": isCollapsed,
            }
          )}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {t("LINKED_TICKETS")}{" "}
          {!isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </div>

        <Collapse isOpened={isCollapsed}>
          <div className="flex flex-col bg-white rounded-b-lg">
            {matchedTicket.map((ticket) => (
              <TicketListItem
                ticket={ticket}
                key={ticket.id}
                emoji={ticket.emoji}
              />
            ))}
          </div>
        </Collapse>
        {isCollapsed && <div className="mb-2 primary-bg w-full"></div>}
      </div>
    );
  }

  // Single Ticket
  return (
    <div className="tickets-found-section primary-bg">
      <Button
        label={
          <div className="font-bold">
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {t("VIEW_TICKET")} - {matchedTicket[0].label}
          </div>
        }
        onClick={async () => {
          navigator.navigateTo(`/tickets/${matchedTicket[0].id}`);
        }}
      />
    </div>
  );
};

const TicketListItem = ({
  ticket,
  emoji,
}: {
  ticket: iTicket;
  emoji: string;
}) => {
  const navigator = useNavigator();

  const { t } = useTranslation();
  return (
    <div
      className="hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer rounded-lg border-1 border-gray-200 flex flex-col p-2"
      onClick={() => {
        navigator.navigateTo(`/tickets/${ticket.id}`);
      }}
    >
      <div className="top-row flex flex-row justify-between items-center">
        <div className="">
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {emoji || "📥"} {ticket.label}
        </div>
        <i className="ri-arrow-right-line"></i>
      </div>
      <div className="second-row text-gray-600">
        {t("CREATED_AT")} {dayjs(ticket.createdAt).format("DD/MM/YYYY")}
      </div>
    </div>
  );
};
