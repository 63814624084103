import classNames from "classnames";
import React from "react";

export const Button = ({
  label,
  onClick,
  type = "primary-button",
  className,
  style,
  size,
}: {
  label: string | JSX.Element;
  onClick?: () => any;
  type?: "primary-button" | "secondary-button";
  className?: string;
  style?: React.CSSProperties;
  size?: "SMALL";
}) => {
  return (
    <div
      className={classNames(
        type,
        "flex items-center justify-center font-semibold rounded-lg cursor-pointer",
        className,
        {
          "p-3": !size,
          "p-2": size === "SMALL",
        }
      )}
      onClick={onClick}
      style={style}
    >
      {label}
    </div>
  );
};
