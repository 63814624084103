import React, { Suspense } from "react";

const VideoPlayer = React.lazy(() => import("./video-player"));

export const VideoPlayerLazy = ({ url }) => {
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <Suspense fallback={<>...</>}>
      <VideoPlayer url={url!} />
    </Suspense>
  );
};
