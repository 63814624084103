import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IframeModal } from "./iframe-modal";

const IframeUrlLoaderActionButton = ({
  url,
  messageId,
  conversationId,
  buttonLabel,
  isLastMessage,
}: {
  url: string;
  conversationId: string;
  messageId: string;
  buttonLabel: string;
  isLastMessage?: boolean;
}) => {
  const { t } = useTranslation("common");
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="mb-4 mt-2">
      <button
        className="p-2 flex px-4 cursor-pointer quick-reply-pill font-semibold rounded-md justify-center items-center"
        onClick={(e) => {
          openModal();
        }}
      >
        {buttonLabel}
      </button>
      {showModal && (
        <IframeModal
          url={url} // Replace with your specified URL
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default IframeUrlLoaderActionButton;
