import { SocketConnector } from "@sdk/@libs/socket-connector";
import { selectConversationsAllList } from "./modules/conversations/conversations.selectors";
import { selectContact } from "./modules/session/session.selectors";
import { store } from "./store";

export const reWatchAdditionalEntities = () => {
  const contact = selectContact(store.getState());
  if (contact && contact.id) {
    // * Quick Patch. Ideally Contact Id should be listed from socket server automatically
    SocketConnector.socket.emit("WATCH_ENTITIES", [contact.id]);
  }

  const conversationList = selectConversationsAllList(store.getState());
  if (conversationList) {
    SocketConnector.socket.emit(
      "WATCH_ENTITIES",
      (conversationList || []).filter((e) => !e.includes("TEMP"))
    );
  }
};
