import { useSDKAction } from "@sdk";
import { SendIcon } from "components/common/icons/send";
import { Spinner } from "components/common/spinner/spinner";
import { useNavigator } from "helpers/hooks/use-navigator";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";

import { MessageAttachmentTypes, iMessage } from "@sdk/services/models";
import { MagicWandIcon } from "components/common/icons/magic-wand-icon";
import RotatingText from "components/common/rotating-text";
import { useTranslation } from "react-i18next";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { selectWidget } from "store/modules/session/session.selectors";
import { GPTAssistantMessagesListBox } from "./gpt-assistant-message-list";
import {
  GenerateCustomerMessage,
  GenerateGPTAssistantMessage,
} from "./helpers/generate-assistant-messages";

import { MessageInput } from "components/common/message-input/message-input";
import React from "react";
import {
  addNewMessage,
  setMessagesRes,
} from "store/modules/messages/message.actions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";

export const GPTAssistantInput = ({
  conversationId,
  onExit,
  assistantId,
}: {
  conversationId: string;
  onExit: (messages: iMessage[]) => any;
  assistantId?: string;
}) => {
  const { t } = useTranslation("common");
  const navigator = useNavigator();
  const store = useStore();
  const dispatch = useDispatch();
  const conversation = useSelector(selectConversationById(conversationId));
  const widget = useSelector(selectWidget);

  const inputRef = useRef<any>();
  const sendButtonRef = useRef<any>();
  const [inputValue, setInputValue] = useState("");

  // const [messages, setMessages, getMessages] = useStateWithGetter(
  //   [] as iMessage[]
  // );

  const magicAssistantMessageState = useSelector(
    selectMessageByConversationId("MAGIC_ASSISTANT")
  );
  const messages = magicAssistantMessageState.data;

  const addMessage = useCallback(
    (message: iMessage) => {
      const currentMagicAssistantState = selectMessageByConversationId(
        "MAGIC_ASSISTANT"
      )(store.getState());

      const isStateLoaded = currentMagicAssistantState.isFullyFetched;

      if (isStateLoaded) {
        dispatch(addNewMessage(message));
      } else {
        dispatch(
          setMessagesRes({
            conversationId: "MAGIC_ASSISTANT",
            messagesResults: {
              docs: [message],
              totalDocs: 1,
              limit: 100,
              offset: 0,
            } as any,
            reset: true,
          })
        );
      }
    },
    [dispatch, store]
  );

  const clearMessages = useCallback(() => {
    dispatch(
      setMessagesRes({
        conversationId: "MAGIC_ASSISTANT",
        messagesResults: {
          docs: [],
          totalDocs: 1,
          limit: 100,
          offset: 0,
        } as any,
        reset: true,
      })
    );
  }, [dispatch]);

  const getMessages = useCallback(() => {
    const currentMagicAssistantState = selectMessageByConversationId(
      "MAGIC_ASSISTANT"
    )(store.getState());
    return currentMagicAssistantState.data;
  }, [store]);

  const { doAction: queryKnowledge, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (sdk) => (query: string) =>
        sdk.queryKnowledge({
          query,
          assistantId,
          conversationId: conversationId.includes("Widget-TEMP")
            ? undefined
            : conversationId,
          noSkipNoMatchedContext:
            messages.filter((item) => item.from.senderType === "CLIENT")
              .length > 0,
          contextKeywords: `${messages
            .filter((item) => item.from.senderType === "CLIENT")
            .map((item) => item.message)
            .join("\n-----------\n")}\n-----------\n Query: ${query}`,
          chatHistory: messages
            .map(
              (item) =>
                `${item.from.senderType === "USER" ? "BOT:" : "CUSTOMER"} ${
                  item.message
                }`
            )
            .join("\n"),
        }),
      // successMessage: "Chat bot has been deactivated",
      failureMessage: t("SOMETHING_WENT_WRONG_MESSAGE"),
      throwErrors: true,
    }),
    [assistantId, conversationId, messages, t]
  );

  const { doAction: _logMagicAssistantEvent } = useSDKAction({
    action: (sdk) => (
      conversationId: string,
      text: string,
      by: "CLIENT" | "ASSISTANT"
    ) => sdk.logMagicAssistantEvent(conversationId, { text, by }),
    // successMessage: "Chat bot has been deactivated",
    failureMessage: t("SOMETHING_WENT_WRONG_MESSAGE"),
  });

  const logMagicAssistantEvent = useCallback(
    (text: string, by: "CLIENT" | "ASSISTANT") => {
      if (!conversation?.isTemporary) {
        _logMagicAssistantEvent(conversation.id, text, by).catch((e) => {
          // Ignore
        });
      }
    },
    [_logMagicAssistantEvent, conversation?.id, conversation?.isTemporary]
  );

  const exitMagicAssistant = useCallback(() => {
    onExit(getMessages());
    clearMessages();
  }, [clearMessages, getMessages, onExit]);

  const onSendMessage = useCallback(
    async (message: string) => {
      try {
        if (!message) {
          return;
        }
        setInputValue("");
        addMessage(
          GenerateCustomerMessage({
            conversationId: "MAGIC_ASSISTANT",
            message: message,
          })
        );
        logMagicAssistantEvent(message, "CLIENT");
        const res = await queryKnowledge(message);
        if (!res) {
          throw { message: "Something went wrong" };
        }

        switch (res.typeOfQuery) {
          case "FRUSTRATED": {
            addMessage(
              GenerateGPTAssistantMessage({
                conversationId: "MAGIC_ASSISTANT",
                message: `I'm sorry that I am not able to assist you. Let me direct you to a team member`,
                attachments: [],
                messageData: {
                  magicAssistantQueryId: res.queryId,
                },
              })
            );

            exitMagicAssistant();
            break;
          }
          case "GO_BACK": {
            addMessage(
              GenerateGPTAssistantMessage({
                conversationId: "MAGIC_ASSISTANT",
                message: `Let's start again.`,
                attachments: [],
                messageData: {
                  magicAssistantQueryId: res.queryId,
                },
              })
            );
            exitMagicAssistant();
            break;
          }
          case "GREETINGS": {
            addMessage(
              GenerateGPTAssistantMessage({
                conversationId: "MAGIC_ASSISTANT",
                message: `Hello there!`,
                attachments: [],
                messageData: {
                  magicAssistantQueryId: res.queryId,
                },
              })
            );
            break;
          }
          case "SPEAK_TO_HUMAN": {
            addMessage(
              GenerateGPTAssistantMessage({
                conversationId: "MAGIC_ASSISTANT",
                message: `Let me direct you to a team member`,
                attachments: [],
                messageData: {
                  magicAssistantQueryId: res.queryId,
                },
              })
            );
            exitMagicAssistant();
            break;
          }
          case "THANK_YOU": {
            addMessage(
              GenerateGPTAssistantMessage({
                conversationId: "MAGIC_ASSISTANT",
                message: `You're welcome! It was a pleasure being able to help you!`,
                attachments: [],
                messageData: {
                  magicAssistantQueryId: res.queryId,
                },
              })
            );
            break;
          }
          case "QUESTION":
          default: {
            const matchedDocuments = res.matchedDocuments.filter(
              (item) => item.type !== "TEXT"
            );
            addMessage(
              GenerateGPTAssistantMessage({
                conversationId: "MAGIC_ASSISTANT",
                message: res.response,
                attachments:
                  matchedDocuments.length > 0
                    ? [
                        {
                          type: MessageAttachmentTypes.RELATED_ARTICLES,
                          payload: {
                            matchedDocuments: matchedDocuments,
                          },
                        },
                      ]
                    : [],
                messageData: {
                  magicAssistantQueryId: res.queryId,
                },
              })
            );
            logMagicAssistantEvent(res.response, "ASSISTANT");
            // if (
            //   res.response.toLowerCase().includes("sorry") &&
            //   res.response.toLowerCase().includes("i don't")
            // ) {
            //   exitMagicAssistant();
            // }
            break;
          }
        }

        setInputValue("");
        // Empty Attachment
      } catch (e) {
        setInputValue(message);
      }
    },
    [addMessage, exitMagicAssistant, logMagicAssistantEvent, queryKnowledge]
  );

  // Auto Focus Input
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 800);
  }, []);

  // Prevent keyboard closing from button click
  useEffect(() => {
    if (sendButtonRef && sendButtonRef.current) {
      const EventListener = (e) => {
        e.preventDefault();
        onSendMessage(inputValue);
      };
      const currentElement = sendButtonRef?.current;
      currentElement?.addEventListener("touchend", EventListener);
      return () => {
        currentElement?.removeEventListener("touchend", EventListener);
      };
    }
  }, [inputValue, onSendMessage, sendButtonRef]);

  const isBeginningOfMessage = messages.length === 0;

  const notHelpFulText =
    widget?.configurations?.locale?.custom?.["NOT_HELPFUL"] || "Not Helpful?";

  const onRedirectToTeamMember = useCallback(() => {
    exitMagicAssistant();
  }, [exitMagicAssistant]);

  return (
    <div
      className="flex flex-col m-2 border-gray-400 border rounded-lg overflow-hidden bg-white chat-footer animate-fast fade-in-up"
      style={{
        boxShadow: "0 -16px 50px 10px #0000000f",
        padding: 10,
        maxHeight: "calc(100% - 78px)",
      }}
    >
      <div className="assistant-header flex flex-row justify-between items-center gap-4 p-2">
        <div className="flex flex-row gap-4 font-bold">
          <MagicWandIcon /> {t("MAGIC_ASSISTANT")}
        </div>
        <div
          className="cursor-pointer hover:underline"
          onClick={() => exitMagicAssistant()}
        >
          {notHelpFulText}
        </div>
      </div>
      <div className="assistant-body flex-1 overflow-hidden">
        {isBeginningOfMessage && (
          <div className="flex flex-col justify-items-center items-center py-4">
            <div className="text-xl text-center text-gray-700">
              {t("MAGIC_ASSISTANT_SUBTITLE")}
            </div>
            <div className="text-gray-600 text-center">
              {t("MAGIC_ASSISTANT_HINT")}
            </div>
            {(
              widget?.configurations?.conversationFlow?.greetingsMessage
                ?.aiAssistantExamples || []
            ).length > 0 && (
              <div className="text-gray-600 text-center">
                {t("MAGIC_ASSISTANT_EXAMPLE")}
                <RotatingText
                  items={
                    widget?.configurations?.conversationFlow?.greetingsMessage
                      ?.aiAssistantExamples
                  }
                />
              </div>
            )}
          </div>
        )}
        {!isBeginningOfMessage && (
          <div className="flex flex-col justify-items-center items-center py-4 h-full">
            <GPTAssistantMessagesListBox
              conversationId={conversationId}
              messages={messages}
              isProcessing={isProcessing}
              onRedirectToTeamMember={onRedirectToTeamMember}
            />
          </div>
        )}
      </div>

      <div className="assistant-footer border-t border-gray-400 input-line w-full flex flex-row b p-2 pb-0">
        <div className="input-container flex-1">
          <MessageInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            onSendMessage={onSendMessage}
            inputRef={inputRef}
            onFocus={() => {
              const messageListContainer = document.getElementById(
                "chat-messages-container"
              );
              if (messageListContainer) {
                messageListContainer.scroll({
                  top: messageListContainer.scrollHeight,
                  // eslint-disable-next-line i18next/no-literal-string
                  behavior: "smooth",
                });
              }
            }}
            onClick={() => {
              // Scroll
              const messageListContainer = document.getElementById(
                "chat-messages-container"
              );
              if (messageListContainer) {
                messageListContainer.scroll({
                  top: messageListContainer.scrollHeight,
                  // eslint-disable-next-line i18next/no-literal-string
                  behavior: "smooth",
                });
              }
            }}
          />
        </div>
        <div className="action-buttons text-gray-800 flex-row flex">
          <div
            className="cursor-pointer hover:opacity-75 p-2"
            onClick={() => onSendMessage(inputValue)}
            ref={sendButtonRef}
          >
            <Spinner isActive={isProcessing} hideChildrenOnProcess={true}>
              <SendIcon />
            </Spinner>
          </div>
        </div>
      </div>
    </div>
  );
};
