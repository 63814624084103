import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Store } from "redux";
import { useStateIfMounted } from "use-state-if-mounted";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { iStore } from "./store.model";

export const useSimpleLoaderStore = <TSelected>(
  selector: (state: iStore) => TSelected,
  loader: (store: Store, forceReload?: boolean) => any,
  dependencies?: any[],
  disable?: boolean
) => {
  const store = useStore();
  const [retries, setRetries] = useStateIfMounted(0);
  const onRetry = () => setRetries(retries + 1);
  const value = useSelector(selector);

  const [hasError, setHasError] = useStateIfMounted(false);
  const [isLoading, setIsLoading] = useStateIfMounted(false);

  useEffect(() => {
    if (!disable && (!value || (value as any).length === 0)) {
      setIsLoading(true);
      loader(store, true)
        .then((d) => {
          setHasError(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setHasError(true);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries, store, ...(dependencies || [])]);
  return {
    state: value,
    isLoading,
    hasError,
    retry: onRetry,
  };
};

export const useQueryWithStore = <TSelected>(
  selector: (state: iStore) => TSelected,
  loader: (store: Store, forceReload?: boolean) => any,
  dependencies?: any[],
  disable?: boolean
) => {
  const store = useStore();
  const [retries, setRetries] = useStateIfMounted(0);
  const onRetry = () => setRetries(retries + 1);
  const value = useSelector(selector);
  useEffect(() => {
    if (!disable) {
      loader(store, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries, store, ...(dependencies || [])]);
  return {
    state: value,
    // isLoading,
    // hasError,
    retry: onRetry,
  };
};

export function useEntitySelector<Entity>(
  selector: (state: iStore) => any,
  entityId: string,
  loader: (store: Store, forceReload?: boolean) => any
) {
  const store = useStore();
  const [retries, setRetries] = useStateIfMounted(0);
  const onRetry = () => setRetries(retries + 1);
  const value = useSelector(
    (state) => ((selector(state as any) as any).byIds as any)[entityId]
  );

  const isLoading = useSelector(
    (state) =>
      (
        ((selector(state as any) as any).itemsBeingFetched as any) || []
      ).indexOf(entityId) > -1
  );

  const hasError = useSelector(
    (state) =>
      (((selector(state as any) as any).failedItems as any) || []).indexOf(
        entityId
      ) > -1
  );
  useEffect(() => {
    loader(store, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries, store]);
  return { value: value as Entity, isLoading, hasError };
}

export const usePaginatedQueryWithStore = <TSelected>(
  entityMapSelector: (state: iStore) => Record<string, TSelected>,
  entitiesSetter: any,
  query: any,
  queryCall: (req: {
    query: any;
    options: PaginatedOptions;
  }) => Promise<PaginatedResults<TSelected>>,
  dependencies?: any[]
) => {
  const [paginationState, setPaginationState] = useSimpleState({
    lastQuery: "",
    // Query Config
    pageSize: 5,
    currentPage: 0,
    // Results
    totalItems: 0,
    // State
    isLoading: false,
    hasError: false,
    results: [] as string[],
  });

  const entityMap = useSelector(entityMapSelector);
  const dispatch = useDispatch();
  const [didLoad, setDidLoad] = useState(false);

  useEffect(
    () => {
      setPaginationState({
        isLoading: true,
      });
      queryCall({
        query,
        options: {
          page:
            JSON.stringify(query) === paginationState.lastQuery
              ? paginationState.currentPage
              : 1,
          limit: paginationState.pageSize,
        },
      })
        .then((results) => {
          if (results.docs) {
            dispatch(entitiesSetter(results.docs));
          }

          setPaginationState({
            hasError: false,
            isLoading: false,
            results: results.docs.map((item) => (item as any).id),
            pageSize: results.limit,
            currentPage: results.page,
            totalItems: results.totalDocs,
            lastQuery: JSON.stringify(query),
          });
        })
        .catch((e) => {
          console.log("Error while querying", e);
          setPaginationState({
            hasError: true,
            isLoading: false,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...(dependencies || []),
      paginationState.pageSize,
      paginationState.currentPage,
      query,
      didLoad,
    ]
  );

  if (!didLoad) {
    setDidLoad(true);
  }

  return {
    paginationState,
    setPaginationState,
    results: paginationState.results.map((id) => entityMap[id]),
  };
};
