import { ClientAvatar } from "components/common/client-avatar/client-avatar";
import { ProfilePreferencesIcon } from "components/common/icons/profile-preferences";

import { SDK } from "@sdk";
import { showErrorMessage } from "@sdk/sdk.hooks";
import { Button } from "components/common/button/button";
import { MobileNumberInputLazy } from "components/common/mobile-number-input/mobile-number-input-lazy";
import { Spinner } from "components/common/spinner/spinner";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { message } from "antd";
import { validatePhoneNumberWithCountryCode } from "helpers/is-valid-phone-number";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "store/modules/session/session.actions";
import {
  selectContact,
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";
import { isEmail } from "utils/is-email";

export const PreferencesTab = () => {
  const [countryCode, setCountryCode] = useState("GB");
  const sessionData = useSelector(selectSession);
  const contact = useSelector(selectContact);
  useEffect(() => {
    const countryCode = sessionData?.location?.data?.country;
    setCountryCode(countryCode || "GB");
  }, [sessionData]);

  const widget = useSelector(selectWidget);

  const [name, setName] = useState(
    contact?.data.firstName
      ? contact?.data.firstName + " " + contact?.data.lastName
      : ""
  );
  const [email, setEmail] = useState(contact?.data.primaryEmail || "");
  const [phone, setPhone] = useState(contact?.data.primaryMobile || "");
  const [checked, setChecked] = useState(
    contact?.preference?.marketingConsentObtained
  );
  const [PhoneError, setPhoneError] = useState("");

  const fieldToCollection = useMemo(
    () => ["NAME", "EMAIL", "PHONE", "MARKETING_PREFERENCE"],
    []
  );

  const { t } = useTranslation("common");

  const isFormValid = useMemo(() => {
    setPhoneError("");
    for (let field of fieldToCollection) {
      if (field === "EMAIL") {
        if (email && !isEmail(email)) {
          // console.log("EMAIL", PhoneError);
          return false;
        }
      } else if (field === "NAME") {
        if (!name) {
          // console.log("name", PhoneError);
          return false;
        }
      }
      if (field === "PHONE") {
        if (phone && !validatePhoneNumberWithCountryCode(phone)) {
          setPhoneError(t("INVALID_PHONE_NUMBER"));
          return false;
        }
      }
    }
    return true;
  }, [fieldToCollection, email, name, phone, t]);

  const [isProcessing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const onPreChatFormSubmitted = () => {
    setProcessing(true);
    const [firstName, ...otherNames] = name.split(" ");
    SDK.updateContactDetails({
      firstName: firstName,
      lastName: otherNames.join(" "),
      primaryEmail: email,
      primaryMobile: phone,
      preference: { marketingConsentObtained: checked },
    })
      .then((d) => {
        dispatch(setSession(d));
        setProcessing(false);
        message.success(t("PROFILE_UPDATED"));
      })
      .catch((e) => {
        showErrorMessage(e, t("SOMETHING_WRONG_CHECK_YOUR_INPUT_MESSAGE"));
        setProcessing(false);
      });
  };

  const submitPreChatFormOnEnter = (e) => {
    if (e.key === "Enter") {
      if (isFormValid) {
        onPreChatFormSubmitted();
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full h-full max-container ">
        <div className="header flex flex-row  items-center primary-gradient-bg text-white p-4">
          <div className="title flex-1 text-lg font-semibold  pl-2 animate fade-in-down">
            <div className="flex flex-row">
              <div className="user-avatar-container" style={{ marginTop: 4 }}>
                <ProfilePreferencesIcon />
              </div>
              <div className="flex-col pl-4">{t("PREFERENCES_TITLE")}</div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-auto bg-white w-full animate fade-in-up">
          <div className="p-16 flex flex-row justify-center items-center w-full">
            <ClientAvatar size={"8rem"} />
          </div>
          <div className="preferences-form">
            <div className="p-4 flex flex-col">
              {fieldToCollection.map((field) => {
                if (field === "NAME") {
                  return (
                    <input
                      placeholder={t("NAME_PLACEHOLDER")}
                      className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      key={field}
                      onKeyDown={submitPreChatFormOnEnter}
                    />
                  );
                } else if (field === "EMAIL") {
                  return (
                    <input
                      placeholder={`${t("EMAIL_TEXT")} - you@abc.com`}
                      className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      key={field}
                      onKeyDown={submitPreChatFormOnEnter}
                    />
                  );
                } else if (field === "PHONE") {
                  return (
                    <>
                      <MobileNumberInputLazy
                        onChange={setPhone}
                        onKeyDown={submitPreChatFormOnEnter}
                        defaultCountry={countryCode}
                      />
                      <div className="text-red-400 pb-3">{PhoneError}</div>
                      {/* <input
                  placeholder="phone - Eg: 0444342432"
                  className="border rounded-lg p-4 mb-4 outline-none focus:shadow-lg"
                  type="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  key={field}
                  onKeyDown={submitPreChatFormOnEnter}
                /> */}
                    </>
                  );
                } else if (field === "MARKETING_PREFERENCE") {
                  return (
                    <div className="flex items-center text-gray-500 mb-2">
                      <input
                        name="marketingConsentObtained"
                        type="checkbox"
                        onKeyDown={submitPreChatFormOnEnter}
                        className="mr-1 border"
                        onClick={() => {
                          setChecked(!checked);
                        }}
                      />
                      {t("I_LIKE_TO_RECEIVE_PROMO_VIA_EMAIL")}
                    </div>
                  );
                }
                return t("UNKNOWN_INPUT");
              })}

              {isFormValid ? (
                <Spinner isActive={isProcessing}>
                  <Button
                    label={t("PREFERENCES_SAVE")}
                    onClick={onPreChatFormSubmitted}
                  />
                </Spinner>
              ) : (
                <ToolTipX content={t("PLEASE_FILL_VALID_DETAILS")}>
                  <Button
                    label={t("PREFERENCES_SAVE")}
                    style={{ opacity: 0.2 }}
                    className="cursor-not-allowed"
                  />
                </ToolTipX>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
