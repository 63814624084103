import { iMessage, iMessageAttachment } from "@sdk/services/models";
import { uuidv4 } from "utils/generate-uuid";

export const GenerateCustomerMessage = ({
  conversationId,
  customerId,
  message,
  attachments,
  messageData,
}: {
  conversationId: string;
  customerId?: string;
  message: string;
  attachments?: iMessageAttachment[];
  messageData?: any;
}) => {
  return {
    id: uuidv4(),
    conversationId: conversationId,
    organizationId: "",
    connectionId: "",
    contactId: customerId || "",
    connectionType: "LIVE_CHAT",
    from: {
      senderType: "CLIENT",
      id: customerId || "",
    },
    references: [],
    metaData: {
      sentAt: new Date().getTime(),
      deliveredAt: new Date().getTime(),
    },
    message: message,
    attachments: attachments || [],
    data: messageData || {},
  } as iMessage;
};

export const GenerateGPTAssistantMessage = ({
  conversationId,
  message,
  attachments,
  messageData,
}: {
  conversationId: string;
  message: string;
  attachments?: iMessageAttachment[];
  messageData: any;
}) => {
  return {
    id: uuidv4(),
    conversationId: conversationId,
    organizationId: "",
    connectionId: "",
    contactId: "",
    connectionType: "LIVE_CHAT",
    from: {
      senderType: "USER",
      id: "CC",
    },
    references: [],
    metaData: {
      sentAt: new Date().getTime(),
      deliveredAt: new Date().getTime(),
    },
    message: message,
    attachments: attachments || [],
    data: messageData || {},
  } as iMessage;
};
