import React from "react";
import "./typing-indicator.css";

export const TypingIndicator = () => {
  return (
    <div className="container animated-cc cc-expand-bounce-left">
      <div className="typingIndicatorContainer">
        <div className="typingIndicatorBubble">
          <div className="typingIndicatorBubbleDot" />
          <div className="typingIndicatorBubbleDot" />
          <div className="typingIndicatorBubbleDot" />
        </div>
      </div>
    </div>
  );
};
