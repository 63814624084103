import { EllipsisOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { AvatarGroup } from "components/common/avatar-group/avatar-group";
import { Popover } from "components/common/popover/popover";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectWidget } from "store/modules/session/session.selectors";
import { TopMenu } from "./top-menu";

export const HomeHeaderCollapsed = ({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) => {
  const { t } = useTranslation("common");

  const widget = useSelector(selectWidget);
  const greetingMessage =
    widget?.configurations?.locale?.custom?.["GREETINGS_MESSAGE"] ||
    t("GREETINGS_MESSAGE");

  const guideMessage =
    widget?.configurations?.locale?.custom?.["GUIDE_MESSAGE"] ||
    t("GUIDE_MESSAGE");

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <div
      style={style}
      className={classNames(
        "flex flex-row justify-between text-white primary-gradient-bg-2",
        className
      )}
    >
      <div className="p-4 w-full">
        <div className="max-container flex flex-row justify-between w-full">
          <div className="flex flex-row  flex-1">
            <div className="user-list">
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <AvatarGroup maxCount={1} avatarSize={"3rem"} />
            </div>
            <div className="messages  ml-4">
              <div className="greeting-message-container text-xl">
                {greetingMessage}
              </div>
              <div className="guide-message-container text-sm">
                {guideMessage}
              </div>
            </div>
          </div>
          <div className="top-menu flex flex-row justify-end items-center">
            <Popover overLay={<TopMenu />} style={{ width: "18rem" }}>
              <EllipsisOutlined className="text-3xl cursor-pointer h-8 w-12 flex items-center justify-center rounded-full hover:bg-black hover:bg-opacity-25 outline-none" />
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};
