import { Store } from "@reduxjs/toolkit";
import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { showErrorMessage } from "@sdk/sdk.hooks";
import { iConversation, iMessageAttachment } from "@sdk/services/models";
import i18n from "i18next";
import { find } from "lodash";

import { message } from "antd";
import { selectAllConversations } from "store/modules/conversations/conversations.selectors";
import { resetUnreadCount } from "store/modules/messages/message.actions";
import { loadMessages } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import {
  selectSession,
  selectWidget,
  selectWidgetConfigurations,
} from "store/modules/session/session.selectors";
import { iStore } from "store/store.model";
import { createNewConversation } from "./create-new-conversation";
import { ResetSessionAndReload } from "./reset-session-and-reload";

export const sendMessage = async (
  conversation: iConversation,
  store: Store<iStore>,
  messageText: string,
  attachments: iMessageAttachment[],
  navigator: { replaceHistory: (path, state) => any },
  references?: string[]
) => {
  try {
    return await _sendMessage(
      conversation,
      store,
      messageText,
      attachments,
      navigator,
      references
    );
  } catch (e) {
    // Retry Again
    try {
      return await _sendMessage(
        conversation,
        store,
        messageText,
        attachments,
        navigator,
        references
      );
    } catch (e) {
      message.warning(i18n.t("NETWORK_ERROR_RETRYING"));
      // Retry Again
      try {
        return await _sendMessage(
          conversation,
          store,
          messageText,
          attachments,
          navigator,
          references
        );
      } catch (e) {
        showErrorMessage(e, i18n.t("NETWORK_ERROR_REFRESH_PAGE"));
        if ((e as any)?.message === "Network Error") {
          return;
        }

        // Todo: Handle Auth Failed; Reload the entire browser
        SDK.sendErrorLog({
          subject: "Widget Error Log",
          data: {
            scope: "Widget: Error in Send Message",
            widgetId: selectWidget(store.getState())?.id!,
            sessionId: selectSession(store.getState())?.id!,
            country: selectSession(store.getState())?.location?.data?.country!,
            ip: selectSession(store.getState())?.ip!,
            userAgent: window.navigator.userAgent,
            conversationId: conversation.id!,
            message: messageText,
            errorMessage: (e as any)?.message,
            errorData: JSON.stringify((e as any)?.response?.data || {}),
            lastToken: SocketConnector.lastToken,
            firstToken: SocketConnector.firstToken,
            sdkToken: JSON.stringify(SDK.getCurrentConfig() || {}),
            localStorageValue: (() => {
              try {
                const WIDGET_ID = selectWidget(store.getState())?.id!;
                const val = localStorage.getItem(
                  `CC_SESSION_TOKEN_${WIDGET_ID}`
                );
                return val;
              } catch (e) {
                return (e as any)?.message || "LC Error";
              }
            })(),
            canSetLocalStorageValue: (() => {
              try {
                localStorage.setItem(`CC_TEST_LC`, "TEST");
                return "YES";
              } catch (e) {
                return (e as any)?.message || "LC Error";
              }
            })(),
          },
        }).catch((e) => {
          // Do Nothing
        });

        if (
          (e as any)?.message &&
          (e as any)?.message.includes("Request failed with status code 403")
        ) {
          ResetSessionAndReload(store);
        }
      }
    }
  }
};

export const _sendMessage = async (
  conversation: iConversation,
  store: Store<iStore>,
  messageText: string,
  attachments: iMessageAttachment[],
  navigator: { replaceHistory: (path, state) => any },
  references?: string[]
) => {
  try {
    const conversationId = conversation.id!;
    if (conversation?.isTemporary) {
      const toastId = message.loading(i18n.t("SENDING_MESSAGE"));
      const messageState = selectMessageByConversationId(conversationId)(
        store.getState()
      );

      const widgetConfigurations = selectWidgetConfigurations(store.getState());
      const previousConversions = selectAllConversations(store.getState());
      const lastActiveConversation = find(
        previousConversions,
        (conversation) =>
          conversation.status === "OPEN" && !conversation.isTemporary
      );

      // Locally check for existing active connection and redirect
      if (
        !widgetConfigurations?.allowMultipleActiveChat &&
        lastActiveConversation
      ) {
        message.warning(i18n.t("ANOTHER_ACTIVE_CONVERSATION_EXISTS"));
        await loadMessages(lastActiveConversation.id!)(store);

        // Send Message
        await SDK.sendMessage(lastActiveConversation.id!, {
          text: messageText,
          attachments,
          references,
        });
        // Go to new conversation
        //* If Navigated from temporary, routing animation will not be performed
        navigator.replaceHistory(
          `/conversations/${lastActiveConversation.id}`,
          ["/temporary"]
        );
      } else {
        // Create Conversation
        const newConversation = await createNewConversation({
          message: conversation.metaData.lastMessage.text,
          tempConversationId: conversationId,
          additionalMessages: messageState.data
            .slice(1)
            .map((message) => ({ message: message.message })),
          // Todo: Pass Chat Bot Id if present,
          chatBotId: conversation.chatBotId,
          messageCampaignId: conversation?.data?.messageCampaignId,
        })(store);

        await loadMessages(newConversation.id!)(store);

        // Send Message
        await SDK.sendMessage(newConversation.id!, {
          text: messageText,
          attachments,
          references,
        });
        // Go to new conversation
        //* If Navigated from temporary, routing animation will not be performed
        navigator.replaceHistory(`/conversations/${newConversation.id}`, [
          "/temporary",
        ]);
      }
      message.destroy();
    } else {
      // Send Message
      await SDK.sendMessage(conversationId!, {
        text: messageText,
        attachments,
        references,
      });

      // Send Read Signal
      if (
        (conversation?.metaData?.lastMessage?.timestamp || 0) >
        (conversation?.metaData?.readWatermarks?.client || 0)
      ) {
        SDK.sendReadSignals(
          conversationId!,
          false,
          conversation?.metaData?.lastMessage.timestamp || 0
        );
        store.dispatch(resetUnreadCount(conversationId));
      }
    }
  } catch (e) {
    message.destroy();
    throw e;
  }
};
