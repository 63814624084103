import { edjsParser } from "@libs/edj-parser";
import { useSDKAction } from "@sdk";
import { Button } from "components/common/button/button";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { VideoPlayerLazy } from "components/common/video-player/video-player-lazy";
import { Tag } from "components/knowledge-base/tag";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { useHistory } from "react-router";
import { loadArticleCollectionById } from "store/modules/article-collection/article-collection.helpers";
import { selectArticleCollectionById } from "store/modules/article-collection/article-collection.selectors";
import {
  loadArticleById,
  loadArticlesOfCollection,
} from "store/modules/articles/articles.helpers";
import {
  selectArticleById,
  selectArticlesByCollection,
} from "store/modules/articles/articles.selectors";
import { selectSession } from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { BreadCrumbs, iBreadCrumbNavItem } from "./breadcrumb/breadcrumb";
import "./editor-js-preview.scss";
import { RateArticle } from "./rate-article/rate-article";

export const ViewArticlePage = () => {
  const history = useHistory();
  const session = useSelector(selectSession);
  const articleId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[3];
    // return "d5af40-e0287";
  }, [history.location.pathname]);

  // const article = useSelector(selectArticleById(articleId));

  const { state: article, isLoading } = useSimpleLoaderStore(
    selectArticleById(articleId),
    loadArticleById(articleId)(),
    [articleId],
    !articleId
  );

  // const collection = useSelector(
  //   selectArticleCollectionById(article?.collections?.[0])
  // );

  const {
    state: collection,
    isLoading: isLoadingCollection,
  } = useSimpleLoaderStore(
    selectArticleCollectionById(article?.collections?.[0]),
    loadArticleCollectionById(article?.collections?.[0])(),
    [article?.collections?.[0]],
    !article?.collections?.[0]
  );

  const store = useStore();
  const navigator = useNavigator();
  const { t } = useTranslation("common");

  const {
    state: similarArticles,
    isLoading: isSimilarArticlesLoading,
  } = useSimpleLoaderStore(
    selectArticlesByCollection(article?.collections?.[0]),
    loadArticlesOfCollection(article?.collections?.[0]),
    [article?.collections?.[0]],
    !article?.collections?.[0]
  );

  useEffect(() => {
    const parentDocument = document.getElementById("article-body-container");
    if (parentDocument) {
      const links = parentDocument.getElementsByTagName("a");
      for (var i = 0, len = links.length; i < len; i++) {
        links[i].target = "_blank";
      }
    }
  }, []);

  const breadcrumbNav = useMemo(() => {
    const firstCollection = collection;
    const nav: iBreadCrumbNavItem[] = [
      ...(firstCollection
        ? [
            {
              link: `/knowledge-base/collections/${firstCollection?.id}`,
              label: firstCollection?.label,
            },
          ]
        : []),
      {
        link: `/knowledge-base/articles/${article?.id}`,
        label: article?.title,
      },
    ];
    return nav;
  }, [article?.id, article?.title, collection]);

  const { doAction: addViewLog } = useSDKAction({
    action: (sdk) => (articleId: string) =>
      sdk.articles.logArticleView(articleId),
    failureMessage: t("SOMETHING_WENT_WRONG_MESSAGE"),
  });

  useEffect(() => {
    if (articleId) {
      const timer = setTimeout(() => {
        addViewLog(articleId).catch((e) => {
          // Ignore
        });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [addViewLog, articleId]);

  const [showFloatingHeader, setFloatingHeaderVisibility] = useState(false);
  const onPageScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const headerThreshold = 60;
      if (showFloatingHeader) {
        if ((e.target as any).scrollTop < headerThreshold) {
          setFloatingHeaderVisibility(false);
        }
      } else {
        if ((e.target as any).scrollTop > headerThreshold) {
          setFloatingHeaderVisibility(true);
        }
      }
    },
    [showFloatingHeader]
  );

  if (!article && !isLoading) {
    return (
      <div className="flex flex-col w-full h-full primary-gradient-bg-2">
        <div className="flex flex-col w-full h-full max-container bg-white">
          <SimplePageHeader
            title={t("KNOWLEDGE_BASE_TITLE")}
            useNavigationPush={true}
          />
          <div className="primary-bg flex-1 overflow-hidden">
            <PageScrollContainer
              className=" h-full w-full flex-1 bg-white relative p-8 pt-12"
              // style={{ borderRadius: "8px 8px 0 0" }}
              onScroll={onPageScroll}
            >
              <div className="relative z-10 -mt-8">
                {t("KB_ARTICLE_NOT_AVAILABLE")}
              </div>
            </PageScrollContainer>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        {showFloatingHeader ? (
          <SimplePageHeader
            title={article.title}
            useNavigationPush={true}
            invert={false}
          />
        ) : (
          <SimplePageHeader title={""} useNavigationPush={true} invert />
        )}

        {!article && isLoading && (
          <>
            <div className="animate-pulse p-10">
              <div className="w-full space-y-6">
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[360px]" />
              </div>
              <div className="w-full space-y-6 flex flex-col justify-center items-center mt-16">
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4" />
                <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4" />
              </div>
            </div>
          </>
        )}
        {article && (
          <>
            <div className="primary-bg flex-1 overflow-hidden z-10 -mt-2">
              <PageScrollContainer
                className=" h-full w-full flex-1 bg-white relative p-8 pt-0"
                // style={{ borderRadius: "8px 8px 0 0" }}
                onScroll={onPageScroll}
              >
                <div className="relative z-10">
                  <div className="relative px-2">
                    <div>
                      <div className="flex flex-row justify-center items-center">
                        <div className="flex flex-row flex-1 items-center">
                          <div className="flex-1 flex flex-col justify-center">
                            <div className="breadcrumbs mb-2">
                              <BreadCrumbs nav={breadcrumbNav} />
                            </div>
                            <div className="text-2xl font-extrabold">
                              {article.title}
                            </div>
                            <div className=" text-gray-800 dark:text-gray-400 mode_transition">
                              {article.description}
                            </div>

                            <div className="">
                              {article.tags?.map((tag) => (
                                <Tag key={tag}>
                                  {(tag.toUpperCase && tag.toUpperCase()) ||
                                    tag}
                                </Tag>
                              ))}
                            </div>
                            <div className=" text-gray-600 text-sm dark:text-gray-400 mode_transition">
                              {/* eslint-disable-next-line i18next/no-literal-string */}

                              {moment(article.metaData.createdAt).format(
                                "LL h:mm:ss a "
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {article.type === "GUIDE" && (
                        <VideoPlayerLazy url={article.videoUrl} />
                      )}
                      <div
                        className="mt-8 editor-js-preview"
                        id="article-body-container"
                        dangerouslySetInnerHTML={{
                          __html:
                            typeof article.body === "string"
                              ? article.body
                              : edjsParser.parse(article.body).join(""),
                        }}
                      ></div>

                      {similarArticles && similarArticles.length > 0 && (
                        <div className="flex flex-col mt-8 px-8 py-4 border-t border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                          <div className="font-semibold mb-4">
                            {t("KB_ARTICLE_SIMILAR")}
                          </div>
                          {/* <div className="text-gray-600">{collection?.label}</div> */}

                          <div className="questions-link-container">
                            <ul style={{ listStyleType: "disc" }}>
                              {similarArticles
                                .filter((article) => article.id !== articleId)
                                .map((article) => (
                                  <li
                                    key={article.id}
                                    className="cursor-pointer ml-8 my-2 hover:underline"
                                    onClick={() => {
                                      navigator.navigateTo(
                                        `/knowledge-base/articles/${article.id}`
                                      );
                                    }}
                                  >
                                    {article.title}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {session?.metaData.isVerified && (
                  <div className="mt-16">
                    <RateArticle articleId={articleId} />
                  </div>
                )}

                <div className="flex flex-col justify-center items-center  animate fade-in-up">
                  <div className="mt-4 mb-2 text-sm text-gray-800 flex justify-center">
                    {t("NO_ANSWER_TEXT")}
                  </div>

                  <div className="border border-primary rounded-lg">
                    <Button
                      label={t("CHAT_WITH_US_BUTTON")}
                      onClick={async () => {
                        const { conversation } = await initLocalConversation(
                          store
                        );
                        navigator.navigateTo(
                          `/conversations/${conversation.id}`
                        );
                      }}
                      type="secondary-button"
                      className="py-1"
                    />
                  </div>
                </div>
              </PageScrollContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
