import { ParentWindowService } from "@parent-window/parent-window";
import { MessageAttachmentTypes } from "@sdk/services/models";
import { Button } from "components/common/button/button";
import { ChatIcon2 } from "components/common/icons/chat-icon2";
import { NewChatIcon } from "components/common/icons/new-chat";
import { Spinner } from "components/common/spinner/spinner";
import { ChatListItem } from "components/conversations/chat-list-item/chat-list-item";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import { find, last, orderBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { selectAppInitTime } from "store/modules/app-state/app-state.selectors";
import { loadAllConversations } from "store/modules/conversations/conversations.helpers";
import { selectAllConversations } from "store/modules/conversations/conversations.selectors";
import { loadMessages } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import {
  selectWidget,
  selectWidgetConfigurations,
} from "store/modules/session/session.selectors";
import { selectUserById } from "store/modules/users/users.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { usePrevious } from "utils/use-previous";
import { HomePageWidgetContainer } from "./home-page-widget-container";
import { PoweredByLabel } from "../home.page";

export const ConversationsWidget = () => {
  const { state: conversations, isLoading } = useSimpleLoaderStore(
    selectAllConversations,
    loadAllConversations
  );
  const visibleConversations = useMemo(() => {
    return orderBy(
      conversations,
      ["status", "metaData.lastMessage.timestamp"],
      ["desc", "desc"]
    ).slice(0, 2);
  }, [conversations]);

  const navigator = useNavigator();

  const appInitTime = useSelector(selectAppInitTime);

  const store = useStore();

  const widgetConfigurations = useSelector(selectWidgetConfigurations);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstConversationsLength = useMemo(() => conversations?.length, []);
  const lastActiveConversation = useMemo(() => {
    //* Even temporary conversation is considered an active conversation
    const activeConversation = find(conversations, { status: "OPEN" });
    return activeConversation?.id;
  }, [conversations]);
  const previousConversationLength = usePrevious(conversations?.length || 0);

  useEffect(() => {
    const widgetJustStarted = appInitTime + 5000 > Date.now();
    if (
      (widgetJustStarted && !(window as any).ccAutoNavigateTriggered) ||
      (previousConversationLength || firstConversationsLength) <
        conversations.length
    ) {
      const openConversation = find(visibleConversations, { status: "OPEN" });
      if (openConversation) {
        navigator.navigateTo(`/conversations/${openConversation.id}`);
        (window as any).ccAutoNavigateTriggered = true;
        if (
          openConversation.metaData.readWatermarks.client <
          openConversation.metaData.lastMessage.team
        ) {
          const sendLastMessageToParent = async () => {
            const widgetConfig = selectWidget(store.getState());

            let organizationName = selectWidget(store.getState())
              ?.configurations?.locale?.custom?.TEAM_NAME;
            if (widgetConfig?.organizationId === "fc89e0") {
              organizationName = "Click Connector";
            }

            const messages = await (async () => {
              await loadMessages(openConversation.id!)(store);
              return (
                selectMessageByConversationId(openConversation.id!)(
                  store.getState()
                )?.data || []
              );
            })();

            const lastMessageFromUser = last(
              messages.filter((message) => message.from.senderType === "USER")
            )!;

            if (!lastMessageFromUser) {
              console.log(
                "Something went wrong while fetching messages for notification"
              );
              return;
            }

            const user = selectUserById(lastMessageFromUser.from.id)(
              store.getState()
            );

            const coverImage = (() => {
              const coverImageAttachment = find(
                lastMessageFromUser.attachments,
                {
                  type: MessageAttachmentTypes.GREETINGS_COVER,
                }
              );

              if (coverImageAttachment) {
                return coverImageAttachment.payload.coverImage;
              }
            })();

            const widget = selectWidget(store.getState());

            const { userName, userAvatar } = (() => {
              const userName = user?.data?.firstName;
              const userAvatar = user?.data?.avatar;

              if (
                lastMessageFromUser.from.id === "CC" ||
                lastMessageFromUser.from.id === "cc"
              ) {
                return {
                  userName:
                    widget?.configurations.chatBotProfile?.name || userName,
                  userAvatar:
                    widget?.configurations.chatBotProfile?.avatar || userAvatar,
                };
              }
              return {
                userName,
                userAvatar,
              };
            })();

            ParentWindowService.sendMessage({
              type: "CC_UNREAD_MESSAGE",
              data: {
                message: openConversation.metaData.lastMessage.text,
                conversationId: openConversation.id,
                automaticallyOpenChatWidgetOnNewMessages:
                  widgetConfig?.configurations
                    ?.automaticallyOpenChatWidgetOnNewMessages,
                organizationName,
                userAvatar,
                userName,
                coverImage,
              },
            });
          };
          sendLastMessageToParent();
        }
      }
    }
    // Todo: Check if es lint is disabled correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleConversations]);

  const { t, i18n } = useTranslation("common");

  const widget = useSelector(selectWidget);

  const showPoweredBy = !widget?.configurations?.hideCCLogo;

  return (
    <HomePageWidgetContainer
      icon={<ChatIcon2 size={"1.2rem"} />}
      title={t("YOUR_CONVERSATIONS")}
      noHeaderBorder
      extraContent={
        <div
          className="text-gray-600 text-xs  cursor-pointer"
          onClick={() => navigator.navigateTo(`/conversations`)}
        >
          {t("SEE_All_MESSAGE")}
        </div>
      }
    >
      <Spinner isActive={isLoading}>
        <div className="conversation-list-container animate fade-in-right">
          {visibleConversations.map((conversation) => (
            <ChatListItem key={conversation.id} conversation={conversation} />
          ))}
          {visibleConversations.length === 0 && (
            <div className="border-dashed border rounded-lg m-8 mb-2 p-8 flex flex-col justify-center items-center animate fade-in">
              <ChatIcon2 size={40} className="text-gray-600" />
              <div className="text-gray-600 text-center">
                {t("NEW_USER_MESSAGE")}
              </div>
            </div>
          )}
        </div>
      </Spinner>
      {/* Todo: Check for active conversation here */}
      {(widgetConfigurations?.allowMultipleActiveChat ||
        !lastActiveConversation) && (
        <div className="start-conversations-button p-4 animate">
          <Button
            label={
              <div className="flex flex-row">
                <NewChatIcon className="mr-2" /> {t("NEW_CONVERSATION_BUTTON")}
              </div>
            }
            onClick={async () => {
              const { conversation } = await initLocalConversation(store);
              navigator.navigateTo(`/conversations/${conversation.id}`);
            }}
            size="SMALL"
          />
        </div>
      )}
      {showPoweredBy && (
        <PoweredByLabel
          label={t("TAB_LABEL_POWERED_BY")}
          language={i18n.language}
        />
      )}
    </HomePageWidgetContainer>
  );
};
