import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import {
  selectContact,
  selectWidget,
} from "store/modules/session/session.selectors";

export const usePreChatFormVisibility = (conversationId: string) => {
  const widget = useSelector(selectWidget);
  const contact = useSelector(selectContact);
  const conversation = useSelector(selectConversationById(conversationId));

  const isPreChatFormVisible = useMemo(() => {
    const isEnabled =
      widget?.configurations.conversationFlow.preChatForm.isEnabled || false;
    const requiredFields =
      widget?.configurations.conversationFlow.preChatForm.fields || [];

    const hasRequiredData = requiredFields.reduce(
      (acc, field: "NAME" | "EMAIL" | "PHONE") => {
        if (!acc) {
          return false;
        }
        if (field === "NAME") {
          return !!contact?.data?.firstName;
        } else if (field === "EMAIL") {
          return !!contact?.data?.primaryEmail;
        } else if (field === "PHONE") {
          return !!contact?.data?.primaryMobile;
        }
        return acc;
      },
      true
    );
    const isChatBotActive = conversation?.activeBot;
    if (isChatBotActive) {
      return false;
    }
    return isEnabled && !hasRequiredData;
  }, [
    widget?.configurations.conversationFlow.preChatForm.isEnabled,
    widget?.configurations.conversationFlow.preChatForm.fields,
    conversation?.activeBot,
    contact?.data?.firstName,
    contact?.data?.primaryEmail,
    contact?.data?.primaryMobile,
  ]);
  return isPreChatFormVisible;
};
