import { iTicket } from "@sdk/services/tickets-models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { ticketsDefaultQueryConfig } from "./tickets-default-query";

export const {
  slice: TicketsSlice,
  reducers: TicketsSliceReducer,
  actions: {
    setEntityQueryResults: setTicketsQueryResults,
    setQueryList: setTicketsQueryList,
    setEntity: setTicket,
    setEntities: setTickets,
    patchEntity: patchTicket,
    addNewEntity: addNewTicket,
    setEntityFetchingStatus: setTicketFetchingStatus,
    setEntitiesFetchingStatus: setTicketsFetchingStatus,
    setEntityFailedStatus: setTicketFailedStatus,
    setEntityQueryError: setTicketsQueryError,
    resetQuery: resetTicketsQuery,
  },
} = CreateEntitySlice<iTicket>({
  sliceName: "tickets",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...ticketsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
