import { ArrowRightIcon } from "components/common/icons/arrow-right";
import React, { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const UnHappyResponse = ({
  onRedirectToTeamMember,
}: {
  onRedirectToTeamMember: () => any;
}) => {
  const { t } = useTranslation("common");
  return (
    <div
      className="gap-x-2 font-medium border border-gray-200 bg-white text-gray-800 shadow-sm rounded-lg flex flex-row justify-between items-center mt-6 p-4 w-full cursor-pointer hover:bg-gray-200"
      style={{
        bottom: -18,
        right: -18,
        zIndex: 9,
      }}
      onClick={onRedirectToTeamMember}
    >
      <span className="font-semibold"> {t("REDIRECT_TO_HUMAN_SUPPORT")}</span>{" "}
      <ArrowRightIcon />
    </div>
  );
};
