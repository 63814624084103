import React, { Suspense } from "react";
import "./mobile-number-input.scss";

const MobileNumberInput = React.lazy(() => import("./mobile-number-input"));

export const MobileNumberInputLazy = (props: {
  onChange: any;
  onKeyDown: any;
  defaultCountry: string;
}) => {
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <Suspense fallback={<>...</>}>
      <MobileNumberInput {...props} />
    </Suspense>
  );
};
