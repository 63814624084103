import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { CheckMarkIcon } from "components/common/icons/check-mark";
import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense, useMemo } from "react";

export const RateMagicAssistantReply = ({
  responseId,
}: {
  responseId: string;
}) => {
  const {
    doAction: rateMagicAssistantResponse,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req: {
        responseId: string;
        rating?: "HAPPY" | "NOT_HAPPY";
      }) => SDK.rateMagicAssistantResponse(req),
      successMessage: "Thank you for the feedback",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    []
  );

  return (
    <div
      className="hidden flex-row gap-2 items-center gap-x-2 text-sm font-medium border border-gray-200 bg-white text-gray-800 shadow-sm rounded-lg absolute magic-assistant-rating-container"
      style={{
        bottom: -18,
        right: -18,
        zIndex: 9,
      }}
    >
      {isProcessing && (
        <>
          <LoadingIndicator />
        </>
      )}
      {response && !isProcessing && (
        <>
          {/* <div className="fsd">Rated</div>
          <CheckMarkIcon size={14} /> */}
        </>
      )}
      {!response && !isProcessing && (
        <>
          <button
            type="button"
            className="bg-white text-gray-800 hover:bg-gray-200 py-2 px-3 rounded-l-lg"
            onClick={() => {
              rateMagicAssistantResponse({
                responseId,
                rating: "HAPPY",
              });
            }}
          >
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 10v12" />
              <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
            </svg>
          </button>
          <button
            type="button"
            className="bg-white text-gray-800 hover:bg-gray-200 py-2 px-3 rounded-r-lg"
            onClick={() => {
              rateMagicAssistantResponse({
                responseId,
                rating: "NOT_HAPPY",
              });
            }}
          >
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M17 14V2" />
              <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
            </svg>
          </button>
        </>
      )}
    </div>
  );
};
