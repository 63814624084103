import { useNavigator } from "helpers/hooks/use-navigator";
import { AddEllipsis } from "utils/add-ellipsis";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface iBreadCrumbNavItem {
  link: string;
  label: string;
}

export const BreadCrumbs = ({ nav }: { nav: iBreadCrumbNavItem[] }) => {
  const navigator = useNavigator();
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse flex-wrap gap-2">
        <li className="inline-flex items-center whitespace-nowrap">
          <a
            className="inline-flex items-center text-sm font-medium text-gray-700 hover-text-primary-600 dark:text-gray-400 dark:hover:text-white whitespace-nowrap cursor-pointer"
            onClick={() => {
              navigator.navigateTo(`/knowledge-base`);
            }}
          >
            <svg
              className="w-3 h-3 me-2.5 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            {t("KB_HOME")}
          </a>
        </li>
        {nav.map((item, i) => (
          <li key={item.link} className="whitespace-nowrap">
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 9 4-4-4-4"
                />
              </svg>
              {i === nav.length - 1 ? (
                <>
                  <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                    {AddEllipsis(item.label, 30)}
                  </span>
                </>
              ) : (
                <>
                  <a
                    className="ms-1 text-sm font-medium text-gray-700 hover-text-primary-600 md:ms-2 dark:text-gray-400 dark:hover:text-white cursor-pointer"
                    onClick={() => {
                      navigator.navigateTo(item.link);
                    }}
                  >
                    {AddEllipsis(item.label, 40)}
                  </a>
                </>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
