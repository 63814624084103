import { iArticleCollection } from "@sdk/services/knowledge-base/knowledge-base-models";
import classNames from "classnames";
import { Button } from "components/common/button/button";
import { SearchIcon } from "components/common/icons/search";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { HelpCenterArticleItem } from "components/knowledge-base/help-center-article-item";
import { HelpCenterCollectionItem } from "components/knowledge-base/help-center-collection-item";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { useHistory } from "react-router";
import {
  loadAllArticleCollections,
  loadArticleCollectionById,
} from "store/modules/article-collection/article-collection.helpers";
import {
  selectAllArticleCollections,
  selectArticleCollectionById,
} from "store/modules/article-collection/article-collection.selectors";
import { loadArticlesOfCollection } from "store/modules/articles/articles.helpers";
import { selectArticlesByCollection } from "store/modules/articles/articles.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";

export const KbCollectionPage = ({
  disableBack,
}: {
  disableBack?: boolean;
}) => {
  const { t } = useTranslation("common");

  const history = useHistory();
  const collectionId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    if (pathArray[2] !== "collections") {
      return "";
    }
    return pathArray[3];
  }, [history.location.pathname]);

  const { state: collection } = useSimpleLoaderStore(
    selectArticleCollectionById(collectionId),
    loadArticleCollectionById(collectionId),
    [collectionId],
    !collectionId
  );

  const store = useStore();
  const navigator = useNavigator();

  const { state: allArticles, isLoading } = useSimpleLoaderStore(
    selectArticlesByCollection(collectionId),
    loadArticlesOfCollection(collectionId),
    [collectionId],
    !collectionId
  );


  const searchController = useSearch(allArticles || []);
  

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={collection?.label || t("KNOWLEDGE_BASE_TITLE")}
          disableBack={disableBack}
          useNavigationPush={true}
        />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className="h-full w-full flex-1 p-4 bg-white relative"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            {collection && (
              <HelpCenterCollectionHeader collection={collection} />
            )}

            {/* Search BAr */}
            <div className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md">
              <input
                placeholder={`Search ${collection?.label}...`}
                className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
                onChange={(el) => {
                  searchController.setSearchTerm(el.currentTarget.value);
                }}
                autoFocus
              />
              <SearchIcon className="mr-2" />
            </div>

            {/* Results */}

            <div className="articles mt-12">
              {searchController.filteredData.map((article) => (
                <HelpCenterArticleItem
                  key={article.id!}
                  article={article}
                  onSelect={() => {
                    navigator.navigateTo(
                      `/knowledge-base/articles/${article.id}`
                    );
                  }}
                />
              ))}
              {searchController.filteredData.length === 0 && (
                <>
                  <div className="border-dashed border rounded-lg m-8 mb-2 p-8 flex flex-col justify-center items-center">
                    <SearchIcon size={40} className="text-gray-600" />
                    <div className="text-gray-600 text-center">
                      {t("NO_MATCHING_ARTICLES")}
                    </div>
                  </div>
                </>
              )}

              <div className="flex flex-col justify-center items-center animate fade-in-up">
                <div className="mt-16 mb-2 text-sm text-gray-800 flex justify-center">
                  {t("NO_ANSWER_TEXT")}
                </div>

                <div className="border border-primary rounded-lg">
                  <Button
                    label={t("CHAT_WITH_US_BUTTON")}
                    onClick={async () => {
                      const { conversation } = await initLocalConversation(
                        store
                      );
                      navigator.navigateTo(`/conversations/${conversation.id}`);
                    }}
                    type="secondary-button"
                    className="py-1"
                  />
                </div>
              </div>
            </div>
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};

export const HelpCenterCollectionHeader = ({
  collection,
}: {
  collection:
    | { icon: string; label: string; description: string }
    | iArticleCollection;
}) => {
  const [isHiddenBg, setHiddenBg] = useState(false);
  return (
    <div
      className={classNames(
        "w-full border-0 bg-white dark:bg-gray-900 rounded-2xl mode_transition bg-no-repeat mt-4 py-12 bg-right-top bg-small"
      )}
      style={{ backgroundImage: `url(${collection?.icon})` }}
    >
      <div className="flex-1 flex flex-col justify-center pl-4">
        <div className=" text-primary-600 text-2xl collection-title">
          {collection.label}
        </div>
        <div className=" text-gray-600 dark:text-gray-400 mode_transition collection-description text-base mr-0 sm:mr-40">
          {collection.description}
        </div>
      </div>
    </div>
  );
};
