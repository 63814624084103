import { iArticleCollection } from "@sdk/services/knowledge-base/knowledge-base-models";
import classNames from "classnames";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { ArticleIcon } from "components/common/icons/article-icon";
import React from "react";

export const HelpCenterCollectionItem = ({
  collection,
  onSelect,
}: {
  collection: iArticleCollection;
  onSelect?: () => any;
}) => {
  return (
    <div
      className="my-2 border-gray-200 border rounded-2xl hover-border-primary-500 rounded-md flex collection-item draggable-collection mode_transition"
      onClick={onSelect}
    >
      <div
        className={classNames(
          "w-full border-0 bg-white dark:bg-gray-900 rounded-lg  mode_transition",
          {
            "hover:bg-primary-100 cursor-pointer dark:hover:bg-gray-800": Boolean(
              onSelect
            ),
          }
        )}
      >
        <div className="flex flex-row justify-center items-center group">
          <div className="flex flex-row flex-1 items-center p-4">
            <div className="flex items-center w-10">
              {collection.icon ? (
                <img
                  src={collection.icon || "/assets/article.png"}
                  width={45}
                  className="rounded-full"
                  alt="icon"
                />
              ) : (
                <ArticleIcon />
              )}
            </div>
            <div className="flex-1 flex flex-col justify-center pl-4">
              <div className="text-primary">{collection.label}</div>
              <div className=" text-gray-600 dark:text-gray-400 mode_transition text-sm">
                {collection.description}
              </div>
            </div>
            <div className="hidden group-hover:show animate fade-in-left">
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
