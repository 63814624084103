import { DynamicFormsServices } from "./services/dynamic-forms/dynamic-forms.service";
import { FilesService } from "./services/file-upload/file-services";
import { KnowledgeBaseService } from "./services/knowledge-base/knowledge-base-services";
import { WidgetServices } from "./services/services";
import { aggregateClasses } from "./utils/mixin";

export class MainService extends aggregateClasses(
  WidgetServices,
  FilesService,
  KnowledgeBaseService,
  DynamicFormsServices
) {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  getCurrentConfig = () => {
    return this.config;
  };
  constructor(protected config: { basePath: string; token: string }) {
    super(config);
  }
}
