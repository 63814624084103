import { ProfilePreferencesIcon } from "components/common/icons/profile-preferences";

import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button } from "components/common/button/button";
import { IconContainer } from "components/common/icons/icon-container";
import { LockIcon } from "components/common/icons/lock-icon";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { Spinner } from "components/common/spinner/spinner";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { ResetSessionAndReload } from "helpers/reset-session-and-reload";
import React, { useMemo, useState } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useSelector, useStore } from "react-redux";
import { setLastVisitedTab } from "store/modules/app-state/app-state.actions";
import {
  selectContact,
  selectSession,
} from "store/modules/session/session.selectors";
import { isEmail } from "utils/is-email";

export const ProfileTab = () => {
  const session = useSelector(selectSession);

  const contact = useSelector(selectContact);

  const { t } = useTranslation("common");

  const [email, setEmail] = useState(contact?.data.primaryEmail || "");
  const [otp, setOtp] = useState("");

  const isEmailValid = useMemo(() => {
    return isEmail(email);
  }, [email]);

  const [otpStep, setOTPStep] = useState(
    "ENTER_EMAIL" as "ENTER_EMAIL" | "ENTER_OTP"
  );

  const store = useStore();

  const {
    doAction: sendOTP,
    isProcessing: isSendingOTP,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (email: string) =>
        SDK.sendOTP(email).then((d) => {
          setOTPStep("ENTER_OTP");
          setOTPSentTime(Date.now());
        }),
      successMessage: "OTP has been emailed",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    []
  );

  const {
    doAction: verifyOTP,
    isProcessing: isVerifyingOTP,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (email: string) =>
        SDK.verifyWithOTP(email).then((d) => {
          store.dispatch(setLastVisitedTab("HOME"));
        }),
      successMessage: "You have successfully logged in.",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    [store]
  );

  const otpFormOnEnter = (e) => {
    if (e.key === "Enter") {
      if (isEmailValid) {
        sendOTP(email);
      }
    }
  };

  const [OTPSentTime, setOTPSentTime] = useState(0);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full h-full max-container ">
        <SimplePageHeader
          title={t("PROFILE_TITLE")}
          disableBack={true}
          icon={
            <div className="dark-bg rounded-lg p-2 flex justify-center items-center">
              <ProfilePreferencesIcon size={"1.3rem"} />
            </div>
          }
        />

        <div
          className="flex-1 overflow-auto  w-full animate fade-in-up flex flex-col justify-center items-center"
          style={{ background: "#f4f4f4" }}
        >
          {session?.metaData.isVerified ? (
            <div className="w-full p-4">
              <div className="flex flex-col items-center w-full rounded-lg shadow-lg border border-gray-300 bg-white p-4">
                <div className="">
                  <LockIcon />
                </div>
                <div className="text-gray-800 font-semibold mt-2 text-center mb-4">
                  {t("PROFILE_LOGGED_IN_AS")} {contact?.data?.primaryEmail}
                </div>

                <div className="">
                  <Button
                    type="secondary-button"
                    label={
                      <div className="flex flex-row justify-center items-center">
                        <div className="mr-2">{t("LOGOUT")}</div>
                        <IconContainer size={"1.1rem"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M5 11H13V13H5V16L0 12L5 8V11ZM3.99927 18H6.70835C8.11862 19.2447 9.97111 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C9.97111 4 8.11862 4.75527 6.70835 6H3.99927C5.82368 3.57111 8.72836 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C8.72836 22 5.82368 20.4289 3.99927 18Z"></path>
                          </svg>
                        </IconContainer>
                      </div>
                    }
                    onClick={() => {
                      // eslint-disable-next-line i18next/no-literal-string
                      store.dispatch(setLastVisitedTab("HOME"));
                      ResetSessionAndReload(store);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full p-4">
              {otpStep === "ENTER_EMAIL" && (
                <div className="flex flex-col items-center w-full rounded-lg shadow-lg bg-white p-4 fade-in-right animated-fast border border-gray-300 hover:border-gray-500">
                  <div className="">
                    <LockIcon />
                  </div>
                  <div className="text-gray-800 font-semibold my-2">
                    {t("PROFILE_NOT_LOGGED_IN")}
                  </div>
                  <div className="w-full">
                    <input
                      placeholder={`${t("EMAIL_TEXT")} - you@abc.com`}
                      className="border rounded-lg p-2 mb-4 outline-none focus:shadow-lg w-full"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onKeyDown={otpFormOnEnter}
                    />
                  </div>
                  <div className="">
                    {isEmailValid ? (
                      <Spinner isActive={isSendingOTP}>
                        <Button
                          label={
                            <div className="flex flex-row justify-center items-center">
                              <div className="mr-2">{t("SEND_OTP")}</div>
                              <IconContainer size={"1.1rem"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"></path>
                                </svg>
                              </IconContainer>
                            </div>
                          }
                          onClick={() => sendOTP(email)}
                        />
                      </Spinner>
                    ) : (
                      <ToolTipX content={t("PLEASE_FILL_VALID_DETAILS")}>
                        <Button
                          label={
                            <div className="flex flex-row justify-center items-center">
                              <div className="mr-2">{t("SEND_OTP")}</div>
                              <IconContainer size={"1.1rem"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"></path>
                                </svg>
                              </IconContainer>
                            </div>
                          }
                          style={{ opacity: 0.2 }}
                          className="cursor-not-allowed"
                        />
                      </ToolTipX>
                    )}
                  </div>
                </div>
              )}
              {otpStep === "ENTER_OTP" && (
                <div className="flex flex-col items-center w-full rounded-lg shadow-lg bg-white p-4 fade-in-right animated-fast">
                  <div className="flex flex-row justify-start items-center w-full">
                    <div className="">
                      <Button
                        label={
                          <div className="flex flex-row justify-center items-center">
                            <IconContainer size={"1.1rem"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                              </svg>
                            </IconContainer>
                            <div className="ml-2">{t("BACK")}</div>
                          </div>
                        }
                        type="secondary-button"
                        // eslint-disable-next-line i18next/no-literal-string
                        onClick={() => setOTPStep("ENTER_EMAIL")}
                      />
                    </div>
                  </div>
                  <div className="">
                    <LockIcon />
                  </div>
                  <div className="text-gray-800 font-semibold my-2">
                    {t("ENTER_OTP")}
                  </div>
                  <div className="w-full flex flex-row justify-center items-center mb-4">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={
                        // eslint-disable-next-line i18next/no-literal-string
                        <div className="p-2 flex flex-col justify-center items-center">
                          -
                        </div>
                      }
                      renderInput={(props) => (
                        <input
                          {...props}
                          className="border rounded-lg p-2 outline-none focus:shadow-lg w-full"
                        />
                      )}
                    />
                  </div>
                  <div className="">
                    {otp.length === 6 ? (
                      <Spinner isActive={isVerifyingOTP}>
                        <Button
                          label={
                            <div className="flex flex-row justify-center items-center">
                              <div className="mr-2">{t("LOGIN_WITH_OTP")}</div>
                              <IconContainer size={"1.1rem"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M10 11V8L15 12L10 16V13H1V11H10ZM2.4578 15H4.58152C5.76829 17.9318 8.64262 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9H2.4578C3.73207 4.94289 7.52236 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C7.52236 22 3.73207 19.0571 2.4578 15Z"></path>
                                </svg>
                              </IconContainer>
                            </div>
                          }
                          onClick={() => verifyOTP(otp)}
                        />
                      </Spinner>
                    ) : (
                      <ToolTipX content={t("PLEASE_FILL_VALID_DETAILS")}>
                        <Button
                          label={
                            <div className="flex flex-row justify-center items-center">
                              <div className="mr-2">{t("LOGIN_WITH_OTP")}</div>
                              <IconContainer size={"1.1rem"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M10 11V8L15 12L10 16V13H1V11H10ZM2.4578 15H4.58152C5.76829 17.9318 8.64262 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9H2.4578C3.73207 4.94289 7.52236 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C7.52236 22 3.73207 19.0571 2.4578 15Z"></path>
                                </svg>
                              </IconContainer>
                            </div>
                          }
                          style={{ opacity: 0.2 }}
                          className="cursor-not-allowed"
                        />
                      </ToolTipX>
                    )}
                  </div>
                  <div className="">
                    <Countdown
                      key={OTPSentTime + 2 * 60 * 1000}
                      date={OTPSentTime + 2 * 60 * 1000}
                      renderer={({ hours, minutes, seconds, completed }) => {
                        if (completed) {
                          // Render a completed state
                          return (
                            <Spinner isActive={isSendingOTP}>
                              <Button
                                label={t("RESEND_OTP")}
                                type="secondary-button"
                                onClick={() => sendOTP(email)}
                                className="mt-2"
                              />
                            </Spinner>
                          );
                        } else {
                          // Render a countdown
                          return (
                            <span>
                              <Button
                                label={`${t(
                                  "RESEND_OTP"
                                )} (Wait - ${minutes}:${seconds})`}
                                type="secondary-button"
                                style={{ opacity: 0.2 }}
                                className="cursor-not-allowed mt-2"
                              />
                            </span>
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
