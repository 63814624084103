import { ParentWindowService } from "@parent-window/parent-window";
import { SDK } from "@sdk";
import { ReferenceSignalHandler } from "@services/reference-signal-handler";
import { uniq, without } from "lodash";
import { Store } from "redux";
import { setActiveCampaign } from "store/modules/app-state/app-state.actions";
import { selectConversationsMapAll } from "store/modules/conversations/conversations.selectors";
import {
  setConversation,
  setConversationsQueryList,
} from "store/modules/conversations/conversations.slice";
import { GetSafely } from "utils/get-safely";
import { isInIframe } from "utils/is-in-iframe";
import { ResetSessionAndReload } from "./reset-session-and-reload";
import { selectPreferredSubjectLine } from "store/modules/app-state/app-state.selectors";

export const createNewConversation = ({
  message = "",
  tempConversationId,
  additionalMessages,
  chatBotId,
  messageCampaignId,
}: {
  message: string;
  tempConversationId: string;
  additionalMessages?: { message: string }[];
  chatBotId?: string;
  messageCampaignId?: string;
}) => async (store: Store) => {
  const preferredSubjectLine = selectPreferredSubjectLine(store.getState());
  const createNewConversationResponse = await SDK.createNewConversation({
    campaignMessage: {
      text: message,
      timestamp: Date.now(),
      inviteeId: "1",
      additionalMessages,
    },
    //! DEBUG THIS. Sometimes  ParentWindowService?.currentPageDetails is undefined
    startedPage: ParentWindowService?.currentPageDetails?.url || "#",
    additionalData: {
      referencePage: "",
      messageCampaignId,
    },
    chatBotId,
    subjectLine: preferredSubjectLine || undefined,
  }).catch((e) => {
    const errorMessage = GetSafely(() => e.response.data.message, " ");
    if (errorMessage.includes("Widget not found")) {
      ResetSessionAndReload(store);
    }
    throw e;
  });

  const allConversationIds =
    selectConversationsMapAll(store.getState())?.list || [];
  const newConversationList = without(
    uniq([
      ...allConversationIds,
      createNewConversationResponse.conversation.id!,
    ]),
    tempConversationId
  );

  store.dispatch(setConversation(createNewConversationResponse.conversation));
  store.dispatch(setActiveCampaign({ campaignId: "", campaignUser: "" }));
  store.dispatch(
    setConversationsQueryList({
      query: "all",
      list: newConversationList,
    })
  );

  // Todo: Could add it to all
  if (!isInIframe() && ReferenceSignalHandler.getReferer()) {
    setTimeout(() => {
      SDK.addActivity({
        type: "REFERER_SIGNAL",
        data: {
          page: {
            url: ReferenceSignalHandler.getReferer(),
            title: undefined,
          },
        },
      }).catch((e) => {
        console.log("Error while sending reference signal", e);
      });
    }, 2000);
  }

  return createNewConversationResponse.conversation;
};
