export function AddEllipsis(
  text: string,
  macCharCount: number,
  hideEllipses: boolean = true
) {
  if (text && text.length > macCharCount) {
    return `${text.slice(0, macCharCount)}${hideEllipses ? "" : "..."}`;
  }
  return text;
}
