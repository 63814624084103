import { extractEntityTokens } from "./extract-tokens";
import { ReplaceTokens } from "./token-replacer";

export const replaceEntityTokens = (
  content: string,
  _context: Record<string, string | undefined>
) => {
  const context = { ..._context };
  const entities = extractEntityTokens(content);
  for (const entity of entities) {
    if (entity.type === "@") {
      content = content.replace(`@${entity.value}`, entity.data.label);
    } else if (entity.type === "{{") {
      const parts = entity.value.split(" | ");

      const messageVariable = parts[0].trim();
      const defaultValue = parts?.[1]?.trim();

      content = content.replace(entity.original, `{${messageVariable}}`);

      if (!context[messageVariable]) {
        context[messageVariable] = defaultValue;
      }
    }
  }
  return ReplaceTokens(content, context);
};
