import { ParentWindowService } from "@parent-window/parent-window";
import { Store } from "@reduxjs/toolkit";
import { selectContactId } from "store/modules/session/session.selectors";
import { SetLocalStorageItemSafely } from "utils/set-local-storage-item";

export const ResetSessionAndReload = (store: Store) => {
  const WIDGET_ID = (window as any).WIDGET_ID;
  const currentContactId = selectContactId(store.getState());
  ParentWindowService.sendMessage({
    type: "RESET_SESSION_AND_RELOAD",
    data: { currentContactId },
  });
  SetLocalStorageItemSafely(`CC_SESSION_TOKEN_${WIDGET_ID}`, "");
  window.location.reload();
};
