import { iStore } from "../../store.model";
import { defaultWidget } from "./data/default-widtet";

export const selectSession = (state: iStore) => state.session.session;

export const selectContact = (state: iStore) => state.session.session?.contact;

export const selectContactId = (state: iStore) =>
  state.session.session?.contactId;

export const selectWidget = (state: iStore) =>
  state.session.widgetConfigurations;

export const selectWidgetConfigurations = (state: iStore) =>
  state.session.widgetConfigurations?.configurations;

export const selectWidgetColorPallet = (state: iStore) =>
  state.session.widgetConfigurations?.configurations?.appearance?.colorPallet ||
  defaultWidget?.configurations?.appearance?.colorPallet;

export const selectFaqCollections = (state: iStore) =>
  state.session.widgetConfigurations?.configurations.faqCollections.map(
    (collection, index) => ({
      id: index.toString(),
      ...collection,
    })
  ) || [];

export const selectAllFAQs = (state: iStore) =>
  state.session.widgetConfigurations?.configurations.faqCollections
    .map((collection, collectionIndex) =>
      collection.faqList.map((faq, faqIndex) => ({
        id: `${collectionIndex.toString()}-${faqIndex.toString()}`,
        collectionId: collectionIndex.toString(),
        collectionLabel: collection.label,
        ...faq,
      }))
    )
    .flat(1) || [];

export const selectFaqById = (faqId: string = "") => (state: iStore) => {
  const [collectionIndex, faqIndex] = faqId.split("-");
  return {
    id: `${collectionIndex}-${faqIndex}`,
    collectionId: collectionIndex.toString(),
    collectionLabel:
      state.session.widgetConfigurations?.configurations?.faqCollections?.[
        Number(collectionIndex)
      ]?.label || "",
    ...(state.session.widgetConfigurations?.configurations?.faqCollections?.[
      Number(collectionIndex)
    ]?.faqList[Number(faqIndex)] || {}),
  };
};

const emptyArray = [];
export const selectAllTicketTypes = (state: iStore) =>
  state.session.ticketTypes || emptyArray;

export const selectActiveTours = (state: iStore) =>
  state.session.activatedTours;

export const selectActivatedMessageCampaigns = (state: iStore) =>
  state.session.activatedMessageCampaigns;
