import { iConversation, iMessage } from "@sdk/services/models";
import classNames from "classnames";
import { Popover } from "components/common/popover/popover";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import { GetReadableTime } from "helpers/get-readable-time";
import { getUserName } from "helpers/get-user-name";
import find from "lodash/find";
import React, { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectWidget } from "store/modules/session/session.selectors";
import { selectUserById } from "store/modules/users/users.selectors";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import IframeUrlLoaderActionButton from "../iframe-url-loader/iframe-url-loader";
import { MessageQuickReplies } from "./attachments/message-quick-replies";
import { LazyMarkdownPreview } from "./lazy-mark-down-preview";
import { MessageAttachments } from "./message-attachment";
import {
  isFirstMessageOfGroup,
  isLastMessageOfGroup,
  shouldShowUserAvatar,
} from "./message-list-helpers";
import { RateArticle } from "views/knowledge-base-pages/rate-article/rate-article";
import { RateMagicAssistantReply } from "./rate-magic-assistant-reply";
import { UnHappyResponse } from "./un-happy-response";
import { isHTML } from "utils/is-html";

const extractUrls = require("extract-urls");

const CalendlyButton = React.lazy(
  () => import("../calendly-button/calendly-button")
);

const CalButton = React.lazy(() => import("../cal-button/cal-button"));

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

export const MessagesListItem = ({
  message,
  index,
  messages,
  conversation,
  useMarkDownRender,
  hideMessageOptions,
  onRedirectToTeamMember,
}: {
  message: iMessage;
  index: number;
  messages: iMessage[];
  conversation: iConversation;
  useMarkDownRender?: boolean;
  hideMessageOptions?: boolean;
  onRedirectToTeamMember?: () => any;
}) => {
  const attachments = useMemo(() => {
    return (message.attachments || []).filter(
      (attachment) =>
        attachment.type !== "QUICK_REPLIES" &&
        attachment.type !== "SELECTED_QUICK_REPLY"
    );
  }, [message]);

  const { t } = useTranslation("common");

  const {
    containsCalenlyLink,
    calendlyLink,
    alreadyScheduled,
  } = useMemo(() => {
    const urls = extractUrls(message.message) || [];
    const calendlyLink = find(urls || [], (url) =>
      url.includes("https://calendly.com")
    );
    return {
      alreadyScheduled: Boolean(
        (message.attachments || []).find(
          (item) => item.type === "SCHEDULED_EVENT_DETAILS"
        )
      ),
      // containsCalenlyLink: true,
      containsCalenlyLink: Boolean(calendlyLink),
      calendlyLink,
    };
  }, [message.attachments, message.message]);

  const { containsCalLink, calLink, calAlreadyScheduled } = useMemo(() => {
    const urls = extractUrls(message.message) || [];
    const calLink = find(
      urls || [],
      (url) =>
        url.includes("https://cal.com") &&
        url
          .replace("https://cal.com", "")
          .split("/")
          .filter((e) => e).length === 2
    );
    return {
      calAlreadyScheduled: Boolean(
        (message.attachments || []).find(
          (item) => item.type === "SCHEDULED_EVENT_DETAILS"
        )
      ),
      // containsCalenlyLink: true,
      containsCalLink: Boolean(calLink),
      calLink: calLink,
    };
  }, [message.attachments, message.message]);

  const { iframeActionButtonLabel, iframeActionLink } = useMemo(() => {
    const urls = extractUrls(message.message) || [];
    // console.log('urls', urls)
    const src = find(urls || [], (url) => url.includes("cc-widget-embed"));
    // console.log('src', src)
    if (!src) {
      return {
        iframeActionLink: undefined,
        iframeActionButtonLabel: undefined,
      };
    }
    const url = new URL(src);
    return {
      iframeActionLink: url.toString(),
      iframeActionButtonLabel: url.searchParams.get("cc-widget-embed"),
    };
  }, [message.message]);

  console.log("iframeActionButtonLabel", iframeActionButtonLabel);

  const user = useSelector(selectUserById(message.from.id));

  const widget = useSelector(selectWidget);

  const sentUserText = useMemo(() => {
    switch (message.from.senderType) {
      case "CAMPAIGN": {
        return "System";
      }
      case "CLIENT": {
        return "You";
      }
      case "SYSTEM": {
        return "System";
      }
      case "USER": {
        if (message.from.id === "CC" || message.from.id === "cc") {
          return `${widget?.configurations?.chatBotProfile?.name || "Bot"}`;
        }
        return getUserName(user) || "Team Member";
      }
    }
  }, [
    message.from.id,
    message.from.senderType,
    user,
    widget?.configurations?.chatBotProfile?.name,
  ]);

  return (
    <div
      className={classNames([
        "message-row relative w-full",
        {
          me: message.from.senderType === "CLIENT",
          contact: message.from.senderType === "USER",
          system: message.from.senderType === "SYSTEM",
          campaign: message.from.senderType === "CAMPAIGN",
        },
      ])}
      key={index.toString()}
    >
      {/* Todo: Order is hard coded */}
      {attachments.length > 0 &&
        (attachments[0]?.type === "GREETINGS_COVER" ||
          attachments[1]?.type === "GREETINGS_COVER") && (
          <div className="attachments-cont greetings-cont">
            <MessageAttachments attachments={message.attachments} />
            <div className="time secondary-text mat-caption">
              {message.from.senderType === "USER" &&
                conversation?.metaData?.readWatermarks?.client >=
                  message.metaData.sentAt && <></>}
              {message.from.senderType === "USER" &&
                conversation?.metaData?.readWatermarks?.client <
                  message.metaData.sentAt && <></>}
            </div>
          </div>
        )}

      <div
        className={classNames([
          "message-row relative w-full group",
          {
            me: message.from.senderType === "CLIENT",
            contact: message.from.senderType === "USER",
            system: message.from.senderType === "SYSTEM",
            campaign: message.from.senderType === "CAMPAIGN",
            "first-of-group": isFirstMessageOfGroup(message, index, messages),
            "last-of-group": isLastMessageOfGroup(message, index, messages),
            "animated-cc cc-expand-bounce-right":
              message.from.senderType === "CLIENT",
            "animated-cc cc-expand-bounce-left":
              index !== 0 && message.from.senderType === "USER",
            "animate fade-in-down":
              message.from.senderType === "SYSTEM" ||
              message.from.senderType === "CAMPAIGN",
          },
        ])}
      >
        {shouldShowUserAvatar(message, index, messages) && (
          <div className="avatar avatar-cont animate fade-in-down">
            <UserAvatar userId={message.from.id} size={38} />
          </div>
        )}

        <div
          className={classNames("bubble", {
            hidden:
              message.message === "Attachments" &&
              message.attachments.length > 0,
          })}
        >
          {!hideMessageOptions &&
            (message.from.senderType === "USER" ||
              message.from.senderType === "CLIENT") && (
              <div
                className="absolute"
                style={{
                  [message.from.senderType === "CLIENT"
                    ? "left"
                    : "right"]: -25,
                }}
              >
                <Popover
                  overLay={
                    <div className="bg-white shadow-md rounded-lg p-2 mt-2">
                      <div
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700 cursor-pointer"
                        onClick={(e) => {
                          GlobalEventEmitter.emit("REPLY_MESSAGE", {
                            messageId: message.id,
                            messageText: message.message,
                          });
                          setTimeout(() => {
                            document.body.click();
                            setTimeout(() => {
                              document.getElementById("ccMessageVal")?.focus();
                            }, 0);
                          }, 100);
                        }}
                      >
                        {t("REPLY")}
                      </div>
                    </div>
                  }
                  className="flex justify-center items-center size-9 text-sm font-semibold rounded-lg bg-white hover:bg-gray-200 text-gray-800 shadow-sm hover:bg-gray-50 p-2 item-visible-on-hover"
                  // eslint-disable-next-line i18next/no-literal-string
                  activeClassName="opacity-100"
                >
                  <svg
                    className="flex-none size-4 text-gray-600"
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={5} r={1} />
                    <circle cx={12} cy={19} r={1} />
                  </svg>
                </Popover>
              </div>
            )}

          {/* <>
                  {message.from.senderType === "USER" ? (
                    <>
                      <PrintUserNameById userId={message.from.id} />:{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {moment(message.metaData.sentAt).format(
                    "dddd, h:mm:ss a - MMMM Do YYYY"
                  )}
                </> */}
          <ToolTipX
            content={`${
              message.from.senderType === "USER" ? `${sentUserText} · ` : ``
            }${GetReadableTime(message.metaData.sentAt)}`}
          >
            <div
              className={classNames("message", {
                "with-markdown": useMarkDownRender,
              })}
            >
              {/* <Linkify
              componentDecorator={(
                decoratedHref: string,
                decoratedText: string,
                key: number
              ) => (
                <SecureLink href={decoratedHref} key={key}>
                  {decoratedText}
                </SecureLink>
              )}
            >
              {message.message}
            </Linkify> */}
              {useMarkDownRender ? (
                <LazyMarkdownPreview
                  source={message.message}
                  wrapperElement={{
                    // eslint-disable-next-line i18next/no-literal-string
                    "data-color-mode": "light",
                  }}
                />
              ) : (
                // <Linkify componentDecorator={componentDecorator}>
                //   {message.message}
                // </Linkify>
                <div
                  dangerouslySetInnerHTML={{
                    // __html: isHTML(message.message)
                    //   ? message.message
                    //   : message.message
                    //       .replace(
                    //         /((http:|https:)[^\s]+[\w])/g,
                    //         '<a href="$1" target="_blank">$1</a>'
                    //       )
                    //       .replace(/\n/g, "<span class='break-fix'>\n</span>"),
                    __html: message.message
                      .replace(
                        /((http:|https:)[^\s]+[\w])/g,
                        '<a href="$1" target="_blank">$1</a>'
                      )
                      .replace(/\n/g, "<span class='break-fix'>\n</span>"),
                  }}
                ></div>
              )}
            </div>
          </ToolTipX>
          {message?.data?.magicAssistantQueryId && (
            <RateMagicAssistantReply
              responseId={message?.data?.magicAssistantQueryId!}
            />
          )}

          {attachments.length === 0 &&
            index === messages.length - 1 &&
            message.from.senderType !== "SYSTEM" && (
              <div className="time">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {sentUserText} · {GetReadableTime(message.metaData.sentAt)}
              </div>
            )}
        </div>

        {attachments.length > 0 &&
          !(
            attachments[0]?.type === "GREETINGS_COVER" ||
            attachments[1]?.type === "GREETINGS_COVER"
          ) && (
            <div className="attachments-cont">
              <MessageAttachments attachments={message.attachments} />
              <div className="time secondary-text mat-caption">
                {message.from.senderType === "USER" &&
                  conversation?.metaData?.readWatermarks?.client >=
                    message.metaData.sentAt && <></>}
                {message.from.senderType === "USER" &&
                  conversation?.metaData?.readWatermarks?.client <
                    message.metaData.sentAt && <></>}
                {index === messages.length - 1 && (
                  <>
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    {sentUserText} · {GetReadableTime(message.metaData.sentAt)}
                  </>
                )}
              </div>
            </div>
          )}
      </div>

      <div className="extras-cont">
        {message?.data?.magicAssistantQueryId &&
          messages.length - 1 === index &&
          message.message.toLowerCase().includes("sorry") &&
          message.message.toLowerCase().includes("i don't") && (
            <UnHappyResponse onRedirectToTeamMember={onRedirectToTeamMember!} />
          )}
        {/* Quick Replies */}
        {message.attachments &&
          message.attachments.length > 0 &&
          messages.length - 1 === index && (
            <MessageQuickReplies
              attachments={message.attachments}
              conversationId={message.conversationId}
            />
          )}
        {message.from.senderType !== "CLIENT" &&
          !alreadyScheduled &&
          containsCalenlyLink && (
            // eslint-disable-next-line i18next/no-literal-string
            <Suspense fallback={<>...</>}>
              <CalendlyButton
                url={calendlyLink!}
                messageId={message.id!}
                conversationId={conversation?.id!}
              />
            </Suspense>
          )}

        {message.from.senderType !== "CLIENT" &&
          !calAlreadyScheduled &&
          containsCalLink && (
            // eslint-disable-next-line i18next/no-literal-string
            <Suspense fallback={<>...</>}>
              <CalButton
                url={calLink!}
                messageId={message.id!}
                conversationId={conversation?.id!}
              />
            </Suspense>
          )}

        {message.from.senderType !== "CLIENT" &&
          iframeActionButtonLabel &&
          iframeActionLink && (
            <IframeUrlLoaderActionButton
              url={iframeActionLink!}
              buttonLabel={iframeActionButtonLabel}
              messageId={message.id!}
              conversationId={conversation?.id!}
              isLastMessage={messages.length - 1 === index}
            />
          )}
      </div>
    </div>
  );
};
