/* eslint-disable i18next/no-literal-string */
import { SDK } from "@sdk";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StackTracey from "stacktracey";
import {
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";

import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { store } from "./store/store";

export class CustomErrorBoundary extends React.Component {
  state: {
    error: any;
    errorInfo: any;
  };

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error Fallback
      return (
        <ErrorFallback
          error={this.state.error}
          componentStack={this.state.errorInfo.componentStack}
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export const ErrorFallback = ({ error, componentStack }) => {
  const [sendingLog, setSendingLog] = useState(false);
  const [errorSendingLog, setErrorSendingLog] = useState(false);

  useEffect(() => {
    const process = async () => {
      const prettyPrintedString = (
        await new StackTracey(error).cleanAsync()
      ).asTable(); // asynchronous version
      if (
        !window.location.href.includes("localhost") &&
        !window.location.href.includes("file://")
      ) {
        const knownErrors = [
          "cancelAnimationFrame is not defined",
          "SecurityError: Failed to read the 'localStorage' property from",
          "Cannot read property 'translator' of undefined",
          "dispatchEvent",
          "ChunkLoadError",
          "getComputedStyle"
        ];

        if (knownErrors.some((v) => error.toString()?.includes(v))) {
          console.log("Known Error Ignored", error);
          return;
        }

        const widget = selectWidget(store.getState());
        setSendingLog(true);
        SDK.sendError({
          subject: "Widget Error Log",
          data: {
            title: "Widget Error Log",
            url: window.location.href,
            error: error.toString(),
            errorInfo: componentStack,
            stack: prettyPrintedString,
            widgetId: widget?.id!,
            session: selectSession(store.getState())?.id!,
            currentTime: new Date().toString(),
            nodeStartedTime: new Date(
              Number((window as any).appStartedTime)
            ).toString(),
            errorAfter: convertMillisecondsToReadableString(
              Date.now() - Number((window as any).appStartedTime)
            ),
          },
        })
          .then((d) => {
            setSendingLog(false);
            window.location.reload();
          })
          .catch((e) => {
            setSendingLog(false);
            setErrorSendingLog(true);
          });
      }
    };
    process().catch((e) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-8">
      <h2 className="text-3xl">Something went wrong.</h2>
      {sendingLog && <p>Sending logs</p>}
      {errorSendingLog && <p>Error while sending logs</p>}

      {!errorSendingLog && !sendingLog && <p>Reloading Widget</p>}

      <details style={{ whiteSpace: "pre-wrap" }}>
        <div>{error.toString()}</div>
        <br />
        <div>{componentStack}</div>
      </details>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload Widget
      </button>
    </div>
  );
};
