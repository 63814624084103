import {
  iFormNode,
  iGPTAssistantNode,
  iMessageNode,
  iShopifyOrderStatusNode,
} from "./chat-bot-models";
import { iMatchedKnowledgeDocument } from "./knowledge-documents-model";

export interface iSendRequestBody {
  subject: string;
  data: { [key: string]: string };
}

export interface iWidget {
  id?: string;
  organizationId: string;
  connectionId: string;
  data: iWidgetData;
  configurations: iWidgetConfiguration;
  metaData: iWidgetMetaData;
}

export interface iWidgetData {
  label: string;
}

export interface iWidgetMetaData {
  isActive: boolean;
  isDeleted: boolean;
  createdAt: number;
}

export interface iWidgetBlackListedClients {
  ips: string[];
  whiteListedCountries: string[];
}

export interface iShopifyPluginConfig {
  isActive: boolean;
}

export interface iKBConfig {
  isActive: boolean;
  selfServicePortalId?: string;
}

export interface iWidgetAnnouncementsConfig {
  isEnabled: boolean;
  message: string;
}

export interface iWidgetOfflineHoursDayConfig {
  startTime: string;
  endTime: string;
}

export interface iWidgetOfflineHoursDayWiseConfig {
  [day: string]: iWidgetOfflineHoursDayConfig;
}

export interface iWidgetOfflineHours {
  timezone: string;
  dayWiseConfig: iWidgetOfflineHoursDayWiseConfig;
}
export interface iWidgetCustomerSignIn {
  isEnabled: boolean;
}

export interface iWidgetConfiguration {
  appearance: iWidgetAppearanceConfiguration;
  conversationFlow: iChatConversationFlowConfig;
  locale: iChatLocale;
  faqCollections: iChatFAQCollection[];
  blackListedClients?: iWidgetBlackListedClients;
  allowMultipleActiveChat?: boolean;
  automaticallyOpenChatWidgetOnNewMessages?: boolean;
  shopifyPlugin?: iShopifyPluginConfig;
  knowledgeBase?: iKBConfig;
  hideCCLogo?: boolean;
  offlineHours?: iWidgetOfflineHours;
  announcements?: iWidgetAnnouncementsConfig;
  chatBotProfile?: iBotAssistantProfile;
  disableFormTracking?: boolean;
  customerSignIn?: iWidgetCustomerSignIn;
}

export interface iBotAssistantProfile {
  name: string;
  avatar: string;
}
// Appearance Configuration

export interface iWidgetAppearanceConfiguration {
  colorPallet: iWidgetColorPallet;
  customCss?: string; // this could be inline saved to DB,
  launcherPosition?: LauncherPosition;
  eyeGrabber?: string;
  headerPattern?: string;
  chatBubbleSize?: "DEFAULT" | "COMPACT";
  chatBubbleStyle?: "rounded" | "circle";
  chatBubbleImage?: string;
  chatBubbleXOffset?: number;
  chatBubbleYOffset?: number;
  widgetSize?: "DEFAULT" | "COMPACT";
}

export interface iWidgetColorPallet {
  primary: string;
}

export enum LauncherPosition {
  RIGHT_BOTTOM = "RIGHT_BOTTOM",
  LEFT_BOTTOM = "LEFT_BOTTOM",
}

// Conversation Flow

export interface iChatConversationFlowConfig {
  greetingsMessage: iChatFlowConfig_Greetings;
  preChatForm: iChatFlowConfig_PreChatForm;
  chatRatings: iChatFlowConfig_ChatRatings;
  campaigns?: iCampaign[];
}

export interface iChatFlowConfig_PreChatForm {
  isEnabled: boolean;
  fields: any[];
  allowOnlyWorkEmails?: boolean;
  privacyPolicyLink?: string;
}

export interface iChatFlowConfig_ChatRatings {
  enabled: boolean;
  allowAgentsToPrompt?: boolean;
}

export interface iChatFlowConfig_GreetingsOption {
  label: string;
  action: string;
  data: any;
}
export interface iChatFlowConfig_Greetings {
  text: string;
  coverImage?: string;
  magicAssistantId?: string;
  startWithAIAssist?: boolean;
  aiAssistantExamples?: string[];
  options: iChatFlowConfig_GreetingsOption[];
  // Ideally if there is any widget attachment, it should be present in the attachment data
  autoTrigger?: boolean;
  chatBotId?: string;
  messageData?: any;
}

// Locale

export interface iChatLocale {
  language: string;
  custom: {
    [phrase: string]: string;
  };
}

// FAQs

export interface iChatFAQCollection {
  label: string;
  faqList: iChatFAQ[];
}

export interface iChatFAQ {
  question: string;
  body: string;
}

/************
 *  USERS *
 ************/

export interface iUser {
  id: string;
  data: {
    firstName: string;
    lastName: string;
    avatar: string;
    designation: string;
    isSpecialist: boolean;
    description: string;
    rating: number;
    isInternalUser?: boolean;
  };
  metaData: {
    isActive: boolean;
    isAvailable?: true;
    isAPIUser?: boolean;
  };
}

/************
 *  Session *
 ************/

export interface iCreateSessionResponse {
  accessToken: string;
  session: iSessionPublicData;
}

export interface iLogPageVisitRequest {
  id: string;
  landedPage: {
    title: string;
    url: string;
  };
  referer: string;
  startedTime: number;
  endedTime: number;
}

export interface UpdateContactDetails {
  firstName: string;
  lastName: string;
  primaryEmail: string;
  primaryMobile: string;
  preference?: Partial<iContactPreference>;
}
export interface iContactPreference {
  isSubscribedToNewsletter: boolean;
  isSubscribedToPush: boolean;
  marketingConsentObtained: boolean;
  marketingConsentNote?: string;
}
export interface iContactPublicData {
  id: string;
  data: {
    firstName?: string;
    lastName?: string;
    primaryEmail?: string;
    primaryMobile?: string;
  };
  preference?: Partial<iContactPreference>;
}

export interface iSessionInviteSubsequentMessage {
  message: string;
}

export interface iSessionChatInvite {
  message: string;
  subsequentMessages: iSessionInviteSubsequentMessage[];
  invitedBy: string;
  invitedTime: number;
  visitId?: string;
}

export interface iSessionPublicData {
  id?: string;
  organizationId: string;
  connectionId: string;
  contactId?: string;
  contact?: iContactPublicData;
  widgetId: string;
  chatInvite?: iSessionChatInvite;
  ip: string;
  location?: {
    data?: {
      country?: string;
    };
  };
  device?: {
    timezone?: string;
    screenW?: number;
  };
  metaData: {
    lastOnline?: number;
    createdTime: number;
    landedPage?: {
      url?: string;
    };
    referer?: {
      url?: string;
    };
    isSandboxSession?: boolean;
    customUserId?: string;
    isVerified?: boolean;
  };
}

/************
 *  Conversation *
 ************/

/*FOLLOWING INTERFACES GIVEN BY: RAHULAN 2ND TIME*/
export interface iConversationFlag {
  isActive: boolean;
  raisedBy: string;
  raisedAt: number;
  dismissedBy?: string;
  dismissedAt?: number;
}

export interface iConversationFlags {
  requiresAttention?: iConversationFlag;
}

export interface iConversationReadWatermarksByUser {
  [userId: string]: number;
}

export interface iConversationReadWatermarks {
  client: number;
  team: number;
  byUser: iConversationReadWatermarksByUser;
}

export interface iConversationLastMessage {
  text: string;
  timestamp: number;
  client: number;
  team: number;
  isByClient: boolean;
}

export interface iConversationMetaData {
  isActive: boolean;
  createdAt: number;
  lastMessage: iConversationLastMessage;
  readWatermarks: iConversationReadWatermarks;
  flags: iConversationFlags;
  totalMessages: number;
  // Reporting
  firstResponseTime: number;
  // Other
  dueData: number;
  // Start Source
  initSource: ChatInitSource;
  unreadMessages: number /*ADDED BY: RUBY*/;
}

export type ChatInitSource =
  | "INVITE"
  | "INBOUND_EMAIL"
  | "OUTBOUND_EMAIL"
  | "WEBSITE_CHAT"
  | "FB_PRIVATE_REPLY"
  | "FACEBOOK_AD";

export interface iConversationUser {
  userId: string;
  joinedTime: number;
}

export interface iConversationPipeline {
  id: string;
  stage: string;
  // Could Add Reporting Data
  // Eg: Time between stages
}

export interface iMoment {
  timestamp: number;
  category: MomentCategory;
  title: string;
  notes: string[];
  featured: boolean;
}

export enum MomentCategory {
  META_DATA = "Meta_Data",
  USER_DEFINED = "User_Defined",
  AHA_MOMENT = "Aha_Moment",
  MILESTONE = "Milestone",
  FB_AD_REFERENCE = "FB_AD_REFERENCE",
  FB_POST_REFERENCE = "FB_POST_REFERENCE",
  TRANSFER_LOG = "TRANSFER_LOG",
}

export interface iConversationRef {
  type: iConversationReferenceType;
  value: string;
}

export enum iConversationReferenceType {
  FB_AD_REFERENCE = "FB_AD_REFERENCE",
  FB_POST_REFERENCE = "FB_POST_REFERENCE",
  // We could add Campaigns Reference Here
  // We could add tags here, (different type of tags)
}
export interface iChatRating {
  timestamp: number;
  rating: number; // 1-10
  comment: string;
}

export interface iConversation {
  id?: string;
  organizationId: string;
  connectionId: string;
  connectionType: string;
  contactId: string;
  activeBot?: string;
  data: any; // Any Data Added
  priority: number; // 1- 10
  subject: string;
  status: "OPEN" | "CLOSED";
  pipeline: iConversationPipeline;
  channel: string; // Department
  tags: string[];
  members: iConversationUser[];
  moments: iMoment[]; // rename to events
  references: iConversationRef[];
  metaData: iConversationMetaData;
  messageBoxType?: string /*ADDED BY: RUBY*/;
  isTemporary?: boolean;
  chatBotId?: string;
  // Extension
  chatRating?: iChatRating;
  customProperties?: any;
}

/************
 *  Conversations *
 ************/
export interface iMessageMetaData {
  sentAt: number;
  deliveredAt: number;
  // Newly Added
  fbMessageReference?: string; // mid
  fbReplyReference?: {
    mid: string;
  }; // reply_to
  isDeleted?: boolean;
  messageEditHistory?: string[];
}

export enum iMessageSenderType {
  USER = "USER",
  CAMPAIGN = "CAMPAIGN",
  CLIENT = "CLIENT",
  SYSTEM = "SYSTEM",
}

export interface iMessageFrom {
  senderType: "USER" | "CAMPAIGN" | "CLIENT" | "SYSTEM";
  id: string;
}
export interface iMessageAttachment {
  // formatting of the messages can be done in front end
  // Notes: Buttons can be used here and the button actions can be sent as a
  // different message containing reply as an attachment.... This can be manipulated in the front-end
  type: MessageAttachmentTypes;
  payload:
    | any
    | {
        // File Attachment
        file: {
          url: string;
        };
        type: "application/pdf" | "image/jpeg" | "image/png" | "file";
      }
    | {
        // Link Attachment
        link: {
          url: string;
          title?: string;
          metaData?: {
            postId?: string;
            postData?: {
              message: string;
              image: string;
            };
          };
        };
      }
    | {
        // FB_POST_REF
        postRefUrl: string;
        pageId: string;
        postId: string;
        commentId: string;
        postData?: {
          message: string;
          image: string;
        };
      }
    | {
        // FbEventEntry_Referral
      }
    | {
        // Quick Reply Options;
        options: iChatFlowConfig_GreetingsOption[];
      }
    | {
        // Quick Reply Selection;
        selection: iChatFlowConfig_GreetingsOption;
      }
    | {
        // Related Articles
        matchedDocuments: iMatchedKnowledgeDocument[];
      }
    | {
        // AI Assist Session
        messages: iMessage[];
      };
}

export enum MessageAttachmentTypes {
  VIDEO = "VIDEO",
  VOICE_NOTE = "VOICE_NOTE",
  FORM = "FORM",
  SURVEY = "SURVEY",
  Rich_Text = "Rich_Text",
  PRESENTATION = "PRESENTATION",
  LINK = "LINK",
  FILE = "FILE",
  TICKET_REF = "TICKET_REF",
  FB_POST_REF = "FB_POST_REF",
  FB_MESSENGER_REFERRAL = "FB_MESSENGER_REFERRAL",
  QUICK_REPLIES = "QUICK_REPLIES",
  GREETINGS_COVER = "GREETINGS_COVER",
  SELECTED_QUICK_REPLY = "SELECTED_QUICK_REPLY",
  SCHEDULED_EVENT_DETAILS = "SCHEDULED_EVENT_DETAILS",
  LOCATION = "LOCATION",
  CALL_LOG = "CALL_LOG",
  V_CARD = "V_CARD",
  MESSAGE_REFERENCE = "MESSAGE_REFERENCE",
  TASK = "TASK",
  ACTION_LIST = "ACTION_LIST",
  RELATED_ARTICLES = "RELATED_ARTICLES",
  AI_ASSIST_SESSION = "AI_ASSIST_SESSION",
}

export interface iMessage {
  id?: string;
  organizationId: string;
  contactId?: string;
  conversationId: string;
  from: iMessageFrom;
  message: string;
  metaData: iMessageMetaData;
  // Newly Added
  isWhisper?: boolean;
  isPrivate?: boolean;
  isNotes?: boolean;
  attachments: iMessageAttachment[];
  references: string[]; // referencing other messages
  connectionId: string;
  connectionType: string;
  originRef?: string;
  data?: {
    botContext?:
      | iMessageNode
      | iGPTAssistantNode
      | iFormNode
      | iShopifyOrderStatusNode;
    magicAssistantQueryId?: string;
  };
}

export interface iCampaignOptions {
  additionalMessages?: { message: string }[];
  userId?: string;
  additionalMessageData?: any;
}

export interface iCampaign {
  id: string;
  isEnabled: boolean;
  data: iCampaignData;
}

export interface iCampaignData {
  triggerCondition: iCampaignTriggerCondition;

  // For Chat Prompts
  message: string;
  offerImageUrl?: string;
  quickReplies?: iChatFlowConfig_GreetingsOption[];
  options?: iCampaignOptions;
  chatBotId?: string;
  // Meta Data
  messageCampaignId?: string;
}

export interface iCampaignTriggerCondition {
  preset: TriggerConditionPresets;
  data: iCampaignTriggerConditionData;
}

export interface iCampaignTriggerConditionData {
  query?: any[];
  secondsToDelay?: number;
  pageContains?: string;
  forUtmTags?: {
    tag: string;
    value: string;
  }[];
}

export enum TriggerConditionPresets {
  NONE = "NONE",
  FIVE_SECONDS_AFTER_VISIT = "FIVE_SECONDS_AFTER_VISIT",
  ON_SECOND_VISIT = "ON_SECOND_VISIT",
  ON_NAVIGATION_TO_SECOND_PAGE = "ON_NAVIGATION_TO_SECOND_PAGE",
  ON_EVENT_RECEIVED_FROM_PARENT = "ON_EVENT_RECEIVED_FROM_PARENT",
}
