import { useSDK } from "@sdk";
import { Button } from "components/common/button/button";
import { FolderIconSolid } from "components/common/icons/folder-icon";
import { SearchIcon } from "components/common/icons/search";
import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { HelpCenterArticleItem } from "components/knowledge-base/help-center-article-item";
import { HelpCenterCollectionItem } from "components/knowledge-base/help-center-collection-item";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import {
  loadAllArticleCollections,
  loadAllArticleCollectionsByPortalId,
} from "store/modules/article-collection/article-collection.helpers";
import {
  selectAllArticleCollections,
  selectCollectionsByPortal,
} from "store/modules/article-collection/article-collection.selectors";
import {
  loadAllArticles,
  loadAllArticlesByPortalId,
} from "store/modules/articles/articles.helpers";
import {
  selectAllArticles,
  selectArticlesByPortal,
} from "store/modules/articles/articles.selectors";
import { selectWidget } from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import { useDebouncedValue } from "utils/use-debounced-value";

export const KbHomePage = ({ disableBack }: { disableBack?: boolean }) => {
  const { t } = useTranslation("common");

  const store = useStore();
  const navigator = useNavigator();

  const widget = useSelector(selectWidget);

  const selfServicePortalId =
    widget?.configurations.knowledgeBase?.selfServicePortalId;

  const {
    state: allCollections,
    isLoading: isCollectionsLoading,
  } = useSimpleLoaderStore(
    selectCollectionsByPortal(selfServicePortalId!),
    loadAllArticleCollectionsByPortalId(selfServicePortalId!),
    [selfServicePortalId],
    !selfServicePortalId
  );

  const [query, setQuery] = useState("");

  const [searchTerm] = useDebouncedValue(query, 500);

  const { data: searchResults, isLoading: isRemoteSearching } = useSDK(
    (SDK) =>
      SDK.articles.searchArticles(
        searchTerm,
        widget?.organizationId!,
        selfServicePortalId!
      ),
    [searchTerm],
    !searchTerm,
    []
  );

  const isSearching = isRemoteSearching || query !== searchTerm;

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={t("KNOWLEDGE_BASE_TITLE")}
          disableBack={disableBack}
          useNavigationPush={true}
          icon={
            <div className="dark-bg rounded-lg p-2 flex justify-center items-center">
              <FolderIconSolid size={"1.3rem"} />
            </div>
          }
        />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className="h-full w-full flex-1 p-4 bg-white relative"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            {/* Search BAr */}
            <div className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md">
              <input
                placeholder={t("SEARCH_KB_INPUT_PLACEHOLDER")}
                className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
                onChange={(el) => {
                  setQuery(el.currentTarget.value);
                }}
                autoFocus
              />
              <SearchIcon className="mr-2" />
            </div>

            {/* Results */}

            {!query && (
              <div className="animate fade-in-up">
                {allCollections.map((collection) => (
                  <HelpCenterCollectionItem
                    key={collection.id}
                    collection={collection}
                    onSelect={() => {
                      navigator.navigateTo(
                        `/knowledge-base/collections/${collection.id}`
                      );
                    }}
                  />
                ))}
                {allCollections.length === 0 && (
                  <div className="border-dashed border rounded-lg m-8 mb-2 p-8 flex flex-col justify-center items-center">
                    <SearchIcon size={40} className="text-gray-600" />
                    <div className="text-gray-600 text-center">
                      {t("NO_RESULTS")}
                    </div>
                  </div>
                )}
              </div>
            )}
            {query && (
              <>
                <div className="articles animate fade-in-up">
                  {isSearching && (
                    <div className="flex flex-col justify-center items-center p-4 mt-8">
                      <LoadingIndicator size={"3rem"} />
                      <div className="text-gray-600 mt-4">{t("SEARCHING")}</div>
                    </div>
                  )}
                  {searchResults.length > 0 && (
                    <div className="font-semibold my-4">
                      {t("KB_MATCHING_ARTICLES")}
                    </div>
                  )}
                  {searchResults.map((article) => (
                    <HelpCenterArticleItem
                      key={article.id!}
                      article={article}
                      onSelect={() => {
                        navigator.navigateTo(
                          `/knowledge-base/articles/${article.id}`
                        );
                      }}
                    />
                  ))}
                  {!isSearching && searchResults.length === 0 && (
                    <>
                      <div className="border-dashed border rounded-lg m-8 mb-2 p-8 flex flex-col justify-center items-center">
                        <SearchIcon size={40} className="text-gray-600" />
                        <div className="text-gray-600 text-center">
                          {t("NO_MATCHING_ARTICLES")}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {!isSearching && (
              <div className="flex flex-col justify-center items-center animate fade-in-up">
                <div className="mt-16 mb-2 text-sm text-gray-800 flex justify-center">
                  {t("NO_ANSWER_TEXT")}
                </div>

                <div className="border border-primary rounded-lg">
                  <Button
                    label={t("CHAT_WITH_US_BUTTON")}
                    onClick={async () => {
                      const { conversation } = await initLocalConversation(
                        store
                      );
                      navigator.navigateTo(`/conversations/${conversation.id}`);
                    }}
                    type="secondary-button"
                    className="py-1"
                  />
                </div>
              </div>
            )}
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};
