import { SDK } from "@sdk";
import { iUser } from "@sdk/services/models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { usersDefaultQueryConfig } from "./users-default-query";
import { UsersSlice } from "./users.slice";

export const loadAllUsers = (widgetId: string) => (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadUsersQuery({ ...usersDefaultQueryConfig, query: { widgetId } }, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadUsersQuery,
  loadEntityById: loadUserById,
  loadEntitiesByIds: bulkLoadUsersByIds,
} = CreateHelpersForEntity<iStore, iUser>({
  sliceName: "users",
  slice: UsersSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: (query) =>
    SDK.getUserList((query.query as any).widgetId),
  loadEntityByIdProvider: (entityId: string) => {
    throw "Not Implemented";
  },
  loadEntityByIdsProvider: (userIds: string[]) => {
    throw "Not Implemented";
  },
});
