import React, { Suspense } from "react";

const MarkdownPreview = React.lazy(() => import("@uiw/react-markdown-preview"));

export const LazyMarkdownPreview = (props: {
  prefixCls?: string;
  className?: string;
  source?: string;
  disableCopy?: boolean;
  style?: React.CSSProperties;
  wrapperElement?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    "data-color-mode"?: "light" | "dark";
  };
  // Todo: More props available
}) => {
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <Suspense fallback={<>...</>}>
       {/* eslint-disable-next-line i18next/no-literal-string */}
      <MarkdownPreview {...props} linkTarget="_blank" />
    </Suspense>
  );
};
