import { SDK } from "@sdk";
import Switch from "@yaireo/ui-switch";
import "@yaireo/ui-switch/src/switch.scss";
import { EndChatIcon } from "components/common/icons/end-chat";
import { IconContainer } from "components/common/icons/icon-container";
import { SoundOffIcon } from "components/common/icons/sound-off";
import { SoundOnIcon } from "components/common/icons/sound-on";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setMuteState } from "store/modules/app-state/app-state.actions";
import {
  selectAudioMuteState,
  selectAudioState,
} from "store/modules/app-state/app-state.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectWidgetConfigurations } from "store/modules/session/session.selectors";
import { store } from "store/store";
import { GlobalEventEmitter } from "utils/global-event-emitter";

export const ConversationMenu = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const isSoundEnabled = useSelector(selectAudioState);
  const conversation = useSelector(selectConversationById(conversationId));

  const userId = conversation?.members?.[0]?.userId;

  const widgetConfiguration = useSelector(selectWidgetConfigurations);

  const { t } = useTranslation("common");

  const endChat = () => {
    SDK.endConversation(conversationId);
  };

  return (
    <div style={{ borderRadius: 10, overflow: "hidden" }} className="py-2">
      {/* Sound Settings */}
      <div
        className="menu-item p-2 px-4 hover:bg-gray-200 flex cursor-pointer"
        onClick={() => {
          store.dispatch(setMuteState(isSoundEnabled));
        }}
      >
        <div className="pr-2">
          {isSoundEnabled ? <SoundOnIcon /> : <SoundOffIcon />}
        </div>
        <div className="flex-grow text-base text-gray-700">
          {isSoundEnabled ? t("SOUND_ON") : t("SOUND_OFF")}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            checked={isSoundEnabled}
            onChange={(e) => {
              e.stopPropagation();
              store.dispatch(setMuteState(isSoundEnabled));
            }}
          />
        </div>
      </div>
      {/* End Conversation */}
      {!conversation?.isTemporary && conversation?.status === "OPEN" && (
        <div
          className="menu-item p-2 px-4 hover:bg-gray-200 flex cursor-pointer"
          onClick={endChat}
        >
          <div className="pr-2">
            <EndChatIcon />
          </div>
          <div className="flex-grow text-base text-gray-700">
            {t("END_CONVERSATION_BUTTON_TEXT")}
          </div>
        </div>
      )}

      {userId &&
        widgetConfiguration &&
        widgetConfiguration?.conversationFlow?.chatRatings?.enabled && (
          <>
            <div
              className="menu-item p-2 px-4 hover:bg-gray-200 flex cursor-pointer"
              onClick={() => {
                GlobalEventEmitter.emit("ACTIVATE_CHAT_DRAWER", {
                  view: "RATE_CHAT",
                });
                document.getElementById('chat-drawer')?.click();
              }}
            >
              <div className="pr-2">
                <IconContainer className="flex items-center text-xl text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM7 10H9C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10H17C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10Z" />
                  </svg>
                </IconContainer>
              </div>
              <div className="flex-grow text-base text-gray-700">
                {t("RATE_CONVERSATION")}
              </div>
            </div>
          </>
        )}
    </div>
  );
};
