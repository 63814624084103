import { iArticle } from "@sdk/services/knowledge-base/knowledge-base-models";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { Tag } from "./tag";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "components/common/icons/arrow-right";

export const HelpCenterArticleItem = ({
  article,
  onSelect,
}: {
  article: iArticle;
  onSelect?: () => any;
}) => {
  const { t } = useTranslation("common");
  return (
    <div
    className="my-2 border-gray-200 border rounded-2xl hover-border-primary-500 rounded-md flex collection-item draggable-collection mode_transition"
      onClick={onSelect}
    >
      <div
        className={classNames(
          "w-full border-0 bg-white dark:bg-gray-900  cursor-pointer  rounded-lg  mode_transition",
          {
            "hover:bg-gray-100 cursor-pointer": Boolean(
              onSelect
            ),
          }
        )}
      >
        <div className="flex flex-row justify-center items-center">
          <div className="flex flex-row flex-1 items-center p-4 group">
            <div className="flex-1 flex flex-col justify-center pl-4">
              <div className=" text-primary">{article.title}</div>
              <div className=" text-gray-800 dark:text-gray-400 mode_transition text-sm">
                {article.description}
              </div>
              <div className="">
                {article.tags?.map((tag) => (
                  <Tag key={tag}>
                    {(tag.toUpperCase && tag.toUpperCase()) || tag}
                  </Tag>
                ))}
              </div>
              <div className=" text-gray-600 dark:text-gray-400 mode_transition text-sm">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {t("KB_ARTICLE_CREATED_AT")}:{" "}
                {moment(article.metaData.createdAt).format("LL h:mm:ss a ")}
              </div>
            </div>
            <div className="hidden group-hover:show animate fade-in-left">
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
