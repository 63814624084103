import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import { iMessageAttachment } from "@sdk/services/models";
import React from "react";
import { useTranslation } from "react-i18next";

export const MessageEventScheduledCard = ({
  attachment,
}: {
  attachment: iMessageAttachment;
}) => {
  const eventLink = attachment?.payload?.eventDetails.event?.uri;

  const { t } = useTranslation("common");

  return (
    <div className="attachment-inner-cont text-left flex flex-row mt-2">
      <CheckCircleOutlined className="text-2xl" />
      <div className="pl-2 ">
        <div className="font-bold">{t("EVENT_SCHEDULED")}</div>
        {/* <a
          className="text-gray-600 text-sm"
          target="_blank"
          rel="noreferrer"
          href={eventLink}
        >
          {t("EVENT_DETAILS")}
        </a> */}
      </div>
    </div>
  );
};
