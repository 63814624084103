import classNames from "classnames";
import React from "react";

export const NewChatIcon = ({
  size = '1.7rem',
  className,
}: {
  size?: number | string;
  className?: string;
}) => {
  return (
    <div
      className={classNames("icon-container", className)}
      style={{ width: size, height: size }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        className="icon-svg"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 10H8v2h3v3h2v-3h3v-2h-3V7h-2v3z" />
      </svg>
    </div>
  );
};
