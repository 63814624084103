import dayjs from "dayjs";
import moment from "moment";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const GetReadableTime = (timeStamp: number) => {
  const currentTime = dayjs();
  const targetTime = dayjs(timeStamp);

  if (currentTime.isSame(targetTime, "hour")) {
    // If it's the same hour, show time in 'fromNow' format
    return (targetTime as any).fromNow();
  } else if (currentTime.isSame(targetTime, "day")) {
    // If it's the same day, only show time
    return targetTime.format("h:mm A");
  } else if (currentTime.isSame(targetTime, "year")) {
    // If it's the same year, omit the year
    return targetTime.format("D MMM, h:mm A");
  } else {
    // Otherwise, show full date and time
    return targetTime.format("D MMM YYYY, h:mm A");
  }
};
