import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const useNavigator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const navigateTo = useMemo(() => {
    return (path: string, historyState?: string[]) => {
      const statePageVisitedArray = history.location.state
        ? history.location.state["pageVisited"]
        : [];
      const pageVisitedArray = [
        ...statePageVisitedArray,
        history.location.pathname,
      ];

      history.push({
        pathname: path,
        state: {
          pageVisited: historyState || pageVisitedArray,
        },
      });
      //   dispatch(push(path));
    };
  }, [history]);

  const goBack = () => {
    const statePageVisitedArray = history.location.state
      ? history.location.state["pageVisited"]
      : [];
    const clonedVisitedPages = [...statePageVisitedArray];
    const goBackPage = clonedVisitedPages.pop(); // TODO: check this
    const pageVisitedArray = clonedVisitedPages;
    history.push({
      pathname: goBackPage,
      state: {
        pageVisited: pageVisitedArray,
      },
    });
  };

  const replaceHistory = (path: string, historyState?: string[]) => {
    history.replace(path, {
      pageVisited: historyState,
    });
  };

  return { navigateTo, goBack, replaceHistory };
};
