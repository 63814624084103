import Switch from "@yaireo/ui-switch";
import "@yaireo/ui-switch/src/switch.scss";
import { SoundOffIcon } from "components/common/icons/sound-off";
import { SoundOnIcon } from "components/common/icons/sound-on";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setMuteState } from "store/modules/app-state/app-state.actions";
import { selectAudioMuteState, selectAudioState } from "store/modules/app-state/app-state.selectors";
import { store } from "store/store";

export const TopMenu = () => {
  const isSoundEnabled = useSelector(selectAudioState);
  const { t } = useTranslation("common");

  return (
    <div style={{ borderRadius: 10, overflow: "hidden" }} className="py-2">
      {/* Sound Settings */}
      <div
        className="menu-item p-2 px-4 hover:bg-gray-200 flex cursor-pointer"
        onClick={() => {
          store.dispatch(setMuteState(isSoundEnabled));
        }}
      >
        <div className="pr-2">
          {!isSoundEnabled ? <SoundOnIcon /> : <SoundOffIcon />}
        </div>
        <div className="flex-grow text-base text-gray-700">
          {!isSoundEnabled ? t("SOUND_ON") : t("SOUND_OFF")}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            checked={!isSoundEnabled}
            onChange={(e) => {
              e.stopPropagation();
              store.dispatch(setMuteState(isSoundEnabled));
            }}
          />
        </div>
      </div>
    </div>
  );
};
