import { iMessageAttachment } from "@sdk/services/models";
import React from "react";

export const MessageLinkCard = ({
  attachment,
}: {
  attachment: iMessageAttachment;
}) => {
  const title = attachment?.payload?.link?.title;
  const description = attachment.payload.link?.description;
  const url = attachment?.payload?.link?.url;
  const imagePreview = attachment?.payload?.link?.imagePreview;

  return (
    <a
      className="attachment-inner-cont link cursor-pointer hover:bg-gray-100 no-underline"
      target="_blank"
      rel="noreferrer"
      href={url}
    >
      <div className="font-bold text-sm line-clamp-2">{title}</div>
      <div className="text-gray-600 text-xs line-clamp-2">{description}</div>
      <div className="text-gray-600 text-xs">{url}</div>

      {imagePreview && (
        <div className="image mt-2">
          <img src={imagePreview} alt="Preview" />
        </div>
      )}
    </a>
  );
};
