import { SDK } from "@sdk";
import { TypingIndicator } from "components/common/typing-indicator/typing-indicator";
import debounce from "lodash/debounce";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { animated, useSpring } from "react-spring";
import useStayScrolled, { UseStayScrolledOptions } from "react-stay-scrolled";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import "../message-list-box/messages-list.scss";
import { iMessage } from "@sdk/services/models";
import { usePrevious } from "utils/use-previous";
import { MessagesListItem } from "../message-list-box/message-list-item";
import { GPTProcessingIndicator } from "./gpt-processing-indicator/gpt-processing-indicator";

export const GPTAssistantMessagesListBox = ({
  conversationId,
  messages,
  isProcessing,
  onRedirectToTeamMember,
}: {
  conversationId: string;
  messages: iMessage[];
  isProcessing?: boolean;
  onRedirectToTeamMember?: () => any;
}) => {
  const conversation = useSelector(selectConversationById(conversationId));

  const ref = useRef<HTMLElement>(null);
  const [{ scrollTop }, updateScroll] = useSpring(() => ({ scrollTop: 0 }));
  const runScroll = useCallback(
    (offset) =>
      updateScroll({
        scrollTop: offset,
        from: { scrollTop: ref.current ? ref.current!.scrollTop : 0 },
        reset: true,
      }),
    [updateScroll]
  );
  const [notifyNewMessage, setNotifyNewMessage] = useState(false);
  const { scrollBottom, stayScrolled, isScrolled } = useStayScrolled(
    ref as React.RefObject<HTMLElement>,
    {
      runScroll,
    } as UseStayScrolledOptions
  );

  const onScroll = useCallback(() => {
    if (isScrolled()) setNotifyNewMessage(false);
  }, [isScrolled]);

  const lastMessageCount = usePrevious(messages.length);

  useLayoutEffect(() => {
    // Tell the user to scroll down to see the newest messages if the element wasn't scrolled down
    if (messages.length > (lastMessageCount || 0) && lastMessageCount !== 0) {
      // * Removed the feature as it was buggy (When bot is active). Got to spend some time and re-put this
      // setNotifyNewMessage(!(stayScrolled as any)());
      scrollBottom();
    }
  }, [lastMessageCount, messages.length, scrollBottom, stayScrolled]);

  useLayoutEffect(() => {
    scrollBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = debounce(function handleResize() {
      scrollBottom();
    }, 200);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const { t } = useTranslation("common");

  return (
    <>
      <animated.div
        ref={ref as any}
        id="chat-messages-container"
        className="chat-messages flex-1 overflow-y-auto overflow-x-hidden h-full w-full p-0 pl-6"
        onScroll={onScroll}
        {...{ scrollTop: scrollTop }}
      >
        {messages.map((message, index) => {
          return (
            <MessagesListItem
              message={message}
              index={index}
              messages={messages}
              conversation={conversation}
              key={message.id}
              useMarkDownRender={message.from.senderType === "USER"}
              hideMessageOptions={true}
              onRedirectToTeamMember={onRedirectToTeamMember}
            />
          );
        })}

        <div
          className="typing-indicator-container"
          style={{ height: 40, marginTop: 40 }}
        >
          {isProcessing && (
            <>
              <TypingIndicator />
              {/* <GPTProcessingIndicator /> */}
            </>
          )}
        </div>
      </animated.div>
      {notifyNewMessage && (
        <div className="relative">
          <div
            className="new-message-notification cursor-pointer"
            onClick={() => scrollBottom()}
          >
            {t("SCROLL_DOWN_TO_NEW_MESSAGE")}
          </div>
        </div>
      )}
    </>
  );
};
