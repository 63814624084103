import { Button } from "components/common/button/button";
import { ChatIcon2 } from "components/common/icons/chat-icon2";
import { NewChatIcon } from "components/common/icons/new-chat";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { ChatListItem } from "components/conversations/chat-list-item/chat-list-item";
import { useNavigator } from "helpers/hooks/use-navigator";
import { initLocalConversation } from "helpers/init-local-conversation";
import find from "lodash/find";
import React, { useMemo } from "react";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { ReactI18NextConfig } from "react-i18config";
import { useSelector, useStore } from "react-redux";
import { loadAllConversations } from "store/modules/conversations/conversations.helpers";
import { selectAllConversations } from "store/modules/conversations/conversations.selectors";
import { selectWidgetConfigurations } from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { filter, orderBy } from "lodash";
import { iConversation } from "@sdk/services/models";

export const ConversationListPage = () => {
  const { state: _allConversations, isLoading } = useSimpleLoaderStore(
    selectAllConversations,
    loadAllConversations
  );

  const allConversations = useMemo(
    () =>
      orderBy(
        _allConversations,
        (item: iConversation) => item.metaData?.lastMessage?.timestamp,
        "desc"
      ),
    [_allConversations]
  );

  const widgetConfigurations = useSelector(selectWidgetConfigurations);

  const lastActiveConversation = useMemo(() => {
    //* Even temporary conversation is considered an active conversation
    const activeConversation = find(allConversations, { status: "OPEN" });
    return activeConversation?.id;
  }, [allConversations]);

  const navigator = useNavigator();

  const store = useStore();

  const { t, i18n } = useTranslation("common");

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader title={t("ALL_CONVERSATIONS_TITLE")} />

        <PageScrollContainer className="page flex-1 bg-white">
          <div className="conversation-list-container">
            {allConversations.map((conversation) => (
              <ChatListItem key={conversation.id} conversation={conversation} />
            ))}
            {allConversations.length === 0 && (
              <div className="border-dashed border rounded-lg m-8 mb-2 p-8 flex flex-col justify-center items-center">
                <ChatIcon2 size={40} className="text-gray-600" />
                <div className="text-gray-600 text-center">
                  {t("NEW_USER_MESSAGE")}
                </div>
              </div>
            )}
          </div>
        </PageScrollContainer>
        {(widgetConfigurations?.allowMultipleActiveChat ||
          !lastActiveConversation) && (
          <div className="footer p-4">
            <Button
              label={
                <div className="flex flex-row">
                  <NewChatIcon className="mr-2" />{" "}
                  {t("START_NEW_CONVERSATION_BUTTON")}
                </div>
              }
              onClick={async () => {
                const { conversation } = await initLocalConversation(store);
                navigator.navigateTo(`/conversations/${conversation.id}`);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
