export function validatePhoneNumberWithCountryCode(
  phoneNumber: string
): boolean {
  // Remove non-digit characters
  const cleanedPhoneNumber = `+${phoneNumber.replace(/\D/g, "")}`;

  // Check total length of phone number -     // considering + sign
  const totalLength = cleanedPhoneNumber.length;
  // 12 or 13
  //   In the United States, phone numbers typically consist of 10 digits (excluding the country code).
  // In the United Kingdom, phone numbers can vary in length but are typically 10 or 11 digits long (excluding the country code).
  // In India, phone numbers are usually 10 digits long (excluding the country code).
  // In Japan, phone numbers are typically 10 or 11 digits long (excluding the country code).
  if (totalLength < 12 || totalLength > 14) {
    return false;
  }
  return true;
}
