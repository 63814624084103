import {
  CloseOutlined,
  LeftOutlined,
  PushpinOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSDKAction } from "@sdk";
import classNames from "classnames";
import { Spinner } from "components/common/spinner/spinner";
import { ContextAwareClickListener } from "helpers/context-aware-click-listener";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { RateUserComponent } from "./message-components";
import { selectWidgetConfigurations } from "store/modules/session/session.selectors";
import { IconContainer } from "components/common/icons/icon-container";
import { GlobalEventEmitter } from "utils/global-event-emitter";

export const ChatBottomDrawer = ({
  isOpen,
  onClose,
  onFileDropped,
  conversationId,
}: {
  isOpen: boolean;
  onClose: () => any;
  onFileDropped: (
    files: FileList | null,
    event: React.DragEvent<HTMLDivElement>
  ) => any;
  conversationId: string;
}) => {
  const [view, setView] = useState<"MENU" | "FILE_UPLOAD" | "RATE_CHAT">(
    "MENU"
  );
  const [isFileDropActive, setFileDropStatus] = useState(false);

  const conversation = useSelector(selectConversationById(conversationId));
  const userId = conversation?.members?.[0]?.userId;

  const { t } = useTranslation("common");
  const widgetConfiguration = useSelector(selectWidgetConfigurations);

  const {
    isProcessing: isDeactivatingBot,
    doAction: deActiveBot,
  } = useSDKAction({
    action: (sdk) => () => sdk.deActivateBot(conversationId),
    onSuccess: () => {
      onClose();
    },
    successMessage: t("CHAT_BOT_DEACTIVATE_MESSAGE"),
    failureMessage: t("SOMETHING_WENT_WRONG_MESSAGE"),
  });

  useEffect(() => {
    const registerEvents = (data: { view: string }) => {
      setView(data.view as any);
    };
    GlobalEventEmitter.addListener("ACTIVATE_CHAT_DRAWER", registerEvents);
    return () => {
      GlobalEventEmitter.removeListener("ACTIVATE_CHAT_DRAWER", registerEvents);
    };
  }, [conversationId]);

  return (
    <ContextAwareClickListener onClickedOutside={onClose}>
      <div
        style={{
          transition: "all 0.4s linear",
          maxHeight: isOpen ? 1000 : 0,
          overflow: "hidden",
        }}
        id="chat-drawer"
      >
        {view === "MENU" && (
          <div className="border-t">
            <div style={{ height: "300px" }}>
              <div className=" flex justify-between py-2 ml-6 mr-2">
                <div className="font-semibold mt-3">
                  {t("WHAT_WOULD_YOU_LIKE_TOD_DO_QUESTION")}
                </div>
                <CloseOutlined
                  className="flex items-center text-base p-4 rounded-lg hover:bg-gray-200 cursor-pointer"
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>
              <div
                className="cursor-pointer hover:bg-gray-200 px-6"
                onClick={() => {
                  setView("FILE_UPLOAD");
                  // onClose();
                }}
              >
                <div className=" flex  items-center py-4">
                  <div>
                    <IconContainer className="flex items-center mr-4 text-xl text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM4 18.3851L5.76282 17H20V5H4V18.3851ZM13 11V15H11V11H8L12 7L16 11H13Z"></path>
                      </svg>
                    </IconContainer>
                  </div>
                  <div className="text-gray-800">{t("UPLOAD_ATTACHMENT")}</div>
                </div>
              </div>
              {userId &&
                widgetConfiguration &&
                widgetConfiguration?.conversationFlow?.chatRatings?.enabled && (
                  <>
                    <div
                      className="cursor-pointer hover:bg-gray-200 px-6"
                      onClick={() => {
                        setView("RATE_CHAT");
                      }}
                    >
                      <div className=" flex  items-center py-4">
                        <div>
                          <IconContainer className="flex items-center mr-4 text-xl text-gray-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM7 10H9C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10H17C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10Z" />
                            </svg>
                          </IconContainer>
                        </div>
                        <div className="text-gray-800">
                          {t("RATE_CONVERSATION")}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {conversation?.activeBot && (
                <Spinner
                  isActive={isDeactivatingBot}
                  hideChildrenOnProcess={false}
                >
                  <div
                    className="cursor-pointer hover:bg-gray-200 px-6"
                    onClick={() => {
                      deActiveBot();
                    }}
                  >
                    <div className=" flex items-center py-4">
                      <div>
                        <UserOutlined className="flex items-center mr-4 text-xl text-gray-600" />
                      </div>
                      <div className="text-gray-800">{t("SPEAK_TO_HUMAN")}</div>
                    </div>
                  </div>
                </Spinner>
              )}

              {/* <div className=" cursor-not-allowed hover:bg-gray-200 px-6 opacity-50">
                <div className=" flex  items-center py-4 border-t">
                  <div>
                    <CalendarOutlined className="flex items-center mr-4 text-xl text-gray-600" />
                  </div>
                  <div className="font-semibold text-gray-600">Book a Demo</div>
                </div>
              </div> */}
              {/* <div className=" cursor-not-allowed hover:bg-gray-200 px-6 opacity-50">
                <div className=" flex  items-center py-4 border-t ">
                  <div>
                    <ExceptionOutlined className="flex items-center mr-4 text-xl text-gray-600" />
                  </div>
                  <div className="font-semibold text-gray-600">
                    Send a complain
                  </div>
                </div>
              </div> */}
              {/* <div className=" cursor-not-allowed hover:bg-gray-200 px-6 opacity-50">
                <div className=" flex  items-center py-4 border-t ">
                  <div>
                    <FormOutlined className="flex items-center mr-4 text-xl text-gray-600" />
                  </div>
                  <div className="font-semibold text-gray-600">
                    Submit Query
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        )}
        {view === "FILE_UPLOAD" && (
          <div>
            <div className="border rounded-lg theme_border_light">
              <div
                className="back-button ml-2 mt-2 flex flex-row justify-start cursor-pointer items-center"
                onClick={() => {
                  setView("MENU");
                }}
              >
                <div className="button-container">
                  <div className="rounded-lg bg-gray-200 hover:bg-gray-300 h-12 w-12 flex items-center justify-center cursor-pointer">
                    <LeftOutlined className="text-2xl" />
                  </div>
                </div>
                <div className="text-container ml-2 font-semibold">
                  {t("BACK_BUTTON")}
                </div>
              </div>

              <div style={{ height: "300px" }} className="p-6">
                <div
                  className={classNames(
                    "border-dashed border-2 rounded-lg flex flex-col items-center justify-center text-xl h-full",
                    {
                      "bg-gray-200": setFileDropStatus,
                    }
                  )}
                  onClick={(e) =>
                    document.getElementById("uploadField")?.click()
                  }
                >
                  <div className="rounded-lg bg-gray-200 h-12 w-12 flex items-center justify-center">
                    <UploadOutlined className="text-2xl" />
                  </div>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {t("DROP_FILES") + " / " + t("CLICK_TO_SELECT_FILE")}
                </div>
                <input
                  type="file"
                  id="uploadField"
                  className="hidden"
                  multiple
                  onChange={(e) => {
                    const selectedFiles = (document.getElementById(
                      "uploadField"
                    )! as any).files;
                    onFileDropped(Array.from(selectedFiles) as any, e as any);
                    (document.getElementById("uploadField")! as any).value = "";
                    onClose();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {view === "RATE_CHAT" && (
          <div>
            <div className="border rounded-lg theme_border_light">
              <div className="px-2 pt-2 flex flex-row items-center justify-between w-full">
                <div
                  className="back-button flex flex-row justify-start cursor-pointer items-center"
                  onClick={() => {
                    setView("MENU");
                  }}
                >
                  <div className="button-container">
                    <div className="rounded-lg bg-gray-200 hover:bg-gray-300 h-12 w-12 flex items-center justify-center cursor-pointer">
                      <LeftOutlined className="text-2xl" />
                    </div>
                  </div>
                  <div className="text-container ml-2 font-semibold">
                    {t("BACK_BUTTON")}
                  </div>
                </div>
                <CloseOutlined
                  className="flex items-center text-base p-4 rounded-lg hover:bg-gray-200 cursor-pointer"
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>

              <div className="p-6 pt-2">
                <RateUserComponent
                  conversationId={conversationId}
                  onRated={() => {
                    onClose();
                    // eslint-disable-next-line i18next/no-literal-string
                    setView("MENU");
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </ContextAwareClickListener>
  );
};
