import _Popover from "@libs/react-simple-popover";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useDebouncedValue } from "utils/use-debounced-value";

export const Popover = ({
  children,
  overLay,
  style,
  className,
  activeClassName,
}: {
  children: React.ReactElement;
  overLay: React.ReactElement;
  style?: React.CSSProperties;
  className?: string;
  activeClassName?: string;
}) => {
  const [isPopoverVisible, setPopoverVisibility] = useState(false);
  const [debouncedVisibility] = useDebouncedValue(isPopoverVisible, 50);
  const itemRef = useRef<any>();

  return (
    <>
      <div
        className={classNames("relative", className, {
          [activeClassName || ""]: isPopoverVisible,
        })}
        ref={itemRef}
        onClick={() => setPopoverVisibility(true)}
      >
        {children}
      </div>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_Popover
        placement="bottom"
        show={isPopoverVisible}
        target={itemRef.current}
        onHide={() => debouncedVisibility && setPopoverVisibility(false)}
        containerStyle={{ paddingLeft: 10, paddingRight: 10 }}
        style={{ borderRadius: 10, padding: 0, ...style }}
      >
        {overLay}
      </_Popover>
    </>
  );
};
