import { iConversation } from "@sdk/services/models";
import classNames from "classnames";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { TeamAvatar } from "components/common/team-avatar/team-avatar";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import { GetReadableTime } from "helpers/get-readable-time";
import { getUserName } from "helpers/get-user-name";
import { useNavigator } from "helpers/hooks/use-navigator";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUnreadMessages } from "store/modules/conversations/conversations.selectors";
import { selectWidget } from "store/modules/session/session.selectors";
import { selectUserById } from "store/modules/users/users.selectors";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import { isHTML } from "utils/is-html";

export const ChatListItem = ({
  conversation,
}: {
  conversation: iConversation;
}) => {
  const currentMember = useSelector(
    selectUserById(conversation?.members[0]?.userId)
  );
  const navigator = useNavigator();

  const unreadMessages = useSelector(selectUnreadMessages(conversation.id!));
  const { t } = useTranslation("common");

  const widget = useSelector(selectWidget);
  const teamName =
    widget?.configurations?.locale?.custom?.["TEAM_NAME"] ||
    t("DEFAULT_TEAM_NAME");

  return (
    <div
      className={classNames(
        "flex flex-row pointer hover:bg-gray-300 p-4 cursor-pointer rounded-lg",
        {
          "bg-red-200": unreadMessages.hasUnread,
          "hover:bg-red-300": unreadMessages.hasUnread,
        }
      )}
      onClick={() => navigator.navigateTo(`/conversations/${conversation.id}`)}
    >
      <div className="user-avatar-container">
        {conversation.members.length > 0 ? (
          <UserAvatar
            userId={conversation.members[0].userId}
            isBordered
            size={40}
          />
        ) : (
          <TeamAvatar isBordered size={40} />
        )}
      </div>
      <div className="message-container flex-col flex-1 pl-4 overflow-hidden">
        <div className="first-line flex flex-row items-center">
          {/* Name */}
          <div
            className={classNames(
              "name flex-1 overflow-hidden whitespace-no-wrap text-overflow-ellipses",
              {
                "font-semibold": unreadMessages.hasUnread,
              }
            )}
          >
            {conversation.members.length > 0
              ? getUserName(currentMember)
              : teamName}
          </div>
          {/* Last Activity */}
          <div className="last-activity-data text-xs text-gray-600">
            {GetReadableTime(conversation?.metaData?.lastMessage.timestamp)}
          </div>
          {/* Unread bade */}
          {unreadMessages.hasUnread && Boolean(unreadMessages.count) && (
            <div className="unread-count w-5 h-5 rounded-full bg-red-600 text-white ml-2 text-xs flex justify-center items-center">
              {unreadMessages.count}
            </div>
          )}
        </div>
        <div className="last-message text-gray-600 text-sm overflow-hidden whitespace-no-wrap text-overflow-ellipses">
          {isHTML(conversation?.metaData.lastMessage.text) ? (
            convertHtmlToPlainText(conversation?.metaData.lastMessage.text)
          ) : (
            conversation?.metaData.lastMessage.text || t("NO_ACTIVITY")
          )}
        </div>
      </div>
    </div>
  );
};
